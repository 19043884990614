import React from 'react'
import { useTranslation } from 'react-i18next'
import { HIGHLIGHT } from '../../highlight/constants'
import HighlightBalloon from './HighlightBalloon'
import k from '../../../i18n/keys'

export const IsNewOrChanged = ({
  newOrChanged,
  thessaList = false,
  phdProjectList = false,
  supervisionPlanList = false
}) => {
  const { t } = useTranslation()
  if (newOrChanged === HIGHLIGHT.NEW) {
    return (
      <HighlightBalloon
        thessaList={thessaList}
        phdProjectList={phdProjectList}
        supervisionPlanList={supervisionPlanList}
        tooltipContent={t(k.NEW)}
      />
    )
  } else if (newOrChanged === HIGHLIGHT.CHANGED) {
    return (
      <HighlightBalloon
        thessaList={thessaList}
        phdProjectList={phdProjectList}
        supervisionPlanList={supervisionPlanList}
        tooltipContent={t(k.CHANGED)}
      />
    )
  }
  return null
}
