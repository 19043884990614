import React from 'react'
import { PhDProjectNotification } from './PhDProjectNotification'
import { Link } from 'react-router'
import { HyIcon } from '../common/icon/HyIcon'
import { useTranslation } from 'react-i18next'
import k from '../../i18n/keys'
import { UniButton } from '../common/uni/UniButton'

const InviteThesisCommitteeMembersNotification = () => {
  const { t } = useTranslation()
  return (
    <PhDProjectNotification
      id="invite-thesis-committee-members-notification"
      icon={<HyIcon alert />}
      body={t(k.PLEASE_INVITE_TCMS_TO_THESSA)}
      cta={
        <UniButton secondary as={Link} to="/profile/thesis-committee">
          {t(k.INVITE_THESIS_COMMITTEE)}
        </UniButton>
      }
    />
  )
}

export default InviteThesisCommitteeMembersNotification
