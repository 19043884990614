import React from 'react'
import styled, { css } from 'styled-components'
import { UniParagraphAnchorStyles } from './UniParagraph'
import { ADDITIONAL_SKYBLUE, BRAND_MAIN_LIGHT, GRAYSCALE_BLACK } from './colors'
import { HyIcon } from '../icon/HyIcon'

interface Properties {
  summary?: string | React.ReactNode
  summaryStyle?: 'link' | 'heading'
  open?: boolean
  marginTop?: boolean
  marginBottom?: boolean
  noArrow?: boolean
  showChevron?: boolean
  children: React.ReactNode
}

export const UniDetails = ({ summary, open, marginTop, marginBottom, noArrow, showChevron, children }: Properties) => {
  return (
    <Details open={open} marginTop={marginTop} marginBottom={marginBottom}>
      <Summary noArrow={noArrow} showChevron={showChevron}>
        <SummaryText>{summary}</SummaryText>
        {showChevron ? <Chevron caretDown /> : null}
      </Summary>
      {children}
    </Details>
  )
}

const Details = styled.details<{
  marginTop?: boolean
  marginBottom?: boolean
}>`
  margin-top: ${({ marginTop }) => (marginTop ? '12px' : '0')};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? '12px' : '0')};
`

const Summary = styled.summary<{ link?: boolean; heading?: boolean; noArrow?: boolean; showChevron?: boolean }>`
  display: list-item;
  cursor: pointer;
  ${({ noArrow }) => (noArrow ? 'list-style: none;' : '')}

  ${({ showChevron }) =>
    showChevron &&
    css`
      display: flex;
      align-items: center;
      gap: 16px;
    `}
`

const SummaryText = styled.span`
  ${UniParagraphAnchorStyles}
  color: ${GRAYSCALE_BLACK};
`

const Chevron = styled(HyIcon)<{ small?: boolean; open?: boolean }>`
  flex: 0 0 auto;
  color: ${GRAYSCALE_BLACK};
  font-size: 16px;
  line-height: 1.5em;
  height: 1.5em;

  ${({ open }) =>
    open &&
    css`
      transform: rotate(180deg);
    `}

  ${Details}[open] & {
    transform: rotate(180deg);
  }
`
