import InternationalActivity from '../../models/InternationalActivity'
import Report from '../../models/Report'
import { ReportCommentsAndEventsResponse } from '../report/review-tool/types'
import { ReportDeadlineResponse } from './responseTypes'
import { Role } from '../../stores/role'
import { ThesisCommitteeMeeting } from '../thesis-committee/types'
import { DoctoralCandidateResponse } from '../../models/DoctoralCandidate'

export enum Field {
  InternationalActivities = 'internationalActivities',
  Reports = 'reports',
  ReportCommentsAndEvents = 'reportCommentsAndEvents',
  ReportDeadline = 'reportDeadline',
  ThesisCommitteeMeetings = 'thesisCommitteeMeetings',
  BeginningOfStudies = 'beginningOfStudies',
  ExpectedGraduationDate = 'expectedGraduationDate'
}

export interface PhdProject {
  doctoralCandidateId: number
  isFastDoctoralCandidate: boolean
  role: Role
  [Field.InternationalActivities]: InternationalActivity[]
  [Field.Reports]: Report[]
  [Field.ReportCommentsAndEvents]: ReportCommentsAndEventsResponse
  [Field.ReportDeadline]: ReportDeadlineResponse
  [Field.ThesisCommitteeMeetings]: ThesisCommitteeMeeting[]
  [Field.BeginningOfStudies]: DoctoralCandidateResponse['beginningOfStudies']
  [Field.ExpectedGraduationDate]: DoctoralCandidateResponse['expectedGraduationDate']
  errorTranslationKey?: string
}

export enum PhdProjectActionType {
  Clear = 'Clear',
  Initialize = 'Initialize',
  Error = 'Error',

  CreateInternationalActivity = 'CreateInternationalActivity',
  EditInternationalActivity = 'EditInternationalActivity',
  DeleteInternationalActivity = 'DeleteInternationalActivity',

  UpdateReports = 'UpdateReports',
  CreateReport = 'CreateReport',
  EditReport = 'EditReport',
  DeleteReport = 'DeleteReport',

  UpdateReportCommentsAndEvents = 'UpdateReportCommentsAndEvents',

  UpdateThesisCommitteeMeetings = 'UpdateThesisCommitteeMeetings'
}

type ClearAction = {
  type: PhdProjectActionType.Clear
}

type InitializeAction = {
  type: PhdProjectActionType.Initialize
  doctoralCandidateId: number
  isFastDoctoralCandidate: boolean
  role: Role
  [Field.InternationalActivities]: InternationalActivity[]
  [Field.Reports]: Report[]
  [Field.ReportDeadline]: ReportDeadlineResponse
  [Field.ThesisCommitteeMeetings]: ThesisCommitteeMeeting[]
  [Field.BeginningOfStudies]: DoctoralCandidateResponse['beginningOfStudies']
  [Field.ExpectedGraduationDate]: DoctoralCandidateResponse['expectedGraduationDate']
}

type ErrorAction = {
  type: PhdProjectActionType.Error
  errorTranslationKey: string
}

type CreateInternationalActivityAction = {
  type: PhdProjectActionType.CreateInternationalActivity
  internationalActivity: InternationalActivity
}

type EditInternationalActivityAction = {
  type: PhdProjectActionType.EditInternationalActivity
  internationalActivity: InternationalActivity
}

type DeleteInternationalActivityAction = {
  type: PhdProjectActionType.DeleteInternationalActivity
  internationalActivityId: number
}

type UpdateReportsAction = {
  type: PhdProjectActionType.UpdateReports
  reports: Report[]
}

type CreateReportAction = {
  type: PhdProjectActionType.CreateReport
  report: Report
}

type EditReportAction = {
  type: PhdProjectActionType.EditReport
  report: Report
}

type DeleteReportAction = {
  type: PhdProjectActionType.DeleteReport
  reportId: number
}

type UpdateReportCommentsAndEventsAction = {
  type: PhdProjectActionType.UpdateReportCommentsAndEvents
  reportCommentsAndEvents: ReportCommentsAndEventsResponse
}

type UpdateThesisCommitteeMeetingsAction = {
  type: PhdProjectActionType.UpdateThesisCommitteeMeetings
  thesisCommitteeMeetings: ThesisCommitteeMeeting[]
}

export type PhdProjectAction =
  | ClearAction
  | InitializeAction
  | ErrorAction
  | CreateInternationalActivityAction
  | EditInternationalActivityAction
  | DeleteInternationalActivityAction
  | UpdateReportsAction
  | CreateReportAction
  | EditReportAction
  | DeleteReportAction
  | UpdateReportCommentsAndEventsAction
  | UpdateThesisCommitteeMeetingsAction
