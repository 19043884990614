import React from 'react'
import styled, { css } from 'styled-components'
import { Link as RouterLink } from 'react-router'
import { BRAND_MAIN, BRAND_MAIN_LIGHT, GRAYSCALE_BLACK } from './colors'
import { HyIcon } from '../icon/HyIcon'
import { fromTabletUp, fromDesktopUp } from '../../styledUtils'
import { UniParagraphAnchorStyles } from './UniParagraph'

interface Properties {
  links: { id?: string; link: string; title: string; external?: boolean }[]
  row?: boolean
  grid?: boolean
}

export const UniShortcut = ({ links, row = true, grid }: Properties) => {
  return (
    <List $type={grid ? 'grid' : row ? 'row' : undefined}>
      {links.map(({ id, link, title, external }, key) => (
        <ListItem key={key}>
          <Link
            to={external ? undefined : link}
            href={external ? link : undefined}
            id={id}
            $type={grid ? 'grid' : row ? 'row' : undefined}
          >
            <Text>{title}</Text>
            <Icon arrowRight aria-hidden={true} $type={grid ? 'grid' : row ? 'row' : undefined} external={external} />
          </Link>
        </ListItem>
      ))}
    </List>
  )
}

const List = styled.ul<{ $type: 'grid' | 'row' | undefined }>`
  ${({ $type }) =>
    $type === 'row' &&
    css`
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: space-between;
      gap: 1.5em;
      padding: 0;

      ${fromTabletUp(css`
        gap: 2.5em;
      `)}

      ${fromDesktopUp(css`
        gap: 5em;
      `)}
    `}

  ${({ $type }) =>
    $type === 'grid' &&
    css`
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 1.5em;
      padding: 0;

      ${fromTabletUp(css`
        grid-template-columns: 1fr 1fr;
      `)}
    `}
`

const ListItem = styled.li`
  flex: 1 1 auto;
  position: relative;
  list-style: none;
`

const Link = styled(RouterLink)<{ $type: 'grid' | 'row' | undefined }>`
  ${UniParagraphAnchorStyles}
  color: ${GRAYSCALE_BLACK};
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  ${({ $type }) =>
    $type === 'row' &&
    css`
      border-block-start: 3px solid ${GRAYSCALE_BLACK};
      border-block-end: 3px solid ${GRAYSCALE_BLACK};
      padding-block: 1em;
    `}

  ${({ $type }) =>
    $type === 'grid' &&
    css`
      border-block-end: 1px solid ${GRAYSCALE_BLACK};
      padding-block: 0.5em;
    `}
`

const Text = styled.span`
  padding-right: 36px;
`

const Icon = styled(HyIcon)<{ $type: 'grid' | 'row' | undefined; external?: boolean }>`
  position: absolute;
  right: 0;
  width: 24px;
  font-size: 1.65em;
  color: ${BRAND_MAIN_LIGHT};
  text-align: right;

  ${({ $type, external }) =>
    $type === 'row' &&
    external &&
    css`
      transform: scale(0.88) translate(0, -3%) rotate(-45deg);
    `}

  ${({ $type, external }) =>
    $type === 'grid' &&
    external &&
    css`
      transform: scale(0.55) translate(0, -3%) rotate(-45deg);
    `}

    ${({ $type, external }) =>
    $type === 'row' &&
    !external &&
    css`
      transform: scale(0.88) translate(0, -3%);
    `}

  ${({ $type, external }) =>
    $type === 'grid' &&
    !external &&
    css`
      transform: scale(0.5) translate(0, -3%);
    `}

  ${Link}:hover & {
  }
`
