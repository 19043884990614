import moment from 'moment'
import { TFunction } from 'i18next'
import { ThesisCommitteeMeeting } from '../components/thesis-committee/types'
import k from '../i18n/keys'
import {
  Chapter as ChapterType,
  ChapterItemType,
  ChapterAssignableItem,
  ChapterMeeting
} from '../components/chapters/types'
import { convertToChapterItem } from '../components/chapters/util'

export class Chapter {
  private chapters: ChapterType[] = []
  private currentId = 0
  private t: TFunction

  constructor(t: TFunction) {
    this.t = t
  }

  public generateChapters(
    meetings: ThesisCommitteeMeeting[],
    studyRightDate: string,
    expectedGraduationDate: string,
    additionalItems?: {
      [key in ChapterItemType]?: ChapterAssignableItem[]
    }
  ): ChapterType[] {
    this.chapters = []
    this.currentId = 0

    this.addInitialChapter(studyRightDate, meetings)
    this.addMeetingChapters(expectedGraduationDate, meetings)
    this.addSuggestedChapters(expectedGraduationDate)

    Array.isArray(additionalItems) &&
      Object.entries(additionalItems).forEach(([type, items]) => {
        if (items) {
          this.addItemsToChapters(items, type as ChapterItemType)
        }
      })

    return this.chapters
  }

  private generateChapterMonthlyInterval = (expectedGraduationDate?: string) => {
    let chapterCountArray = Array(10).fill(6)
    if (expectedGraduationDate) {
      const graduationDate = moment(expectedGraduationDate, 'YYYY-MM-DD')
      const monthsToGraduation = graduationDate.diff(moment(), 'months')
      const chapterCount = Math.floor(monthsToGraduation / 6) - 1
      chapterCountArray = Array(chapterCount).fill(6)
      // TODO: Add the remaining monthly interval to the last chapter
    }
    return [3, ...chapterCountArray]
  }

  private getChapterMonthlyInterval(
    expectedGraduationDate: string,
    index: number,
    plannedAt: moment.Moment | undefined
  ): number {
    if (index === 0 && plannedAt && moment().isAfter(plannedAt)) {
      return 0
    } else {
      return plannedAt && moment().isAfter(plannedAt)
        ? this.generateChapterMonthlyInterval(expectedGraduationDate)[index]
        : 0
    }
  }

  private calculateCompletedAt(plannedAt: moment.Moment | undefined, interval: number): moment.Moment | undefined {
    return plannedAt && moment().isAfter(plannedAt) ? plannedAt.clone().add(interval, 'months') : undefined
  }

  private addInitialChapter(studyRightDate: string, meetings: ThesisCommitteeMeeting[]): void {
    const studyRight = studyRightDate ? moment(studyRightDate) : null
    if (studyRight) {
      const firstMeetingDate = meetings.reduce((earliest, meeting) => {
        const meetingDate = meeting.date ? moment(meeting.date) : moment(meeting.completedAt)
        return meetingDate && (!earliest || meetingDate.isBefore(earliest)) ? meetingDate : earliest
      }, null as moment.Moment | null)

      if (firstMeetingDate ? (studyRight ? studyRight.isBefore(firstMeetingDate) : false) : true) {
        this.currentId++
        this.chapters.push({
          id: this.currentId,
          items: [
            {
              type: ChapterItemType.StudyRight,
              id: this.currentId,
              title: this.t(k.THESIS_COMMITTEE.MEETING),
              completedAt: studyRight.toISOString()
            }
          ],
          chapterInterval: 0
        })
      }
    }
  }

  private addMeetingChapters(expectedGraduationDate: string, meetings: ThesisCommitteeMeeting[]): void {
    meetings.forEach((meeting, index) => {
      const plannedAt = meeting.date ? moment(meeting.date) : undefined
      const chapterInterval = this.getChapterMonthlyInterval(expectedGraduationDate, index, plannedAt)
      const completedAt = meeting.completedAt
        ? moment(meeting.completedAt)
        : this.calculateCompletedAt(plannedAt, chapterInterval)
      this.currentId++

      this.chapters.push({
        id: this.currentId,
        items: [
          {
            type: ChapterItemType.Meeting,
            id: this.currentId,
            title: this.t(k.THESIS_COMMITTEE.MEETING),
            completedAt: completedAt ? completedAt.toISOString() : undefined,
            plannedAt: plannedAt ? plannedAt.toISOString() : undefined,
            suggestedAt: undefined
          }
        ],
        chapterInterval
      })
    })
  }

  private addSuggestedChapters(expectedGraduationDate: string): void {
    const lastMeeting = this.chapters[this.chapters.length - 1]?.items.find(
      (item): item is ChapterMeeting => item.type === ChapterItemType.Meeting
    )
    const lastPlannedTimestamp = lastMeeting?.plannedAt
    const lastPlannedDate = lastPlannedTimestamp ? moment(lastPlannedTimestamp) : moment()
    const nextDate = lastPlannedDate.clone()

    this.generateChapterMonthlyInterval(expectedGraduationDate)
      .slice(this.chapters.length)
      .forEach((chapterInterval) => {
        nextDate.add(chapterInterval, 'months')
        const suggestedAt = nextDate.clone()
        this.currentId++

        this.chapters.push({
          id: this.currentId,
          items: [
            {
              type: ChapterItemType.Meeting,
              id: this.currentId,
              title: this.t(k.THESIS_COMMITTEE.MEETING),
              completedAt: undefined,
              plannedAt: undefined,
              suggestedAt: suggestedAt ? suggestedAt.toISOString() : undefined
            }
          ],
          chapterInterval
        })
      })
  }

  private addItemsToChapters(items: ChapterAssignableItem[], itemType: ChapterItemType): void {
    items.forEach((item) => {
      const chapterIndex = item.chapter - 1
      if (chapterIndex >= 0 && chapterIndex < this.chapters.length) {
        this.currentId++
        const chapterItem = convertToChapterItem(item, itemType, chapterIndex)
        this.chapters[chapterIndex].items.push(chapterItem)
      }
    })
  }
}
