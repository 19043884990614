import { Field, PhdProject } from './types'

export function getEmptyPhdProject(doctoralCandidateId: number = null): PhdProject {
  return {
    doctoralCandidateId,
    isFastDoctoralCandidate: false,
    role: null,
    [Field.InternationalActivities]: null,
    [Field.Reports]: null,
    [Field.ReportCommentsAndEvents]: null,
    [Field.ReportDeadline]: null,
    [Field.ThesisCommitteeMeetings]: null,
    [Field.BeginningOfStudies]: null,
    [Field.ExpectedGraduationDate]: null
  }
}
