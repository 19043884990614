import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import k from '../../../i18n/keys'
import React, { Dispatch, SetStateAction } from 'react'
import { Link } from 'react-router'
import { logOutAction } from '../../../actions/AuthenticationActions'
import { BRAND_MAIN_NEARLY_BLACK, GRAYSCALE_MEDIUM, GRAYSCALE_WHITE } from '../uni/colors'
import { fromPhoneUp, fromTabletUp } from '../../styledUtils'
import { HyIcon } from '../icon/HyIcon'
import { LinkInteractionStyles, LinkStyles } from './LinkStyles'
import { DropdownStyles } from './DropdownStyles'

interface ProfileItemProperties {
  path?: string
  onClickFunction?: () => void
  isActive?: RegExp
  icon?: string
  title?: string
}

interface Properties {
  isLoading?: boolean
  isLoggedIn?: boolean
  isCollapsed?: boolean
  setIsCollapsed: Dispatch<SetStateAction<boolean>>
  name?: string
  items?: ProfileItemProperties[]
  collapseAll: () => void
  className?: string
}

const Profile = ({
  isLoading,
  isLoggedIn,
  isCollapsed,
  setIsCollapsed,
  name,
  items,
  collapseAll,
  className
}: Properties) => {
  const { t } = useTranslation()
  const isItemActive = (item: ProfileItemProperties) => !!location && item.isActive?.test(location.pathname)
  const hasActiveItem = (items: ProfileItemProperties[]) => items.map((item) => isItemActive(item)).some((item) => item)
  const ButtonText = isLoggedIn ? name ?? t(k.PROFILE1) : t(k.LOGIN)
  const ButtonTextTruncated = ButtonText.split(/-| /)[0]
  return (
    <Wrapper isLoading={isLoading} className={className}>
      <ProfileButton
        id={'profileMenuButton'}
        onClick={() => {
          collapseAll()
          setIsCollapsed(!isCollapsed)
        }}
        aria-expanded={!isCollapsed}
        aria-controls={'profileNavigation'}
        aria-label={
          isCollapsed ? `${ButtonText} - ${t(k.OPEN_PROFILE_MENU)}` : `${ButtonText} - ${t(k.CLOSE_PROFILE_MENU)}`
        }
        active={!isCollapsed || hasActiveItem(items)}
      >
        <Avatar>
          <AvatarIcon avatar />
        </Avatar>
        <ProfileButtonTextDesktop>{ButtonText}</ProfileButtonTextDesktop>
        <ProfileButtonTextMobile>{ButtonTextTruncated}</ProfileButtonTextMobile>
        <CaretDown caretDown />
      </ProfileButton>
      <ProfileNavigation
        id={'profileNavigation'}
        isCollapsed={isCollapsed}
        aria-hidden={isCollapsed}
        aria-label={t(k.PROFILE_MENU)}
      >
        <ProfileList>
          {items.map((profileItem, key) => (
            <ProfileItem key={key}>
              <ProfileLink
                to={profileItem.path}
                onClick={profileItem.onClickFunction}
                aria-current={isItemActive(profileItem) ? 'page' : false}
              >
                {profileItem.icon && <ProfileIcon {...{ [profileItem.icon]: true }} />}
                <ProfileText>{profileItem.title}</ProfileText>
              </ProfileLink>
            </ProfileItem>
          ))}
          {isLoggedIn && (
            <ProfileItem>
              <Logout id={'logoutButton'} type="button" onClick={() => logOutAction.push(undefined)}>
                <ProfileIcon logout />
                <ProfileText>{t(k.LOG_OUT)}</ProfileText>
              </Logout>
            </ProfileItem>
          )}
        </ProfileList>
      </ProfileNavigation>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ isLoading?: boolean }>`
  transition: opacity 0.2s;

  ${fromTabletUp(css`
    position: relative;
  `)}

  ${({ isLoading }) =>
    isLoading &&
    css`
      opacity: 0;
    `}
`

const ProfileButton = styled.button<{ active?: boolean }>`
  position: relative;
  appearance: none;
  background: none;
  border: 0;
  ${LinkStyles}
  font-size: 12px;
  justify-content: flex-end;
  ${({ active }) => LinkInteractionStyles(active)}
  margin-inline-end: -12px;

  ${fromPhoneUp(css`
    font-size: 14px;
  `)}
`

const ProfileButtonTextDesktop = styled.span`
  display: none;

  ${fromTabletUp(css`
    display: block;
  `)}
`

const ProfileButtonTextMobile = styled.span`
  display: block;

  ${fromTabletUp(css`
    display: none;
  `)}
`

const Avatar = styled.div`
  background-color: ${BRAND_MAIN_NEARLY_BLACK};
  border-radius: 100px;
  box-shadow: 0 0 0 2px ${BRAND_MAIN_NEARLY_BLACK};
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${fromPhoneUp(css`
    width: 18px;
    height: 18px;
    margin-right: 8px;
  `)}
`

const AvatarIcon = styled(HyIcon)`
  font-size: 12px;
  color: ${GRAYSCALE_WHITE};
  padding-bottom: 2px;

  ${fromPhoneUp(css`
    font-size: 18px;
  `)}
`

const CaretDown = styled(HyIcon)`
  font-size: 10px;
`

const ProfileNavigation = styled.nav<{ isCollapsed?: boolean }>`
  ${({ isCollapsed }) =>
    isCollapsed &&
    css`
      display: none;
    `}
`

const ProfileList = styled.ul<{ isCollapsed?: boolean }>`
  ${DropdownStyles('bottomRight')}
`

const ProfileItem = styled.li`
  list-style: none;

  &:not(:only-child):last-child {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: -6px;
      right: 12px;
      left: 12px;
      height: 1px;
      background-color: ${GRAYSCALE_MEDIUM};
    }
  }
`

const ProfileLink = styled(Link)`
  ${LinkStyles}
  ${({ 'aria-current': ariaCurrent }) => LinkInteractionStyles(ariaCurrent)}

  ${ProfileItem}:not(:only-child):nth-last-child(2) > & {
    margin-bottom: 12px;
  }
`

const ProfileIcon = styled(HyIcon)`
  font-size: 18px;
`

const Logout = styled.button`
  width: 100%;
  appearance: none;
  background: none;
  border: 0;
  ${LinkStyles}
  ${LinkInteractionStyles()}
  white-space: nowrap;
  text-align: start;
`

const ProfileText = styled.span``

export default Profile
