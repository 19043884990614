import React, { useState } from 'react'
import styled from 'styled-components'
import { GRAYSCALE_WHITE } from '../common/uni/colors'
import { UniHeading3, UniHeading4 } from '../common/uni/UniHeading'
import { FirstMeetingDateForm } from './FirstMeetingDateForm'
import { UniParagraph } from '../common/uni/UniParagraph'
import { UniButton } from '../common/uni/UniButton'
import { Trans, useTranslation } from 'react-i18next'
import k from '../../i18n/keys'
import { format, parseISO } from 'date-fns'
import { DATE_FORMAT_DATE_FNS } from '../../dateConstants'
import { usePhdProject } from '../doctoral-candidate-overview/phd-project-context/PhdProjectContextProvider'
import { usePhdProjectActions } from '../doctoral-candidate-overview/phd-project-context/usePhdProjectActions'
import { Role } from '../../stores/role'
import { Link } from 'react-router'

interface MeetingsProps {
  baseUrl: string
}

export const Meetings = ({ baseUrl }: MeetingsProps) => {
  const { t } = useTranslation()
  const phdProject = usePhdProject()
  const actions = usePhdProjectActions()
  const [isFirstMeetingDateFormVisible, setIsFirstMeetingDateFormVisible] = useState(false)
  const [errorOccurredInSavingFirstMeetingDate, setErrorOccurredInSavingFirstMeetingDate] = useState(false)
  const firstMeeting = phdProject.thesisCommitteeMeetings.length > 0 ? phdProject.thesisCommitteeMeetings[0] : null
  const firstMeetingDate = firstMeeting ? firstMeeting.date : null

  async function onSaveFirstMeetingDate(firstMeetingDate: string) {
    setErrorOccurredInSavingFirstMeetingDate(false)

    try {
      await actions.updateFirstThesisCommitteeMeetingDate(firstMeetingDate)
      setIsFirstMeetingDateFormVisible(false)
    } catch (error) {
      setErrorOccurredInSavingFirstMeetingDate(true)
    }
  }

  return (
    <MeetingContainer className="doctoral-candidate-overview__card--meetings">
      <Heading3>{t(k.THESIS_COMMITTEE.TITLE)}</Heading3>
      <Heading4>{t(k.THESIS_COMMITTEE.FIRST_MEETING)}</Heading4>
      {isFirstMeetingDateFormVisible && (
        <FirstMeetingDateForm
          firstMeetingDate={firstMeetingDate}
          onSave={onSaveFirstMeetingDate}
          onCancel={() => setIsFirstMeetingDateFormVisible(false)}
          errorOccurredInSavingFirstMeetingDate={errorOccurredInSavingFirstMeetingDate}
        />
      )}
      {!isFirstMeetingDateFormVisible && (
        <>
          <FirstMeetingDate
            firstMeetingDate={firstMeetingDate}
            userCanEditMeetingDate={phdProject.role === Role.DOCTORAL_CANDIDATE && phdProject.isFastDoctoralCandidate}
            setIsFirstMeetingDateFormVisible={setIsFirstMeetingDateFormVisible}
          />
          {firstMeeting && firstMeeting.reportMustBeSubmittedAfterDate && (
            <>
              <Heading4 marginTop>{t(k.THESIS_COMMITTEE.REPORT_FOR_MEETING)}</Heading4>
              {firstMeeting.reportId && (
                <UniParagraph noMargin>
                  <Trans
                    t={t}
                    i18nKey={k.THESIS_COMMITTEE.REPORT_FOR_MEETING_READY}
                    components={[<Link to={`${baseUrl}cumulative-report`} />]} //eslint-disable-line react/jsx-key
                  />
                </UniParagraph>
              )}
              {!firstMeeting.reportId && (
                <UniParagraph noMargin>
                  <Trans
                    t={t}
                    i18nKey={k.THESIS_COMMITTEE.REPORT_FOR_MEETING_MISSING}
                    components={[<HighlightText />]} //eslint-disable-line react/jsx-key
                    values={{
                      startDate: format(
                        parseISO(firstMeeting.reportMustBeSubmittedAfterDate),
                        DATE_FORMAT_DATE_FNS.DISPLAY_DATE
                      ),
                      endDate: format(parseISO(firstMeetingDate), DATE_FORMAT_DATE_FNS.DISPLAY_DATE)
                    }}
                  />
                </UniParagraph>
              )}
            </>
          )}
        </>
      )}
    </MeetingContainer>
  )
}

interface FirstMeetingDateProps {
  firstMeetingDate: string | null
  userCanEditMeetingDate: boolean
  setIsFirstMeetingDateFormVisible: (isVisible: boolean) => void
}

const FirstMeetingDate = ({
  firstMeetingDate,
  userCanEditMeetingDate,
  setIsFirstMeetingDateFormVisible
}: FirstMeetingDateProps) => {
  const { t } = useTranslation()
  const hasFirstMeetingDate = firstMeetingDate !== null
  if (userCanEditMeetingDate) {
    if (hasFirstMeetingDate) {
      return (
        <UniParagraph noMargin>
          {format(parseISO(firstMeetingDate), DATE_FORMAT_DATE_FNS.DISPLAY_DATE)}{' '}
          <UniButton small secondary onClick={() => setIsFirstMeetingDateFormVisible(true)}>
            {t(k.THESIS_COMMITTEE.CHANGE_DATE)}
          </UniButton>
        </UniParagraph>
      )
    } else {
      return (
        <UniParagraph noMargin>
          {t(k.THESIS_COMMITTEE.CHOOSE_FIRST_MEETING_DATE)}{' '}
          <UniButton small secondary onClick={() => setIsFirstMeetingDateFormVisible(true)}>
            {t(k.THESIS_COMMITTEE.CHOOSE_DATE)}
          </UniButton>
        </UniParagraph>
      )
    }
  } else {
    if (hasFirstMeetingDate) {
      return (
        <UniParagraph noMargin>{format(parseISO(firstMeetingDate), DATE_FORMAT_DATE_FNS.DISPLAY_DATE)}</UniParagraph>
      )
    } else {
      return <UniParagraph noMargin>{t(k.THESIS_COMMITTEE.NO_FIRST_MEETING_DATE_YET)}</UniParagraph>
    }
  }
}

const MeetingContainer = styled.div`
  background: ${GRAYSCALE_WHITE};
  padding: 24px;
`

const Heading3 = styled(UniHeading3)`
  margin-bottom: 26px;
`

const Heading4 = styled(UniHeading4)`
  margin-bottom: 6px;
`

const HighlightText = styled.strong`
  font-weight: 600;
`
