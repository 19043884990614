import React, { forwardRef, SelectHTMLAttributes, useCallback, useState } from 'react'
import styled, { css } from 'styled-components'
import { DEFAULT_FONT } from './typography'
import {
  ADDITIONAL_GREEN_DARK,
  ADDITIONAL_RED_DARK,
  ADDITIONAL_YELLOW,
  BRAND_MAIN_LIGHT,
  GRAYSCALE_DARK_TEXT,
  GRAYSCALE_MEDIUM_DARK,
  GRAYSCALE_WHITE,
  ADDITIONAL_SKYBLUE
} from './colors'
import { HyIcon } from '../icon/HyIcon'
import {
  Icon,
  ValidationErrorAfterContainer,
  ValidationHintAfterContainer,
  ValidationHintBeforeContainer,
  ValidationHintInlineContainer
} from './UniInput'
import { useTranslation } from 'react-i18next'
import k from '../../../i18n/keys'

interface UniSelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  label?: string | JSX.Element
  options: { value: string; label: string }[]
  isValid?: boolean
  validationHintAfter?: string | JSX.Element
  validationHintBefore?: string | JSX.Element
  validationHintInline?: string | JSX.Element
  hasInvalidHighlight?: boolean
  fullWidth?: boolean
  small?: boolean
}

export enum SelectStateIcon {
  Required,
  Valid,
  Invalid
}

export const UniSelect = forwardRef<HTMLSelectElement, UniSelectProps>(
  (
    {
      id,
      label,
      required,
      options,
      isValid,
      validationHintAfter,
      validationHintBefore,
      validationHintInline,
      small,
      hasInvalidHighlight,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation()
    const [hasFocus, setHasFocus] = useState(false)
    const onFocus = useCallback(() => setHasFocus(true), [])
    const onBlur = useCallback(() => setHasFocus(false), [])
    const hasIcon = required === true && isValid !== undefined
    const isEmpty = !props.value
    const { icon, highlightInvalidField } = getIcon(required, isValid, hasInvalidHighlight, isEmpty, hasFocus)
    return (
      <SelectWrapper>
        {validationHintBefore && (
          <ValidationHintBeforeContainer
            id={`${id}-help-before`}
            showInvalidColor={highlightInvalidField}
            aria-hidden="true"
          >
            {validationHintBefore}
          </ValidationHintBeforeContainer>
        )}
        <Container validationHintInline={validationHintInline}>
          <SelectContainer>
            <Select
              id={id}
              ref={ref}
              {...props}
              small={small}
              showInvalidBorder={highlightInvalidField}
              hasIcon={hasIcon}
              onFocus={onFocus}
              onBlur={onBlur}
              aria-required={required ? 'true' : undefined}
              aria-describedby={
                validationHintBefore || validationHintInline || validationHintAfter
                  ? `${validationHintBefore ? `${id}-help-before ` : ''}${
                      validationHintInline ? `${id}-help-inline ` : ''
                    }${validationHintAfter ? `${id}-help-after ` : ''}`
                  : undefined
              }
            >
              {options.map(({ value, label }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </Select>

            <IconContainer icon={icon} small={small}>
              {hasIcon && (
                <Icon
                  valid={icon === SelectStateIcon.Valid}
                  required={icon === SelectStateIcon.Required}
                  info={icon === SelectStateIcon.Invalid}
                />
              )}
              <DropdownIcon caretDown small={small} />
            </IconContainer>
          </SelectContainer>

          {validationHintInline && (
            <ValidationHintInlineContainer
              id={`${id}-help-inline`}
              showInvalidColor={highlightInvalidField}
              aria-hidden="true"
            >
              {validationHintInline}
            </ValidationHintInlineContainer>
          )}
        </Container>
        <ValidationHintAfterContainer
          id={validationHintAfter || highlightInvalidField ? `${id}-help-after` : undefined}
          showInvalidColor={highlightInvalidField}
          aria-hidden="true"
        >
          {highlightInvalidField ? (
            <ValidationErrorAfterContainer>
              {isEmpty ? `${label} ${t(k.IS_MISSING)}` : t(k.THE_VALUE_ENTERED_IS_INVALID)}
            </ValidationErrorAfterContainer>
          ) : null}
          {validationHintAfter}
        </ValidationHintAfterContainer>
      </SelectWrapper>
    )
  }
)

UniSelect.displayName = 'UniSelect'

function getIcon(
  isRequired: boolean,
  isValid: boolean,
  hasInvalidHighlight: boolean,
  isEmpty: boolean,
  hasFocus: boolean
): { icon: SelectStateIcon | undefined; highlightInvalidField: boolean } {
  if (isRequired) {
    if (hasInvalidHighlight) {
      return { icon: SelectStateIcon.Invalid, highlightInvalidField: true }
    }
    if (isValid) {
      return { icon: SelectStateIcon.Valid, highlightInvalidField: false }
    }
    if (hasFocus) {
      return { icon: SelectStateIcon.Required, highlightInvalidField: false }
    }
    if (isEmpty) {
      return { icon: SelectStateIcon.Required, highlightInvalidField: false }
    }

    if (isValid === undefined) {
      return { icon: undefined, highlightInvalidField: false }
    }
    return { icon: SelectStateIcon.Invalid, highlightInvalidField: true }
  }
  return { icon: undefined, highlightInvalidField: false }
}

export const SelectWrapper = styled.div``

const Container = styled.div<{ validationHintInline?: string | JSX.Element }>`
  position: relative;

  ${(props) =>
    props.validationHintInline &&
    css`
      display: flex;
    `}
`

const SelectContainer = styled.div`
  position: relative;
  width: 100%;
`

const Select = styled.select<{ showInvalidBorder: boolean; hasIcon: boolean; fullWidth?: boolean; small?: boolean }>`
  appearance: none;
  background: ${GRAYSCALE_WHITE};
  border: 1px solid ${GRAYSCALE_MEDIUM_DARK};
  border-radius: 0px;
  color: ${GRAYSCALE_DARK_TEXT};
  height: 44px;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 12px;
  padding-right: 56px;
  width: 100%;
  ${DEFAULT_FONT}

  &:focus {
    border: 1px solid ${BRAND_MAIN_LIGHT};
    outline: solid 2px ${ADDITIONAL_YELLOW};
    outline-offset: 2px;
  }

  ${(props) =>
    props.showInvalidBorder &&
    css`
      border-color: ${ADDITIONAL_RED_DARK};
      box-shadow: 0 0 0 1px ${ADDITIONAL_RED_DARK};
    `}

  ${(props) =>
    props.hasIcon &&
    css`
      padding-right: 84px;
    `}

    ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}

    ${(props) =>
    props.small &&
    css`
      height: 32px;
      font-size: 14px;
      line-height: 20px;
      padding: 6px 8px;
      padding-right: 48px;
    `}
`

export const IconContainer = styled.div<{ icon: SelectStateIcon; small?: boolean }>`
  align-items: center;
  bottom: 0px;
  display: flex;
  height: 44px;
  position: absolute;
  right: 12px;
  pointer-events: none;

  ${(props) =>
    props.icon !== undefined &&
    css`
      color: ${colorByIcon[props.icon]};
    `}

  ${(props) =>
    props.small &&
    css`
      height: 32px;
    `}
`

const colorByIcon: { [key in SelectStateIcon]: string } = {
  [SelectStateIcon.Required]: BRAND_MAIN_LIGHT,
  [SelectStateIcon.Valid]: ADDITIONAL_GREEN_DARK,
  [SelectStateIcon.Invalid]: ADDITIONAL_RED_DARK
}

const DropdownIcon = styled(HyIcon)<{ small?: boolean }>`
  flex: 0 0 auto;
  color: ${BRAND_MAIN_LIGHT};
  font-size: 16px;
  line-height: 1.5em;
  height: 1.5em;
  margin-inline-start: 16px;
  padding-inline-start: 16px;
  padding-inline-end: 4px;
  border-inline-start: 1px solid ${ADDITIONAL_SKYBLUE};

  ${(props) =>
    props.small &&
    css`
      font-size: 12px;
      line-height: 1.5em;
      height: 1.5em;
      margin-inline-start: 10px;
      padding-inline-start: 10px;
      padding-inline-end: 0px;
    `}
`
