import React, { lazy, Suspense } from 'react'
import { render } from 'react-dom'
import { applyRouterMiddleware, browserHistory, IndexRoute, Route, Router } from 'react-router'
import { useScroll } from 'react-router-scroll'
import '@itcenteratunihelsinki/huds-lib/dist/fonts/fonts.css'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import 'tippy.js/dist/tippy.css'
import '../sass/main.scss'
import LoadingIndicator from './components/common/LoadingIndicator'
import DoctoralCandidateOverview from './components/doctoral-candidate-overview/DoctoralCandidateOverview'
import CumulativeReport from './components/report/cumulative-report/CumulativeReport'
import { WindowWidthContextProvider } from './WindowWidthContext'
import { generatePageTitle, useCurrentLanguage } from './util'
import initI18n from './i18n/init'
import { FeatureFlagContextProvider } from './FeatureFlag'
import Frame from './components/layout/Frame'
import { UniContainer } from './components/common/uni/layout'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import k from './i18n/keys'

const ThessaApplication = lazy(() => import('./components/ThessaApplication'))
const Chapters = lazy(() => import('./components/chapters/Chapters'))
const EditCourse = lazy(() => import('./components/course/EditCourse'))
const EditGrant = lazy(() => import('./components/funding/EditGrant'))
const EditPublication = lazy(() => import('./components/publication/EditPublication'))
const EditSalary = lazy(() => import('./components/funding/EditSalary'))
const EditTeaching = lazy(() => import('./components/teaching/EditTeaching'))
const ConclusionManager = lazy(() => import('./components/report/concluded-report/ConclusionManager'))
const ViewSupervisionPlan = lazy(() => import('./components/supervision-plan/ViewSupervisionPlan'))
const ViewCourse = lazy(() => import('./components/course/ViewCourse'))
const ViewGrant = lazy(() => import('./components/funding/ViewGrant'))
const ViewPublication = lazy(() => import('./components/publication/ViewPublication'))
const ViewSalary = lazy(() => import('./components/funding/ViewSalary'))
const ViewTeaching = lazy(() => import('./components/teaching/ViewTeaching'))
const ReviewReport = lazy(() => import('./components/report/ReviewReport'))
const ConclusionFeedback = lazy(() => import('./components/report/ConclusionFeedback'))
const ReportView = lazy(() => import('./components/report/ReportView'))
const MyDoctoralCandidates = lazy(() => import('./components/doctoral-candidate-list/MyDoctoralCandidates'))
const TimeLine = lazy(() => import('./components/timeline/TimeLine'))
const UserManager = lazy(() => import('./components/user/UserManager'))
const UserDetails = lazy(() => import('./components/user/UserDetails'))
const ReminderList = lazy(() => import('./components/reminder/ReminderList'))
const ReminderDetails = lazy(() => import('./components/reminder/ReminderDetails'))
const Statistics = lazy(() => import('./components/statistics/Statistics'))
const ReportingStatistics = lazy(() =>
  import('./components/statistics/listDoctoralCandidatesByReportStatus/ReportingStatistics')
)
const ListSupervisorsOfNotCompletedReports = lazy(() =>
  import('./components/statistics/listSupervisorsOfNotCompletedReports/ListSupervisorsOfNotCompletedReports')
)
const DownloadThessaAdminReport = lazy(() =>
  import('./components/statistics/thessaAdminReport/DownloadThessaAdminReport')
)
const ReportConclusionsOverview = lazy(() =>
  import('./components/statistics/report-conclusions-overview/ReportConclusionsOverview')
)
const CoordinatorFrontPage = lazy(() => import('./components/coordinator-front-page/CoordinatorFrontPage'))
const CoordinatorDoctoralCandidates = lazy(() =>
  import('./components/coordinator-doctoral-candidates/CoordinatorDoctoralCandidates')
)
const SupervisorRelationships = lazy(() => import('./components/supervisor-relationships/SupervisorRelationships'))
const ProfileDetails = lazy(() => import('./components/profile/ProfileDetails'))
const ProfileThesisCommitteeMembers = lazy(() => import('./components/profile/ProfileThesisCommittee'))
const Profile = lazy(() => import('./components/profile/Profile'))
const HelpIndex = lazy(() => import('./components/help/HelpIndex'))
const Login = lazy(() => import('./components/auth/Login'))
const ForgotPassword = lazy(() => import('./components/auth/ForgotPassword'))
const ResetPassword = lazy(() => import('./components/auth/ResetPassword'))
const ExternalUserSignUp = lazy(() => import('./components/sign-up/ExternalUserSignUp'))
const Invitation = lazy(() => import('./components/invitation/Invitation'))
const ViewRoar = lazy(() => import('./components/roar/ViewRoar'))
const About = lazy(() => import('./components/about/About'))
const Contact = lazy(() => import('./components/help/Contact'))
const AccessibilityStatement = lazy(() => import('./components/accessibility-statement/AccessibilityStatement'))
const Sitemap = lazy(() => import('./components/sitemap/Sitemap'))
const PathNotFound = lazy(() => import('./components/error/PathNotFound'))

initI18n('fi')

const Loader = () => {
  const { t } = useTranslation()
  return (
    <Frame>
      <Helmet>
        <title>{generatePageTitle(t(k.LOADING), t(k.THESSA))}</title>
      </Helmet>
      <UniContainer wide marginBlock>
        <LoadingIndicator />
      </UniContainer>
    </Frame>
  )
}

const CurrentLanguage = ({ children }) => {
  const { t } = useTranslation()
  const currentLanguage = useCurrentLanguage()
  return (
    <>
      <Helmet>
        <html lang={currentLanguage} />
        <meta name="description" content={t(k.THESSA_IS_THE_DOCTORAL_STUDIES)} />
      </Helmet>
      {children}
    </>
  )
}

render(
  <Suspense fallback={<Loader />}>
    <FeatureFlagContextProvider>
      <WindowWidthContextProvider>
        <CurrentLanguage>
          <Router history={browserHistory} render={applyRouterMiddleware(useScroll())}>
            <Route path="/" component={ThessaApplication}>
              <Route path="phd-project" component={TimeLine}>
                <Route path="chapters" component={Chapters} />
                <Route path="publications/:publicationId" component={EditPublication} />
                <Route path="courses/:courseId" component={EditCourse} />
                <Route path="reports/:reportId/conclusion" component={ConclusionManager} />
                <Route path="reports/:reportId" component={ReportView} />
                <Route path="salaries/:salaryId" component={EditSalary} />
                <Route path="grants/:grantId" component={EditGrant} />
                <Route path="teachings/:teachingId" component={EditTeaching} />
                <Route path="supervision-plans/:supervisionPlanId" component={ViewSupervisionPlan} />
                <Route path="cumulative-report" component={CumulativeReport} />
              </Route>
              <Route path="doctoral-candidates" component={MyDoctoralCandidates}>
                <Route path=":doctoralCandidateId" component={DoctoralCandidateOverview}>
                  <Route path="chapters" component={Chapters} />
                  <Route path="publications/:publicationId" component={ViewPublication} />
                  <Route path="courses/:courseId" component={ViewCourse} />
                  <Route path="salaries/:salaryId" component={ViewSalary} />
                  <Route path="grants/:grantId" component={ViewGrant} />
                  <Route path="teachings/:teachingId" component={ViewTeaching} />
                  <Route path="supervision-plans/:supervisionPlanId" component={ViewSupervisionPlan} />
                  <Route path="cumulative-report" component={CumulativeReport} />
                  <Route path="review-report" component={ReviewReport} />
                  <Route path="reports/:reportId/conclusion-feedback" component={ConclusionFeedback} />
                </Route>
              </Route>
              <Route path="users" component={UserManager}>
                <Route path=":userId" component={UserDetails}>
                  <Route path="overview" component={DoctoralCandidateOverview}>
                    <Route path="chapters" component={Chapters} />
                    <Route path="publications/:publicationId" component={ViewPublication} />
                    <Route path="courses/:courseId" component={ViewCourse} />
                    <Route path="salaries/:salaryId" component={ViewSalary} />
                    <Route path="grants/:grantId" component={ViewGrant} />
                    <Route path="teachings/:teachingId" component={ViewTeaching} />
                    <Route path="supervision-plans/:supervisionPlanId" component={ViewSupervisionPlan} />
                    <Route path="cumulative-report" component={CumulativeReport} />
                  </Route>
                </Route>
              </Route>
              <Route path="reminders" component={ReminderList}>
                <Route path=":reminderId" component={ReminderDetails} />
              </Route>
              <Route path="statistics" component={Statistics} />
              <Route path="statistics/reporting" component={ReportingStatistics} />
              <Route path="statistics/not-completed-reports" component={ListSupervisorsOfNotCompletedReports} />
              <Route path="statistics/thessa-admin-report" component={DownloadThessaAdminReport} />
              <Route path="statistics/report-conclusions-overview" component={ReportConclusionsOverview} />
              <Route path="coordinator" component={CoordinatorFrontPage} />
              <Route path="coordinator/doctoral-candidates" component={CoordinatorDoctoralCandidates} />
              <Route path="coordinator/supervisor-relationships" component={SupervisorRelationships} />
              <Route path="profile" component={Profile}>
                <IndexRoute component={ProfileDetails} />
                <Route path="thesis-committee" component={ProfileThesisCommitteeMembers} />
              </Route>
              <Route path="help" component={HelpIndex} />
            </Route>
            <Route path="/external-login" component={(props) => <Login {...props} external />} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/reset-password/:email/:resetPasswordToken" component={ResetPassword} />
            <Route path="/external-user-sign-up" component={ExternalUserSignUp} />
            <Route path="/invitations/:token" component={Invitation} />
            <Route path="/roar(/:roarValidFrom)" component={ViewRoar} />
            <Route path="/about" component={About} />
            <Route path="/contact" component={Contact} />
            <Route path="/accessibility-statement" component={AccessibilityStatement} />
            <Route path="/sitemap" component={Sitemap} />
            <Route path="*" component={PathNotFound} />
          </Router>
        </CurrentLanguage>
      </WindowWidthContextProvider>
    </FeatureFlagContextProvider>
  </Suspense>,
  document.getElementById('thessa-application')
)
