import React from 'react'
import styled, { css } from 'styled-components'
import {
  GRAYSCALE_DARK_TEXT,
  ADDITIONAL_RED_SOFT,
  ADDITIONAL_GREEN_SOFT,
  GRAYSCALE_MEDIUM,
  GRAYSCALE_BLACK
} from './colors'
import { HyIcon } from '../icon/HyIcon'
import { fromTabletUp } from '../../styledUtils'
import { UniButton } from './UniButton'
import { useTranslation } from 'react-i18next'
import k from '../../../i18n/keys'

interface UniBannerProperties {
  id?: string
  variant?: 'info' | 'error' | 'success' | 'wrench'
  small?: boolean
  gray?: boolean
  white?: boolean
  border?: boolean
  marginTop?: boolean
  marginBottom?: boolean
  paddingInline?: boolean
  paddingBlock?: boolean
  itemsToStart?: boolean
  alignSelfToStart?: boolean
  fullWidth?: boolean
  overflowAuto?: boolean
  close?: () => void
  children: React.ReactNode
  className?: string
}

export const UniBanner = ({
  id,
  variant,
  small,
  gray = true,
  white,
  border = true,
  marginTop,
  marginBottom,
  paddingInline = true,
  paddingBlock = true,
  close,
  fullWidth,
  overflowAuto,
  itemsToStart,
  alignSelfToStart,
  children,
  className
}: UniBannerProperties) => {
  const { t } = useTranslation()
  return (
    <Banner
      id={id}
      className={className}
      marginTop={marginTop}
      marginBottom={marginBottom}
      paddingInline={paddingInline}
      paddingRight={close}
      small={small}
      gray={gray}
      white={white}
      border={border}
      fullWidth={fullWidth}
      alignSelfToStart={alignSelfToStart}
      variant={variant}
    >
      {variant ? (
        <Icon
          small={small}
          info={variant === 'info'}
          alert={variant === 'error'}
          valid={variant === 'success'}
          wrench={variant === 'wrench'}
          aria-hidden={true}
        />
      ) : null}

      <Content
        paddingBlock={paddingBlock}
        paddingRight={close || variant}
        small={small}
        variant={variant}
        itemsToStart={itemsToStart}
        className={
          variant === 'error'
            ? 'errorBanner'
            : variant === 'success'
            ? 'successBanner'
            : variant === 'info'
            ? 'infoBanner'
            : ''
        }
      >
        <InnerContent
          role={variant === 'error' ? 'alert' : variant === 'success' ? 'status' : undefined}
          overflowAuto={overflowAuto}
        >
          {children}
        </InnerContent>
        {close ? (
          <CloseButtonWrapper small={small}>
            <UniButton minimal secondary onClick={close} icon="remove" visuallyHideText aria-expanded="true">
              {t(k.CLOSE)}
            </UniButton>
          </CloseButtonWrapper>
        ) : null}
      </Content>
    </Banner>
  )
}

const Banner = styled.div<{
  marginTop?: boolean
  marginBottom?: boolean
  paddingInline?: boolean
  paddingRight?: UniBannerProperties['close']
  small?: boolean
  gray?: boolean
  white?: boolean
  border?: boolean
  fullWidth?: boolean
  alignSelfToStart?: boolean
  variant?: UniBannerProperties['variant']
}>`
  position: relative;
  margin-top: ${(props) => (props.marginTop ? '12px' : '0')};
  margin-bottom: ${(props) => (props.marginBottom ? '12px' : '0')};
  display: flex;
  gap: 22px;
  flex: 0 1 auto;

  ${fromTabletUp(css`
    gap: 28px;
  `)}

  ${({ small }) =>
    small &&
    css`
      gap: 16px;

      ${fromTabletUp(css`
        gap: 24px;
      `)}
    `}

  ${({ gray }) =>
    gray &&
    css`
      background-color: rgba(0, 0, 0, 0.04);
    `}

  ${({ white }) =>
    white &&
    css`
      background-color: white;
    `}

  ${({ border }) =>
    border &&
    css`
      border: 1px solid ${GRAYSCALE_MEDIUM};
    `}

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      flex: 1 1 auto;
      width: 100%;
    `}

  ${({ alignSelfToStart }) =>
    alignSelfToStart &&
    css`
      align-self: flex-start;
    `}

  ${({ paddingInline }) =>
    paddingInline &&
    css`
      padding-inline: 16px;

      ${fromTabletUp(css`
        padding-inline: 24px;
      `)}
    `}

  ${({ paddingInline, small }) =>
    paddingInline &&
    small &&
    css`
      padding-inline: 26px;

      ${fromTabletUp(css`
        padding-inline: 22px;
      `)}
    `}

    ${({ variant, small }) => {
    if (variant === 'error') {
      return css`
        background-color: ${ADDITIONAL_RED_SOFT};
        border-radius: ${small ? '28px;' : '0px;'};

        a {
          color: ${GRAYSCALE_BLACK};
        }
      `
    } else if (variant === 'success') {
      return css`
        background-color: ${ADDITIONAL_GREEN_SOFT};
        border-radius: ${small ? '28px;' : '0px;'};

        a {
          color: ${GRAYSCALE_BLACK};
        }
      `
    }
  }}

    ${({ paddingRight }) =>
    paddingRight &&
    css`
      padding-right: 12px;

      ${fromTabletUp(css`
        padding-right: 48px;
      `)}
    `}
`

const Icon = styled(HyIcon)<{ small?: boolean }>`
  display: flex;
  align-items: center;
  color: ${GRAYSCALE_DARK_TEXT};
  font-size: 1.25em;

  ${fromTabletUp(css`
    font-size: 1.65em;
  `)}

  ${({ small }) =>
    small &&
    css`
      font-size: 1.1em;

      ${fromTabletUp(css`
        font-size: 1.35em;
      `)}
    `}
`

const Content = styled.div<{
  paddingBlock?: boolean
  paddingRight?: UniBannerProperties['close'] | UniBannerProperties['variant']
  small?: boolean
  itemsToStart?: boolean
  variant?: UniBannerProperties['variant']
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${({ paddingBlock }) =>
    paddingBlock &&
    css`
      padding-block: 16px;

      ${fromTabletUp(css`
        padding-block: 24px;
      `)}
    `}

  ${({ paddingBlock, small }) =>
    paddingBlock &&
    small &&
    css`
      padding-block: 8px;

      ${fromTabletUp(css`
        padding-block: 12px;
      `)}
    `}

  ${({ paddingRight }) =>
    paddingRight &&
    css`
      padding-right: 28px;

      ${fromTabletUp(css`
        padding-right: 32px;
      `)}
    `}

  ${({ variant }) =>
    (variant === 'error' || variant === 'success') &&
    css`
      font-weight: 600;
    `}

  ${({ itemsToStart }) =>
    itemsToStart &&
    css`
      align-items: flex-start;
    `}
`

const InnerContent = styled.div<{
  overflowAuto?: boolean
}>`
  width: 100%;
  height: 100%;

  ${({ overflowAuto }) =>
    overflowAuto &&
    css`
      overflow: auto;
    `}
`

const CloseButtonWrapper = styled.div<{ small?: boolean }>`
  position: absolute;
  top: 12px;
  right: 12px;

  ${({ small }) =>
    small &&
    css`
      top: 1px;
      right: 6px;
    `}
`

export const FailureText = styled.span`
  margin: 0;
  font-weight: 600;
`
