import React, { useContext, useMemo } from 'react'
import map from 'lodash/map'
import { IsNewOrChanged } from './icon/IsNewOrChanged'
import Table from './table/Table'
import { Link } from 'react-router'
import { useTranslation } from 'react-i18next'
import k from '../../i18n/keys'
import VisuallyHidden from './VisuallyHidden'
import CellDefault from './table/CellDefault'
import { WindowWidthContext } from '../../WindowWidthContext'

const columnClassNames: {
  [key: string]: string
} = {
  title: 'thessa-list__title',
  location: 'thessa-list__location',
  credits: 'thessa-list__credits',
  status: 'thessa-list__status',
  statusIcon: 'thessa-list__status-icon',
  progress: 'thessa-list__progress',
  createdAt: 'thessa-list__created-at',
  isCompleted: 'thessa-list__completed',
  isAccepted: 'thessa-list__is-accepted',
  completionDate: 'thessa-list__completion-date',
  dates: 'thessa-list__dates',
  months: 'thessa-list__months',
  publications: 'thessa-list__publications',
  manuscripts: 'thessa-list__manuscripts',
  courses: 'thessa-list__courses',
  workHours: 'thessa-list__work-hours',
  thesisLevel: 'thessa-list__thesis-level',
  role: 'thessa-list__role'
}

interface ThessaListProperties {
  id: string
  rows: any[]
  columns: any
  noContentMessage: string
  totalRow: any
  edgePadding: boolean
  label: string
  white?: boolean
  defaultSortByID?: string
  defaultSortByDesc?: boolean
}

const ThessaList = ({
  id,
  rows,
  columns,
  noContentMessage,
  totalRow,
  edgePadding,
  white,
  defaultSortByID,
  defaultSortByDesc
}: ThessaListProperties) => {
  const { t } = useTranslation()
  const { isDesktopOrLarger, isPhoneOrSmaller } = useContext(WindowWidthContext)

  const tableColumns = useMemo(
    () => [
      ...map(columns, (heading, columnType) => {
        switch (columnType) {
          case 'title':
            return {
              Header: function TitleHeader() {
                return <CellDefault className={columnClassNames[columnType]}>{heading}</CellDefault>
              },
              accessor: 'title',
              Cell: function TitleCell({ row, value }: any) {
                const { original } = row
                const { linkTarget, newOrChanged } = original
                if (linkTarget) {
                  return (
                    <CellDefault className={columnClassNames[columnType]}>
                      <IsNewOrChanged thessaList newOrChanged={newOrChanged} />
                      <Link to={linkTarget}>{value ? value : <em>{t(k.UNTITLED)}</em>}</Link>
                    </CellDefault>
                  )
                } else {
                  return (
                    <CellDefault className={columnClassNames[columnType]}>
                      {value ? value : <em>{t(k.UNTITLED)}</em>}
                      <IsNewOrChanged thessaList newOrChanged={newOrChanged} />
                    </CellDefault>
                  )
                }
              },
              maxWidth: isDesktopOrLarger ? 250 : 700
            }
          case 'dates':
          case 'months':
            return {
              Header: function DatesMonthsHeader() {
                return <CellDefault className={columnClassNames[columnType]}>{heading}</CellDefault>
              },
              accessor: columnType,
              maxWidth: 150,
              Cell: function DatesMonthsCell(properties: any) {
                return <CellDefault {...properties} className={columnClassNames[columnType]} />
              }
            }
          case 'completionDate':
          case 'createdAt':
            return {
              Header: function CompletionCreatedAtDateHeader() {
                return <CellDefault className={columnClassNames[columnType]}>{heading}</CellDefault>
              },
              accessor: columnType,
              maxWidth: 75,
              Cell: function CompletionCreatedAtCell(properties: any) {
                return <CellDefault {...properties} className={columnClassNames[columnType]} />
              }
            }
          case 'isCompleted':
            return {
              Header: function IsCompletedHeader() {
                return (
                  <CellDefault justifyCenter={!isPhoneOrSmaller} className={columnClassNames[columnType]}>
                    {heading}
                  </CellDefault>
                )
              },
              accessor: columnType,
              maxWidth: 75,
              Cell: function IsCompletedCell(properties: any) {
                return (
                  <CellDefault
                    {...properties}
                    justifyCenter={!isPhoneOrSmaller}
                    className={columnClassNames[columnType]}
                  />
                )
              }
            }
          case 'statusIcon':
            return {
              Header: function StatusIconHeader() {
                return (
                  <CellDefault justifyCenter={!isPhoneOrSmaller} className={columnClassNames[columnType]}>
                    {heading}
                  </CellDefault>
                )
              },
              accessor: columnType,
              maxWidth: 100,
              Cell: function StatusIconCell(properties: any) {
                return (
                  <CellDefault
                    {...properties}
                    justifyCenter={!isPhoneOrSmaller}
                    className={columnClassNames[columnType]}
                  />
                )
              }
            }
          case 'credits':
            return {
              Header: function CreditsHeader() {
                return (
                  <CellDefault justifyEnd={!isPhoneOrSmaller} className={columnClassNames[columnType]}>
                    {heading}
                  </CellDefault>
                )
              },
              accessor: columnType,
              maxWidth: 75,
              Cell: function CreditsCell(properties: any) {
                return (
                  <CellDefault
                    {...properties}
                    justifyEnd={!isPhoneOrSmaller}
                    className={columnClassNames[columnType]}
                  />
                )
              },
              Footer: function CreditsFooter({ rows }: any) {
                const totalCompleted = useMemo(
                  () =>
                    rows
                      .filter((row: any) => row.values.isCompleted !== null)
                      .reduce((sum: number, row: any) => row.values.credits + sum, 0),
                  [rows]
                )
                const totalPlanned = useMemo(
                  () => rows.reduce((sum: number, row: any) => row.values.credits + sum, 0),
                  [rows]
                )
                return (
                  <CellDefault justifyEnd={!isPhoneOrSmaller} className="thessa-list__credits">
                    {isPhoneOrSmaller ? (
                      <>
                        {t(k.TOTAL_AMOUNT_OF_CREDITS)}
                        {': '}
                      </>
                    ) : (
                      <VisuallyHidden>
                        {t(k.TOTAL_AMOUNT_OF_CREDITS)}
                        {': '}
                      </VisuallyHidden>
                    )}
                    {totalCompleted.toFixed(1)}
                    {' / '}
                    {totalPlanned.toFixed(1)}
                  </CellDefault>
                )
              }
            }
          case 'workHours':
            return {
              Header: function WorkHoursHeader() {
                return (
                  <CellDefault justifyEnd={!isPhoneOrSmaller} className={columnClassNames[columnType]}>
                    {heading}
                  </CellDefault>
                )
              },
              accessor: columnType,
              maxWidth: 75,
              Cell: function WorkHoursCell(properties: any) {
                return (
                  <CellDefault
                    {...properties}
                    justifyEnd={!isPhoneOrSmaller}
                    className={columnClassNames[columnType]}
                  />
                )
              }
            }
          default:
            return {
              Header: function DefaultHeader() {
                return <CellDefault className={columnClassNames[columnType]}>{heading}</CellDefault>
              },
              accessor: columnType,
              maxWidth: 75,
              Cell: function DefaultCell(properties: any) {
                return <CellDefault {...properties} className={columnClassNames[columnType]} />
              }
            }
        }
      })
    ],
    [isPhoneOrSmaller]
  )

  if (rows.length > 0) {
    return (
      <Table
        className="thessa-list"
        id={id}
        columns={tableColumns}
        data={rows.reverse()}
        truncatedCells={false}
        defaultSortByID={defaultSortByID}
        defaultSortByDesc={defaultSortByDesc}
        showFooter={totalRow}
        cardTitleAccessor="title"
        edgePadding={edgePadding}
        sorting
        white={white}
      />
    )
  } else {
    return <div className="thessa-list__no-content">{noContentMessage}</div>
  }
}

export default ThessaList
