import React from 'react'
import styled from 'styled-components'
import { ChapterItemType } from './types'
import { ChapterProps } from './Chapter'
import Meeting from './meeting/Meeting'
import { GRAYSCALE_BLACK, GRAYSCALE_WHITE } from '../common/uni/colors'
import { UniList, UniListItem } from '../common/uni/UniList'
import { useChapterProgress } from './util'
import StudyRight from './study-right/StudyRight'

export interface ChapterProgressProps {
  timelineVisualScale: number
  chapter: ChapterProps['chapter']
  chapters: ChapterProps['chapters']
  widget?: boolean
  teaser?: boolean
  className?: string
}

const ChapterProgress = ({
  timelineVisualScale,
  chapter,
  chapters,
  widget,
  teaser,
  className
}: ChapterProgressProps) => {
  const {
    containerRef,
    itemReferences,
    barHeight,
    completedTop,
    completedHeight,
    completedItemPositions,
    isNextStartEventCompleted,
    isCurrentStartEventCompleted,
    areAllChapterItemsCompleted
  } = useChapterProgress(chapter, chapters)

  const { items } = chapter || {}

  const filteredItems = teaser ? items?.filter((item) => item.type === ChapterItemType.Meeting) : items

  return (
    <ProgressBarContainer ref={containerRef} className={className} timelineVisualScale={timelineVisualScale}>
      <ProgressBarLine height={barHeight} timelineVisualScale={timelineVisualScale} />

      <CompletedBarLine
        timelineVisualScale={timelineVisualScale}
        top={completedTop}
        height={completedHeight}
        isVisible={completedHeight > 0}
      />

      {!isNextStartEventCompleted ? (
        <Dot
          timelineVisualScale={timelineVisualScale}
          top={completedTop + completedHeight - timelineVisualScale * 2}
          hollow={false}
          isVisible={completedHeight > 0}
        />
      ) : null}

      <List noMargin timelineVisualScale={timelineVisualScale} widget={widget}>
        {filteredItems?.map((item, index) => (
          <Item
            key={item.id}
            noBullet
            noMargin
            timelineVisualScale={timelineVisualScale}
            ref={(element) => (itemReferences.current[index] = element)}
          >
            {item.type === ChapterItemType.StudyRight ? (
              <StudyRight
                timelineVisualScale={timelineVisualScale}
                content={item}
                chapter={chapter}
                chapters={chapters}
                isNextStartEventCompleted={isNextStartEventCompleted}
              />
            ) : null}
            {item.type === ChapterItemType.Meeting ? (
              <Meeting
                timelineVisualScale={timelineVisualScale}
                content={item}
                chapter={chapter}
                chapters={chapters}
                isNextStartEventCompleted={isNextStartEventCompleted}
              />
            ) : null}
          </Item>
        ))}
      </List>
      {!teaser ? <Actions timelineVisualScale={timelineVisualScale}></Actions> : null}
    </ProgressBarContainer>
  )
}

const ProgressBarContainer = styled.div<{ timelineVisualScale: number }>`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  max-width: 640px;
  width: 100%;
`

const List = styled(UniList)<{ timelineVisualScale: number; widget?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ timelineVisualScale }) => timelineVisualScale * 6}px;
  margin-bottom: ${({ timelineVisualScale }) => timelineVisualScale * 6}px;
  width: 100%;
`

const Item = styled(UniListItem)<{ timelineVisualScale: number }>``

const ProgressBarLine = styled.div<{
  timelineVisualScale: number
  height: number
}>`
  position: absolute;
  left: ${({ timelineVisualScale }) => timelineVisualScale * 7 - timelineVisualScale / 2}px;
  top: 0;
  width: 2px;
  height: ${(props) => props.height}px;
  border-left: ${({ timelineVisualScale }) => timelineVisualScale}px dotted ${GRAYSCALE_BLACK};
`

const CompletedBarLine = styled.div<{
  timelineVisualScale: number
  top: number
  height: number
  isVisible: boolean
}>`
  position: absolute;
  left: ${({ timelineVisualScale }) => timelineVisualScale * 7 - timelineVisualScale / 2}px;
  top: ${(props) => props.top}px;
  width: 2px;
  height: ${(props) => props.height}px;
  border-left: ${({ timelineVisualScale }) => timelineVisualScale}px solid ${GRAYSCALE_BLACK};
  transition: top 0.3s ease, height 0.3s ease, opacity 0.3s ease;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
`

const Dot = styled.div<{
  timelineVisualScale: number
  top: number
  hollow?: boolean
  isVisible?: boolean
}>`
  position: absolute;
  left: ${({ timelineVisualScale }) => timelineVisualScale * 7 - timelineVisualScale * 2}px;
  top: ${(props) => props.top}px;
  width: ${({ timelineVisualScale }) => timelineVisualScale * 4}px;
  height: ${({ timelineVisualScale }) => timelineVisualScale * 4}px;
  background-color: ${({ hollow }) => (hollow ? GRAYSCALE_WHITE : GRAYSCALE_BLACK)};
  border: ${({ hollow, timelineVisualScale }) =>
    hollow ? `${timelineVisualScale}px solid ${GRAYSCALE_BLACK}` : 'none'};
  border-radius: 50%;
  transition: top 0.3s ease, opacity 0.3s ease;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
`

const Actions = styled.div<{
  timelineVisualScale: number
}>`
  margin-left: ${({ timelineVisualScale }) => timelineVisualScale * 18}px;
  margin-bottom: ${({ timelineVisualScale }) => timelineVisualScale * 12}px;
`

export default ChapterProgress
