import React from 'react'
import styled, { css } from 'styled-components'
import { fromTabletUp } from '../../styledUtils'
import { GRAYSCALE_BLACK, GRAYSCALE_DARK_TEXT, GRAYSCALE_LIGHT, ADDITIONAL_BLUE_SOFT } from '../uni/colors'

interface PageContentProps {
  id?: string
  title?: string
  actions?: React.ReactNode
  gray?: boolean
  lightBlue?: boolean
  paddingInline?: boolean
  paddingBlock?: boolean
  paddingTop?: boolean
  paddingBottom?: boolean
  wide?: boolean
  border?: boolean
  children?: React.ReactNode
}

const PageContent = ({
  id,
  title,
  actions,
  gray,
  lightBlue,
  paddingInline = true,
  paddingBlock = true,
  paddingTop = false,
  paddingBottom = false,
  wide = true,
  border = true,
  children
}: PageContentProps) => {
  return (
    <Content
      id={id}
      gray={gray}
      lightBlue={lightBlue}
      paddingInline={paddingInline}
      paddingBlock={paddingBlock}
      border={border}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
    >
      <Children wide={wide} lightBlue={lightBlue}>
        {title && <Title>{title}</Title>}
        {children}
        {actions && <Actions>{actions}</Actions>}
      </Children>
    </Content>
  )
}

const Content = styled.div<{
  gray?: PageContentProps['gray']
  lightBlue?: PageContentProps['lightBlue']
  paddingInline?: PageContentProps['paddingInline']
  paddingBlock?: PageContentProps['paddingBlock']
  paddingTop?: PageContentProps['paddingTop']
  paddingBottom?: PageContentProps['paddingBottom']
  border?: PageContentProps['border']
}>`
  max-width: 100%;
  display: block;
  height: auto;

  ${fromTabletUp(css`
    flex: 1 1 calc(3 / 4 * 100%);
    margin: 0 auto;
  `)}

  &:not(:empty) {
    width: 100%;

    ${({ border }) =>
      border &&
      css`
        border-top: 1px solid ${GRAYSCALE_BLACK};
      `}

    ${({ paddingBlock }) =>
      paddingBlock &&
      css`
        padding-block: 16px;

        ${fromTabletUp(css`
          padding-block: 32px;
        `)}
      `}

    ${({ paddingInline }) =>
      paddingInline &&
      css`
        padding-inline: 16px;

        ${fromTabletUp(css`
          padding-inline: 32px;
        `)}
      `}

      ${({ paddingTop }) =>
      paddingTop &&
      css`
        padding-top: 16px;

        ${fromTabletUp(css`
          padding-top: 32px;
        `)}
      `}

      ${({ paddingBottom }) =>
      paddingBottom &&
      css`
        padding-bottom: 16px;

        ${fromTabletUp(css`
          padding-bottom: 32px;
        `)}
      `}
  }

  ${({ gray = false }) =>
    gray &&
    css`
      background-color: ${GRAYSCALE_LIGHT};
    `}

  ${({ lightBlue = false }) =>
    lightBlue &&
    css`
      position: relative;
      overflow: hidden;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        min-height: 1200px;
        height: 100%;
        background: linear-gradient(180deg, ${ADDITIONAL_BLUE_SOFT} 0%, #e3eafa 100%);
      }
    `}
`

const Children = styled.div<{ wide?: PageContentProps['wide']; lightBlue?: PageContentProps['lightBlue'] }>`
  max-width: calc(3 / 4 * 100%);
  width: 100%;
  margin: 0 auto;

  ${({ wide }) =>
    wide &&
    css`
      max-width: 100%;
    `}

  ${({ lightBlue = false }) =>
    lightBlue &&
    css`
      position: relative;
    `}
`

export const Title = styled.h2`
  color: ${GRAYSCALE_DARK_TEXT};
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;

  margin: 0 0 8px 0;

  &:not(:first-child) {
    margin: 32px 0 8px 0;
  }
`

const Actions = styled.div`
  position: relative;
  margin-top: 24px;
  padding-top: 24px;

  &:before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    height: 1px;
    width: 36px;
    background: rgb(85, 85, 85);
  }
`

export default PageContent
