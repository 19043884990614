import React, { PropsWithChildren, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import k from '../../i18n/keys'
import Page from '../common/page/Page'
import PageHeading from '../common/page/PageHeading'
import { Endpoints, get, useGet } from '../../network'
import PageBlock from '../common/page/PageBlock'
import PageLoadingIndicator from '../common/page/PageLoadingIndicator'
import { DoctoralCandidateList } from './DoctoralCandidateList'
import { MyDoctoralCandidateRole } from '../../model'
import { InjectedRouter, WithRouterProps } from 'react-router'
import DoctoralCandidate, {
  DoctoralCandidateResponse,
  DoctoralCandidateRolesResponse
} from '../../models/DoctoralCandidate'
import { Location } from 'history'
import { PageAlert } from '../common/page/PageAlert'
import { Role } from '../../stores/role'
import { Helmet } from 'react-helmet'
import { generatePageTitle } from '../../util'

export type MyDoctoralCandidate = {
  studentNumber: string
  name: string
  roles: MyDoctoralCandidateRole[]
  hasActiveStudyRight: boolean
  doctoralCandidateId?: number
  progress?: number
  latestReportStatus?: 'ok' | 'not ok' | 'not applicable'
  supervisionPlanStatus?: 'ok' | 'not ok'
}

type MyDoctoralCandidatesResponse = MyDoctoralCandidate[]

const MyDoctoralCandidates = ({
  params,
  location,
  router,
  children
}: WithRouterProps<{ doctoralCandidateId: string }> & PropsWithChildren<{}>) => {
  const { t } = useTranslation()
  const { data: doctoralCandidates } = useGet<MyDoctoralCandidatesResponse>(Endpoints.myDoctoralCandidates())
  const { unableToRedirectToDoctoralCandidate } = useStudentNumberQueryParameterRedirecting(
    doctoralCandidates,
    location,
    router
  )

  const { doctoralCandidateOverview } = useDoctoralCandidateOverview(params.doctoralCandidateId)

  const isOverviewVisible = React.Children.count(children) > 0 && doctoralCandidateOverview
  if (isOverviewVisible) {
    const requesterRole = getRequesterRole(doctoralCandidateOverview.roles)
    return React.cloneElement(children as React.ReactElement, {
      doctoralCandidate: doctoralCandidateOverview,
      baseUrl: `/doctoral-candidates/${doctoralCandidateOverview.id}/`,
      title: doctoralCandidateOverview.name,
      role: requesterRole,
      backLinkUrl: '/doctoral-candidates'
    })
  }

  return (
    <Page>
      <Helmet>
        <title>{generatePageTitle(t(k.MY_DOCTORAL_CANDIDATES1), t(k.THESSA))}</title>
      </Helmet>
      <PageHeading>{t(k.MY_DOCTORAL_CANDIDATES1)}</PageHeading>
      {unableToRedirectToDoctoralCandidate && (
        <PageBlock padTop>
          <PageAlert warning>
            <Trans
              t={t}
              i18nKey={k.NAME_IS_NOT_YET_ON_THESSA_WHEN_THEY_SIGN_UP}
              values={{ name: unableToRedirectToDoctoralCandidate.name }}
              components={[<strong />]} //eslint-disable-line react/jsx-key
            />
          </PageAlert>
        </PageBlock>
      )}
      {!doctoralCandidates && <PageLoadingIndicator>{t(k.LOADING_DOCTORAL_CANDIDATES)}</PageLoadingIndicator>}
      {doctoralCandidates && (
        <PageBlock padTop>
          <DoctoralCandidateList
            doctoralCandidates={doctoralCandidates}
            loadingDoctoralCandidateId={parseInt(params.doctoralCandidateId)}
          />
        </PageBlock>
      )}
    </Page>
  )
}

function useStudentNumberQueryParameterRedirecting(
  doctoralCandidates: MyDoctoralCandidate[],
  location: Location,
  router: InjectedRouter
) {
  const [unableToRedirectToDoctoralCandidate, setUnableToRedirectToDoctoralCandidate] =
    useState<MyDoctoralCandidate>(null)

  useEffect(() => {
    setUnableToRedirectToDoctoralCandidate(null)
    if (location.query.studentNumber && doctoralCandidates) {
      const doctoralCandidateToRedirectTo = doctoralCandidates.find(
        (doctoralCandidate) => doctoralCandidate.studentNumber === location.query.studentNumber
      )
      if (doctoralCandidateToRedirectTo.doctoralCandidateId) {
        router.push(`/doctoral-candidates/${doctoralCandidateToRedirectTo.doctoralCandidateId}`)
      } else {
        setUnableToRedirectToDoctoralCandidate(doctoralCandidateToRedirectTo)
      }
    }
  }, [location.query.studentNumber, doctoralCandidates])

  return { unableToRedirectToDoctoralCandidate }
}

function useDoctoralCandidateOverview(doctoralCandidateId: string) {
  const [doctoralCandidateOverview, setDoctoralCandidateOverview] = useState<DoctoralCandidate>()
  useEffect(() => {
    setDoctoralCandidateOverview(undefined)

    if (doctoralCandidateId) {
      get<DoctoralCandidateResponse>(Endpoints.doctoralCandidateOverview(doctoralCandidateId)).then(
        (doctoralCandidateOverview) => {
          setDoctoralCandidateOverview(new DoctoralCandidate(doctoralCandidateOverview))
        }
      )
    }
  }, [doctoralCandidateId])
  return { doctoralCandidateOverview }
}

export function getRequesterRole(roles: DoctoralCandidateRolesResponse): Role {
  if (roles.isRequesterDoctoralCandidate) {
    return Role.DOCTORAL_CANDIDATE
  } else if (roles.isRequesterSupervisor) {
    return Role.SUPERVISOR
  } else if (roles.isRequesterMemberOfThesisCommittee) {
    return Role.THESIS_COMMITTEE_MEMBER
  } else if (roles.isRequesterSalarySystemForeman) {
    return Role.SALARY_SYSTEM_FOREMAN
  } else if (roles.isRequesterCoordinator) {
    return Role.COORDINATOR
  }
}

export default MyDoctoralCandidates
