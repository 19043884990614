import { useTranslation } from 'react-i18next'
import k from '../../i18n/keys'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { Actions } from './common/Actions'
import { TooltipConfirm } from '../common/TooltipConfirm'
import { WithRole } from './WithRole'
import { IsNewOrChanged } from '../common/icon/IsNewOrChanged'
import { DetailsComponent, FormComponent, OnDelete, OnSave, ResponsiveColumns, RowComponent } from './types'
import { HyIcon } from '../common/icon/HyIcon'
import { ADDITIONAL_YELLOW, GRAYSCALE_DARK, GRAYSCALE_DARK_TEXT, GRAYSCALE_MEDIUM_DARK } from '../common/uni/colors'
import { getPhdProjectListColumns } from './constants'
import { UniButton } from '../common/uni/UniButton'
import VisuallyHidden from '../common/VisuallyHidden'
import { PageAlert } from '../common/page/PageAlert'
import { ContactUsLink } from '../common/ContactUs'
import { wasUpdatedNow } from '../styledUtils'

interface PhdProjectListRowProps<T> {
  columns: ResponsiveColumns
  item: T
  Row: RowComponent<T>
  Details: DetailsComponent<T>
  Form: FormComponent<T>
  onEdit: OnSave<T>
  onDelete: OnDelete<T>
  newOrChanged: string
  wasCreatedNow: boolean
}

const PhdProjectListRowComponent = <T extends { id: number | string }>({
  columns,
  item,
  Row,
  Details,
  Form,
  onEdit,
  onDelete,
  newOrChanged,
  wasCreatedNow
}: PhdProjectListRowProps<T>) => {
  const { t } = useTranslation()
  const [isExpanded, setIsExpanded] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [isConfirmDeleteTooltipVisible, setIsConfirmDeleteTooltipVisible] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [actionError, setActionError] = useState<string | null>(null)

  if (isEditing) {
    const hideForm = () => setIsEditing(false)
    return (
      <RowContainer $columns={columns} isExpanded className="phd-project-list-row phd-project-list-row--edit">
        <EditContainer>
          <Form item={item} onSave={(item) => onEdit(item).then(hideForm)} onCancel={hideForm} />
        </EditContainer>
      </RowContainer>
    )
  }

  const actionButtons = (
    <>
      <UniButton onClick={() => setIsEditing(true)} disabled={isDeleting} className="phd-project-list-row__edit">
        {t(k.EDIT)}
      </UniButton>
      <TooltipConfirm
        isVisible={isConfirmDeleteTooltipVisible}
        onCancel={() => setIsConfirmDeleteTooltipVisible(false)}
        onDelete={() => {
          setActionError(null)
          setIsConfirmDeleteTooltipVisible(false)
          setIsDeleting(true)
          onDelete(item).catch(() => {
            setIsDeleting(false)
            setActionError(k.PHD_PROJECT_LIST.ERROR_DELETE_FAILED)
          })
        }}
      >
        <UniButton secondary disabled={isDeleting} onClick={() => setIsConfirmDeleteTooltipVisible(true)}>
          {t(k.DELETE)}
        </UniButton>
      </TooltipConfirm>
    </>
  )

  return (
    <RowContainer
      $columns={columns}
      onClick={() => setIsExpanded(true)}
      isExpanded={isExpanded}
      wasCreatedNow={wasCreatedNow}
      className="phd-project-list-row"
    >
      <Row item={item} highlight={<IsNewOrChanged phdProjectList newOrChanged={newOrChanged} />} />
      <ToggleIsExpandedButton
        onClick={(event) => {
          event.stopPropagation()
          setIsExpanded(!isExpanded)
        }}
      >
        <HyIcon caretDown={!isExpanded} caretUp={isExpanded} />
        <VisuallyHidden>{isExpanded ? t(k.HIDE_DETAILS) : t(k.SHOW_DETAILS)}</VisuallyHidden>
      </ToggleIsExpandedButton>

      {isExpanded && (
        <RowDetails $columnCount={columns.titles.length}>
          <RowDetailsSeparator />
          <Details item={item} />
          <WithRole doctoralCandidate>
            <Actions buttons={actionButtons} />
            {actionError && (
              <PageAlert>
                {t(actionError)}
                &nbsp;
                <ContactUsLink>{t(k.CONTACT_US)}</ContactUsLink>
                &nbsp;
                {t(k.IF_THE_PROBLEM_DOES_NOT_GO_AWA)}
              </PageAlert>
            )}
          </WithRole>
        </RowDetails>
      )}
    </RowContainer>
  )
}

export const PhdProjectListRow = observer(PhdProjectListRowComponent)

const RowContainer = styled.div<{ $columns: ResponsiveColumns; isExpanded?: boolean; wasCreatedNow?: boolean }>`
  position: relative;
  align-items: center;
  border: 1px solid ${(props) => (props.isExpanded ? GRAYSCALE_DARK : 'transparent')};
  background: #fff;
  color: ${GRAYSCALE_DARK_TEXT};
  cursor: pointer;
  display: grid;
  padding: 0 0 0 16px;
  grid-column-gap: 16px;
  ${(props) => getPhdProjectListColumns(props.$columns)}

  ${(props) =>
    props.isExpanded &&
    css`
      cursor: default;
    `}

  ${(props) => wasUpdatedNow(props.wasCreatedNow)}
`

const ToggleIsExpandedButton = styled.button`
  align-self: stretch;
  background: transparent;
  border: none;
  color: #337ab7;
  cursor: pointer;
  font-size: 14px;
  padding: 10px 16px;

  &:focus {
    outline: solid 2px ${ADDITIONAL_YELLOW};
  }
`

const RowDetails = styled.div<{ $columnCount: number }>`
  display: grid;
  grid-column: span ${(props) => props.$columnCount + 1};
  grid-auto-rows: max-content;
  grid-row-gap: 10px;
  padding: 0 16px 20px 0;
`

const RowDetailsSeparator = styled.div`
  border-top: 1px solid ${GRAYSCALE_MEDIUM_DARK};
  height: 4px;
`

const EditContainer = styled.div`
  cursor: default;
  grid-column: span 4;
  padding: 20px 16px 20px 0;
`
