import React from 'react'
import Card from '../common/Card'
import CardActionButton from '../doctoral-candidate-overview/common/CardActionButton'
import Chapter from './Chapter'
import { InjectedRouter, withRouter } from 'react-router'
import { useTranslation } from 'react-i18next'
import k from '../../i18n/keys'
import { useChapters, getCurrentChapter } from './util'

interface ChaptersWidgetProps {
  baseUrl: string
  showViewButton: boolean
  router: InjectedRouter
}

const ChaptersWidget = ({ baseUrl, showViewButton, router }: ChaptersWidgetProps) => {
  const { t } = useTranslation()
  const { chapters } = useChapters()
  const currentChapter = getCurrentChapter(chapters)
  const currentChapterIndex = chapters.findIndex((chapter) => chapter.id === currentChapter?.id)
  const nextChapter = chapters[currentChapterIndex + 1]

  return (
    <Card
      heading={t(k.CHAPTERS)}
      cardAction={
        showViewButton ? (
          <CardActionButton icon="edit" onClick={() => router.push(`${baseUrl}chapters`)}>
            {t(k.VIEW_CHAPTERS)}
          </CardActionButton>
        ) : null
      }
      modifier="chapters"
    >
      {currentChapter ? <Chapter chapter={currentChapter} chapters={chapters} widget /> : null}
      {nextChapter ? <Chapter chapter={nextChapter} chapters={chapters} widget teaser /> : null}
    </Card>
  )
}

export default withRouter(ChaptersWidget)
