{
  "key": "value",
  "ABOUT": "Lisätietoja",
  "THESSA": "Thessa",
  "THESSA_IS_THE_DOCTORAL_STUDIES": "Thessa on Helsingin yliopiston jatko-opintojen suunnittelu- ja raportointityökalu.",
  "ALL_KINDS_OF_FEEDBACK_AND_ERRO": "Kaikenlainen palaute ja virheilmoitukset ovat erittäin tervetulleita.",
  "THESSA_ADMIN_HELSINKI_FI": "thessa-admin@helsinki.fi",
  "RIGHTS_OBLIGATIONS_AND_RESPON": "Oikeudet, velvollisuudet ja vastuut tohtorikoulutuksessa Helsingin yliopistossa",
  "PROBLEMS_LOGGING_IN": "Ongelmia kirjautumisessa?",
  "IF_YOU_FORGOT_YOUR_PASSWORD_Y": "Jos olet unohtanut salasanasi, voit",
  "RESET_IT": "vaihtaa sen",
  "SEND_PASSWORD_RESET_LINK_TO_MY": "Lähetä salasanan palautuslinkki sähköpostiini",
  "EMAIL_FOR_THESSA_ACCOUNT": "Sähköposti Thessa-tiliä varten",
  "SEND": "Lähetä",
  "BACK_TO_LOGIN": "Takaisin sisäänkirjautumiseen",
  "BACK_TO_MY_PHD_PROJECT": "Takaisin väitöskirjaprojektiini",
  "MESSAGE_SENT_CHECK_YOUR_EMAIL": "Viesti lähetetty. Tarkista sähköpostisi ja avaa salasanan palautusviestissä oleva linkki.",
  "TOO_MANY_PASSWORD_RESET_REQUES": "Liian monta salasanan palautuspyyntöä.",
  "WAIT_FIVE_MINUTES_BEFORE_TRYIN": "Odota viisi minuuttia ennen kuin yrität uudelleen. Jos ongelma jatkuu, <0>ota yhteyttä meihin</0> saadaksesi lisää apua.",
  "HELPDESK": "ota yhteyttä IT-Helpdeskiin.",
  "YOU_MAY_RESET_YOUR_PASSWORD": "voit vaihtaa salasanasi.",
  "SOMETHING_WENT_WRONG": "Jotain meni pieleen.",
  "TRY_AGAIN_LATER": "Yritä myöhemmin uudelleen. Jos ongelma jatkuu, <0>ota meihin yhteyttä</0> saadaksesi apua.",
  "RIGHTS_OBLIGATIONS_AND_RESPON1": "Oikeudet, velvollisuudet ja vastuut tohtorikoulutuksessa Helsingin yliopistossa",
  "ABOUT_THESSA": "Tietoja Thessasta",
  "THE_DOCTORAL_STUDIES_PLANNING": "Tohtoriopintojen suunnittelu- ja raportointityökalu.",
  "EMAIL": "Sähköposti",
  "PASSWORD": "Salasana",
  "LOG_IN": "Kirjaudu sisään",
  "LOGIN_WAS_NOT_SUCCESSFUL": "Kirjautuminen ei onnistunut.",
  "CHECK_USERNAME_AND_PASSWORD_I": "Tarkista käyttäjätunnus ja salasana. Jos se ei auta, saat apua täältä",
  "UNIVERSITY_OF_HELSINKI_LOGIN": "Onko sinulla Helsingin yliopiston käyttäjätunnukset?",
  "USE_UNIVERSITY_OF_HELSINKI_LOGIN": "Käytä Helsingin yliopiston kirjautumista",
  "UNIVERSITY_OF_HELSINKI_USERNAM": "Helsingin yliopiston käyttäjätunnus",
  "CHECK_USERNAME_AND_PASSWORD_I1": "Tarkista käyttäjätunnus ja salasana. Jos se ei auta,",
  "TOO_MANY_FAILED_LOGIN_ATTEMPTS": "Liian monta epäonnistunutta kirjautumisyritystä.",
  "WAIT_FIVE_MINUTES_BEFORE_TRYIN1": "Odota viisi minuuttia ennen kuin yrität uudelleen. Tarkasta käyttäjätunnus ja salasana. Jos se ei auta, saat apua täältä",
  "YOU_NEED_AN_INVITATION_OR_AN_A": "Tarvitset kutsun tai aktiivisen jatko-opiskeluoikeuden päästäksesi Thessaan.",
  "YOUR_EMAIL_ADDRESS_IS_ALREADY": "Sähköpostiosoitteesi on jo jonkun toisen käytössä Thessassa.",
  "CONTACT_US": "Ota meihin yhteyttä",
  "CONTACT_US_END_OF_SENTENCE": "ota meihin yhteyttä.",
  "FOR_HELP_WITH_THIS_ERROR": "apua tähän virheeseen.",
  "LOGIN_FAILED_WITH_ERROR_CODE": "Kirjautuminen epäonnistui virhekoodilla",
  "NON_UNIVERSITY_LOGIN_EXTERNAL": "Oletko ulkopuolinen käyttäjä?",
  "USE_NON_UNIVERSITY_LOGIN": "Käytä yliopiston ulkopuolista kirjautumista",
  "NON_UNIVERSITY_LOGIN": "Yliopiston ulkopuolinen kirjautuminen ulkopuolisille käyttäjille",
  "USE_YOUR_UNIVERSITY_OF_HELSINK": "Kirjaudu sisään Helsingin yliopiston käyttäjätunnuksellasi ja salasanallasi.",
  "FORGOT_PASSWORD": "Unohtuiko salasana?",
  "RESET_PASSWORD": "Nollaa salasana",
  "NEW_PASSWORD": "Uusi salasana",
  "SAVE_PASSWORD": "Tallenna salasana",
  "CANCEL": "Peruuta",
  "PASSWORD_SET_REDIRECTING_YOU": "Salasana asetettu. Ohjataan uudelleen",
  "INSIDE": "sisään",
  "THERE_WERE_PROBLEMS_WITH_SAVIN": "Salasanan tallentamisessa oli ongelmia:",
  "SIGN_UP_TO_THESSA": "Kirjaudu Thessaan",
  "IF_YOU_HAVE_A_UNIVERSITY_OF_HE": "Jos sinulla on Helsingin yliopiston tili, käytä sen käyttäjätunnusta ja salasanaa yllä olevassa kirjautumislomakkeessa. Kaikilla opiskelijoilla ja työntekijöillä on tili.",
  "MON": "Ma",
  "TUE": "Ti",
  "WED": "Ke",
  "THU": "To",
  "FRI": "Pe",
  "SAT": "La",
  "SUN": "Su",
  "LOADING_WITH_ELLIPSIS": "Ladataan…",
  "YOU_MAY_USE": "Voit käyttää",
  "MARKDOWN": "Markdown",
  "ITALICS": "*kursivointi*",
  "BOLD": "**lihavoitu**",
  "LINKS_HTTPS": "[linkit](https://...)",
  "HEADINGS": "# otsikkoa",
  "LISTS": "- listat",
  "AND_MORE": "ja enemmän.",
  "THESSA_FOR": "Thessa",
  "UNIVERSITY_OF_HELSINKI": "Helsingin yliopisto",
  "PRIVACY_POLICY": "Tietosuojakäytäntö",
  "ASK_FOR_HELP_AND_GIVE_FEEDBACK": "Pyydä apua ja anna palautetta",
  "TOGGLE_NAVIGATION": "Vaihda navigointi",
  "LOG_OUT": "Kirjaudu ulos",
  "LOGIN": "Kirjaudu sisään",
  "REMINDERS1": "Muistutukset",
  "PROFILE1": "Profiili",
  "HELP1": "Ohje",
  "MY_PHD_PROJECT": "Väitöskirjaprojektini",
  "MY_DOCTORAL_CANDIDATES": "Väitöskirjatutkijani",
  "DOCTORAL_CANDIDATES1": "Väitöskirjatutkijat",
  "USERS1": "Käyttäjät",
  "QUEUE1": "Jono",
  "STATISTICS1": "Tilastot",
  "LEFT": "vasemmalle",
  "BOTTOM": "pohja",
  "ARE_YOU_SURE": "Oletko varma?",
  "CONFIRM_DELETE": "Kyllä, poista",
  "THANKS": "Kiitos!",
  "YOU_CAN_GIVE_MORE_FEEDBACK_HER": "Lisää palautetta voit antaa täältä:",
  "SEND_FEEDBACK": "Lähetä palautetta",
  "PAGE_HEADER": "Sivun otsikko",
  "HEADING": "Otsikko 1",
  "HEADING1": "Otsikko 2",
  "HEADING2": "Otsikko 3",
  "HEADING3": "Otsikko 4",
  "HEADING4": "Otsikko 5",
  "HEADING5": "Otsikko 6",
  "LEAD_TEXT": "Pääteksti",
  "BODY_TEXT": "Leipäteksti",
  "SMALL_TEXT": "Pieni teksti",
  "LABEL": "Tunniste 1",
  "LABEL1": "Tunniste 2",
  "LABEL2": "Etiketti 3",
  "CHECK": "Tarkista 1",
  "CHECK1": "Tarkista 2",
  "SUBMIT": "Lähetä",
  "SAVE": "Tallenna",
  "TOGGLE": "Vaihda",
  "CALENDAR": "kalenteri",
  "OK": "okei",
  "REMOVE": "Poista",
  "SUBMITTED": "Lähetetty",
  "CONCLUSION_MISSING": "Loppulause puuttuu",
  "GIVE_FEEDBACK": "Anna palautetta",
  "DEFAULT": "Oletus",
  "RIGHT": "Oikea",
  "LEFT1": "Vasen",
  "CALL_TO_ACTION": "Kehotus toimintaan",
  "SECONDARY": "Toissijainen",
  "ACTION": "Toiminta",
  "ACTIONS": "Toiminnot",
  "STUDENT_NUMBER": "Opiskelijanumero",
  "NAME": "Nimi",
  "ACCOUNT": "Tili",
  "NO_ACCOUNT": "Ei tiliä",
  "NO_SUPERVISORS_IN_OODI": "Oodissa ei ole ohjaajia",
  "STUDY_RIGHT_START_DATE": "Opinto-oikeuden alkamispäivä",
  "RECENT": "VIIMEAIKAINEN",
  "COORDINATOR_QUEUE": "Koordinaattorijono",
  "LOADING_INACTIVE_USERS": "Ladataan ei-aktiivisia käyttäjiä...",
  "THERE_WAS_AN_ERROR_IN_LOADING": "Ei-aktiivisten käyttäjien lataamisessa tapahtui virhe.",
  "PLEASE": "Kun haluat antaa palautetta tai tarvitset apua Thessan käytössä,",
  "TRY_AGAIN": "yritä uudelleen",
  "LET_US_KNOW": "kerro meille",
  "IF_THE_PROBLEM_DOES_NOT_GO_AWA": "jos ongelma ei poistu.",
  "INACTIVE_USER": "Ei-aktiivinen käyttäjä",
  "TYPE": "Tyyppi",
  "N_A": "Ei käytössä",
  "NO_COURSES_IN_PLAN_YET": "Kursseja ei ole vielä suunnitteilla.",
  "ADD_ONE_BY_CLICKING_THE": "Lisää yksi napsauttamalla",
  "ADD": "Lisää",
  "BUTTON_ABOVE": "painiketta yllä!",
  "TITLE": "Otsikko",
  "CREDITS": "Opintopisteet",
  "STATUS": "Tila",
  "COMPLETED": "Suoritettu",
  "NOT_COMPLETED": "Ei valmis",
  "COMPLETED_AT": "Suoritettu",
  "TO_BE_COMPLETED_AT": "Suoritetaan päivämääränä",
  "NOT_AVAILABLE": "Ei saatavilla",
  "CHANGED_SINCE_LAST_OPENED": "Muuttunut edellisen avauksen jälkeen",
  "REPORT": "Raportti",
  "SUPERVISION_PLAN": "Ohjaussuunnitelma",
  "SUPERVISION_PLAN_other": "Ohjaussuunnitelmat",
  "MY_DOCTORAL_CANDIDATES1": "Väitöskirjatutkijani",
  "LOADING_DOCTORAL_CANDIDATES": "Ladataan väitöskirjatutkijoita...",
  "FAILED_TO_LOAD_DOCTORAL_CANDID": "Väitöskirjatutkijoiden lataaminen epäonnistui. Voit yrittää ladata sivun uudelleen. Jos virhe jatkuu, ota yhteyttä Thessa-tukeen.",
  "MISSING_DOCTORAL_CANDIDATES": "Puuttuuko väitöskirjatutkijoita?",
  "TO_SEE_YOUR_DOCTORAL_CANDIDATE": "Nähdäksesi väitöskirjatutkijasi Thessassa, varmista, että he ilmoittautuvat Thessaan ja kutsuvat sinut ohjaajaksi tai seurantaryhmän jäseneksi.",
  "YOU_WILL_GET_THE_INVITATION_BY": "Saat kutsun sähköpostiisi. Hyväksy se saadaksesi pääsy väitöskirjatutkijan tietoihin.",
  "HIDE": "Piilota",
  "SHOW": "Näytä",
  "CLOSED_ACCOUNTS": "suljetut tilit",
  "RELATIONSHIP": "Suhde",
  "PROGRESS": "Edistyminen",
  "YOU_HAVE_NO_DOCTORAL_CANDIDATE": "Sinulla ei ole väitöskirjatutkijoita Thessassa.",
  "NO_DOCTORAL_CANDIDATES": "Ei väitöskirjatutkijoita.",
  "YOUR_USER_ACCOUNT_NEEDS_TO_BE": "Käyttäjätilisi on aktivoitava ennen kuin voit luoda ensimmäisen raportin. Ennen kuin tilisi voidaan aktivoida, sinun on tehtävä se",
  "INVITE_ALL_YOUR_SUPERVISORS_AN": "kutsu kaikki ohjaajasi ja seurantaryhmän jäsenet",
  "IN_THESSA": "Thessassa.",
  "YOU_WILL_RECEIVE_AN_EMAIL_WHEN": "Saat sähköpostin, kun tilisi on aktivoitu.",
  "INVITE_SUPERVISORS": "Kutsu ohjaajat",
  "THERE_ARE_NO_SUPERVISION_PLANS": "Ohjaussuunnitelmia ei toistaiseksi ole.",
  "DOCTORAL_CANDIDATE_AND_SUPERVI": "Väitöskirjatutkija ja ohjaajat sopivat noudattavansa ohjaussuunnitelmaa, joka määrittelee väitöskirjatutkijan ja ohjaajien yhteistyön. Suunnitelma laaditaan ohjaajan tilillä ja sen jälkeen väitöskirjatutkija ja ohjaajat allekirjoittavat sen.",
  "SUPERVISORS": "Ohjaajat",
  "LOADING_NEXT_REPORT_DUE_DATE": "Ladataan seuraavaa raportin eräpäivää...",
  "NEXT_REPORT_DUE_ON": "Seuraavan raportin määräaika on",
  "NEXT_REPORT_RECOMMENDED_TO_BE_SENT_AT": "Raportti kannattaa lähettää noin viikkoa ennen seurantaryhmän kokousta.",
  "PERIOD": "Kausi",
  "PUBLICATIONS": "Julkaisut",
  "COURSES": "Kurssit",
  "GRADUATION": "Valmistuminen",
  "CONCLUSION": "Loppulause",
  "DRAFT": "Luonnos",
  "OPEN": "Avaa",
  "MISSING": "Puuttuu",
  "HAVE_A_THESIS_COMMITTEE_MEETIN": "Pidä seurantaryhmän kokous ja kirjoita loppulause avaamalla raportti.",
  "REPORTS": "Raportit",
  "LOADING_REPORTS": "Ladataan raportteja...",
  "NO_REPORTS_YET": "Ei vielä raportteja",
  "VIEW_CUMULATIVE_REPORT": "Näytä kumulatiivinen raportti",
  "THESSA_IS_IN_MAINTENANCE": "Thessa on huollossa",
  "PLEASE_WAIT_A_FEW_MINUTES_AND": "Odota muutama minuutti ja <0>lataa sivu uudelleen</0>",
  "RELOAD_THE_PAGE": "Lataa sivu uudelleen",
  "PAGE_NOT_FOUND": "Sivua ei löydetty",
  "SORRY_THE_PAGE_YOU_OPENED_CAN": "Avaamaasi sivua ei valitettavasti löydy. Kokeile",
  "FRONT_PAGE_INSTEAD": "etusivua",
  "ADD_GRANT": "Lisää apuraha",
  "ADD_SALARY": "Lisää palkka",
  "SALARIES": "Palkat",
  "GRANTS": "Apurahat",
  "NO_SALARIES_IN_PLAN_YET": "Palkkaa ei ole vielä suunnitteilla.",
  "NO_SALARIES": "Ei palkkoja.",
  "NO_GRANTS_IN_PLAN_YET": "Suunnitelmissa ei ole vielä apurahoja.",
  "NO_GRANTS": "Ei apurahoja.",
  "SOURCE": "Lähde",
  "AMOUNT": "Määrä",
  "FOR_MONTHS": "Kuukaudet",
  "USED_FOR": "Käytetään",
  "NOT_GRANTED": "Ei myönnetty",
  "GRANTED": "Myönnetty",
  "FOR_DATES": "Päivämäärät",
  "HELP_MORE_GUIDES_LINK_PREFIX": "Avaa",
  "HELP_MORE_GUIDES_LINK_URL": "https://studies.helsinki.fi/ohjeet/artikkeli/thessa",
  "HELP_MORE_GUIDES_LINK_LINK_TEXT": "Opiskelijan ohjeet",
  "HELP_MORE_GUIDES_LINK_SUFFIX": "saadaksesi lisää ohjeita Thessan käyttöön.",
  "THE_THREE_MAIN_PARTS_OF_THESSA": "Thessan kolme toimintoa",
  "THESSA_CONSISTS_OF_THREE_MAIN": "Thessan toiminta perustuu kolmeen eri toiminnallisuuteen:",
  "PHD_PROJECT1": "Väitöskirjaprojekti",
  "SUPERVISION_PLAN_IS_A_SET_OF_D": "Ohjaussuunnitelma on väitöskirjatutkijan ja hänen ohjaajiensa yhdessä tekemä sopimus, jonka tarkoitus on varmistaa, että väitöskirjatyö sujuu ongelmitta. Ohjaaja laatii ohjaussuunnitelman omassa Thessa-näkymässään, minkä jälkeen väitöskirjatutkija ja muut ohjaajat voivat sen hyväksyä.",
  "PHD_PROJECT_IS_A_PLACE_FOR_DOC": "Väitöskirjaprojektini on alue, jossa väitöskirjatutkija voi suunnitella väitöskirjatyön vaiheita ja tohtoriopintojaan ja kirjata niiden etenemistä. Väitöskirjaprojektini on jaettu osiin, joita ovat mm. julkaisut, kurssit ja rahoitus. Nämä tiedot ja paljon muutakin väitöskirjatutkija voi tallentaa Thessaan ja jakaa ne ohjaajiensa kanssa.",
  "ANOTHER_GREAT_BENEFIT_OF_KEEPI": "Suoritusten ja suunnitelmien kirjaaminen Thessaan kannattaa, sillä Thessa luo näiden tietojen pohjalta automaattisesti valtaosan raportin sisällöstä.",
  "DOCTORAL_CANDIDATES_AND_SUPERV": "Väitöskirjatutkija ja ohjaajat",
  "DOCTORAL_CANDIDATES_ARE_THE_ON": "Väitöskirjatutkija kutsuu itse ohjaajansa Thessaan Profile-välilehden kautta. Thessa lähettää ohjaajalle kutsun väitöskirjatutkijan ilmoittamaan sähköpostiosoitteeseen. Kutsussa on linkki, jonka avulla ohjaaja voi liittyä väitöskirjatutkijan ohjaajaksi Thessassa. Linkki on voimassa kaksi viikkoa. Ohjaajan ja väitöskirjatutkijan välinen yhteys on näin väitöskirjatutkijan vastuulla eivätkä ohjaajat voi muulla tavoin valita ohjattaviaan Thessassa. Käytäntö suojaa väitöskirjatutkijan tietoja luvattomalta käytöltä. Väitöskirjatutkija toimii näin itse Thessa-tilinsä portinvartijana.",
  "THE_DOCTORAL_CANDIDATE_CAN_STA": "Väitöskirjatutkija voi alkaa käyttää Thessan väitöskirjaprojektini -toimintoa heti kirjauduttuaan sisään ensimmäistä kertaa. Ohjaussuunnitelman tekemiseksi tarvitaan kuitenkin ohjaaja ja raportointia varten myös seurantaryhmä tulee olla liitettynä väitöskirjatutkijan Thessaan.",
  "TO_LATER_CHANGE_DOCTORAL_CANDI": "Jos myöhemmin halutaan vaihtaa väitöskirjatutkijan ohjaajia, hänen tulee käydä profiilisivullaan.",
  "THESIS_COMMITTEES_TC": "Seurantaryhmä",
  "THESIS_COMMITTEES_ARE_REQUIRED": "Helsingin yliopiston tohtoriohjelmat edellyttävät, että väitöskirjatutkijalla on seurantaryhmä elokuusta 2020 alkaen. Monilla ohjelmilla seurantaryhmä on jo nyt vaadittu käytäntö. Väitöskirjatutkija kutsuu seurantaryhmän jäsenet käyttämällä Profiili-välilehden kutsutyökalua. Seurantaryhmän jäsenet voivat tarkastella ja kommentoida väitöskirjatutkijan raporttia Thessassa.",
  "THESSA_SUPPORTS_INVITING_MEMBE": "Seurantaryhmän kokouksen päätteeksi väitöskirjatutkija kirjoittaa kokouksen yhteenvedon eli loppulauseen (Conclusions and recommendations) seurantaryhmän jäsenten ollessa paikalla. Yhteenvedosta lähtee automaattisesti sähköpostiviesti seurantaryhmän jäsenille. Yhteenvedon kirjoittaminen sulkee raportin ja on näin edellytyksenä seuraavan raportin luomiselle.",
  "THE_CONCLUSIONS_AND_RECOMMEND": "Loppulauseen kirjoittaa väitöskirjatutkija seurantaryhmän kokouksen aikana, kun kaikki seurantaryhmän jäsenet ovat paikalla.",
  "THESSA_ASKS_DOCTORAL_CANDIDATE": "Thessa muistuttaa väitöskirjatutkijaa raportoinnista vähintään kerran vuodessa tohtoriopintojen keston ajan. Raportti on katsaus väitöskirjaprojektin senhetkiseen tilanteeseen ja siksi suurin osa raportin sisällöstä saadaan automaattisesti väitöskirjaprojektini -alueen tiedoista. Raportilla kysytään lisäksi joitakin lisätietoja, jotka väitöskirjatutkijan tulee itse kirjoittaa.",
  "AFTER_THE_DOCTORAL_CANDIDATE_H": "Kun väitöskirjatutkija on lähettänyt raportin Thessassa, voivat ohjaajat ja seurantaryhmän jäsenet tarkastella sitä välittömästi. Raportin yhteydessä on keskustelupalkki, jossa kaikki osapuolet voivat kommentoida raportin sisältöä ja ehdottaa muutoksia tai parannuksia. Väitöskirjatutkija saa kommentit myös sähköpostiinsa ja voi muokata raporttia tarpeen mukaan.",
  "THERE_WILL_BE_REMINDER_EMAILS": "Raportoinnin määräajoista lähetetään muistutussähköposteja. Raportin määräaika on aina seuraavien päivämäärien myöhempi päivämäärä:",
  "ONE_YEAR_FROM_THE_LAST_REPORT": "Vuosi edellisestä raportista.",
  "ONE_YEAR_FROM_THE_BEGINNING_OF": "Vuosi tohtoriopintojen alkamisesta.",
  "CLOSING_THE_REPORT": "Raportin sulkeminen",
  "AT_THE_END_OF_REPORTING_CYCLE": "Raportoinnin päätteeksi, kun kaikki seurantaryhmän ehdottamat muutokset raporttiin on tehty, väitöskirjatutkija sulkee raportin kirjoittamalla loppulauseen eli yhteenvedon (Conclusions and recommendations). Tyypillisesti yhteenveto kirjoitetaan seurantaryhmäkokouksen päätteeksi yhdessä seurantaryhmän jäsenten kanssa.",
  "IN_SOME_DOCTORAL_PROGRAMS_THE": "Joissakin tohtoriohjelmissa seurantaryhmiä ei vielä edellytetä ja raportin lukee vain ohjaaja(t). Tällaisessa tapauksessa väitöskirjatutkijan tulee sulkea raportti kirjoittamalla yhteenvetokenttään (Conclusions and recommendations) esim. omia muistiinpanojaan.",
  "FEEDBACK_AND_ERROR_REPORTS": "Palaute ja tukipyynnöt",
  "CONTACT_US1": "ota meihin yhteyttä",
  "TRY_TO_INCLUDE_AS_MUCH_DETAI": "Kerro ongelmasta tai virhetilanteesta mahdollisimman yksityiskohtaisesti. Thessa on jatkuvan kehityksen kohteena ja kaikki palaute on meille arvokasta.",
  "IF_YOU_NEED_MORE_HELP_PLEASE": "Jos sinulla on kysyttävää Thessasta tai tarvitset apua siihen liittyen,",
  "INVITE": "Kutsu",
  "S_EMAIL_ADDRESS": "{{roleName}} käyttäjän sähköpostiosoite",
  "INVITE_TO_YOUR_PHD_PROJECT_IN": "Kutsu väitöskirjaprojektiisi Thessassa",
  "INVITATION_SUBMITTED": "Kutsu lähetetty",
  "SUBMITTING_INVITATION_FAILED": "Kutsun lähettäminen epäonnistui",
  "YOU_ARE_INVITED": "Sinut on kutsuttu",
  "WHAT_IS_THESSA": "Mikä on Thessa?",
  "ALL_DOCTORAL_CANDIDATES_AND_TH": "Sitä voivat käyttää kaikki väitöskirjatutkijat ja heidän ohjaajansa sekä seurantaryhmän jäsenet.",
  "WELCOME_TO_THESSA": "Tervetuloa Thessaan!",
  "YOU_ARE_NOT_CONNECTED_TO_ANYON": "Et ole yhdistetty keneenkään.",
  "ASK_YOUR_DOCTORAL_CANDIDATES_T": "Pyydä väitöskirjatutkijoitasi lähettämään sinulle kutsu Thessastaan.",
  "OTHERWISE_IT_S_GOING_TO": "Muuten täällä tulee olemaan yksinäistä.",
  "NO_NOTES": "Ei muistiinpanoja.",
  "EDIT": "Muokkaa",
  "SAVING": "Tallentaa...",
  "UPDATE_ANNUAL_REPORTS": "Päivitys: vuosikertomukset",
  "ANNUAL_REPORTS_SECTION_HAS_BEE": "Vuosiraportit-osio on päivitetty näyttämään selvemmin raportin tilan ja pyydetyt toimenpiteet.",
  "UPDATE_CUMULATIVE_REPORT": "Päivitys: kumulatiivinen raportti",
  "CUMULATIVE_REPORT_VIEW_SHOWS_A": "Kumulatiivinen raporttinäkymä näyttää kaikki raportit samanaikaisesti, mikä helpottaa kokonaiskuvan ymmärtämistä. Avaa vuosiraportit -osiossa painamalla 'Näytä kumulatiivinen raportti' -painiketta.",
  "UPDATE_REVIEWING_REPORTS": "Päivitys: Raporttien tarkistaminen",
  "CUMULATIVE_REPORT": "Kumulatiivinen raportti",
  "YOU_CAN_NOW_SEE_THE_CUMULATIVE": "Voit nyt nähdä kumulatiivisen raportin tarkistaessasi.",
  "COMMENTING_REPORT": "Kommentointiraportti",
  "DISCUSS_REPORTS": "Keskustele raporteista.",
  "NEW_IMPROVEMENTS": "Uusia parannuksia!",
  "INVITE_SUPERVISORS_AND_THESIS": "Kutsu ohjaajia ja seurantaryhmän jäseniä käyttämällä sähköpostiosoitteita.",
  "DISCUSS_IN_SIMPLIFIED_ANNUAL_R": "Keskustele yksinkertaistetussa vuosikertomuksessa.",
  "REPORTS_ARE_CLOSED_BY_WRITING": "Raportit päätetään kirjoittamalla loppulause.",
  "READ_MORE_FROM_HELP_PAGE": "Lue lisää ohjesivulta.",
  "SET_OF_DECISIONS_BETWEEN_DOCTO": "Sopimukset väitöskirjatutkijan ja ohjaajan välillä, jotta varmistetaan, että väitöskirjaprojekti etenee sujuvasti.",
  "KEEPING_TRACK_OF_FUTURE_PLANS": "Tulevaisuuden opintojen etenemissuunnitelmien seuraaminen, julkaisut ja muut väitöskirjatutkijan tohtorintutkintoon liittyvät asiat.",
  "A_SNAPSHOT_OF_PHD_PROJECT_IT": "Tilannekuva väitöskirjaprojektista. Se luodaan kerran vuodessa auttamaan väitöskirjatutkijan ja ohjaajan välistä kommunikaatiota.",
  "START_USING_THESSA": "Aloita Thessan käyttö!",
  "WE_RECOMMEND_THAT_YOU_START_US": "Suosittelemme, että aloitat Thessan käytön luomalla ohjaussuunnitelman. Ota yhteyttä ohjaajaasi sen luomiseksi.",
  "THEN_YOU_SHOULD_UPDATE_YOUR_PH": "Sitten sinun tulee päivittää väitöskirjaprojektisuunnitelmasi Thessassa niin, että ne näkyvät selvästi sinulle ja ohjaajalle. Voit aloittaa tämän myös heti!",
  "YOU_CAN_FIND_MORE_DETAILS_ABOU": "Löydät lisätietoja Thessasta päävalikon Ohje-osiosta.",
  "IF_YOU_FIND_BUGS_OR_ERRORS_IN": "Jos löydät bugeja tai virheitä Thessasta tai sinulla on idea sen parantamiseksi,",
  "DROP_AN_EMAIL_TO_US": "laita meille sähköpostia",
  "YOUR_DOCTORAL_CANDIDATES_WILL": "Väitöskirjatutkijasi yhdistävät pian Thessa-tilinsä sinun tiliisi. Sen jälkeen suosittelemme, että aloitat Thessan käytön luomalla ohjaussuunnitelman väitöskirjatutkijoille. Luo se oman Thessa-tilisi kanssa ja sitten jokainen allekirjoittaa sen Thessa-tilillään.",
  "REMOVE1": "Poista",
  "CONFERENCES_AND_MOBILITY": "Konferenssit ja liikkuvuus",
  "LOCATION": "Sijainti",
  "DATES": "Päivämäärät",
  "LOADING_CONFERENCES_AND_MOBILI": "Ladataan konferensseja ja liikkuvuutta...",
  "NO_CONFERENCES_OR_MOBILITY_PLA": "Konferensseja tai liikkuvuutta ei ole suunniteltu.",
  "ADD_CONFERENCE_OR_MOBILITY": "Lisää konferenssi tai liikkuvuus",
  "FAILED_TO_CREATE_CONFERENCE_OR_MOBILITY": "Virhe konferenssin tai liikkuvuuden luonnissa.",
  "DELETE": "Poista",
  "REALLY_CANCEL_INVITATION": "Perutko todella kutsun?",
  "KEEP_INVITATION": "Pidä kutsu",
  "CANCEL_INVITATION": "Peruuta kutsu",
  "INVITATION_EXPIRED": "Kutsu on vanhentunut",
  "INVITED_NOT_YET_RESPONDED": "Kutsuttu, ei vielä vastattu",
  "FIRST_NAME": "Etunimi",
  "LAST_NAME": "Sukunimi",
  "EMAIL_ADDRESS": "Sähköpostiosoite",
  "SAVED": "Tallennettu",
  "REALLY_REMOVE": "Haluatko todella poistaa?",
  "THE_LAST_MEMBER_CANNOT_BE_REMO": "Viimeistä jäsentä ei voi poistaa.",
  "RESEND_INVITATION": "Lähetä kutsu uudelleen",
  "INVITATION_TO_BECOME_A": "Kutsu tulla",
  "FOR": "varten",
  "EXPIRES_ON": "Vanhenee",
  "THIS_INVITATION_HAS_EXPIRED_Y": "Tämä kutsu on vanhentunut. Voit pyytää uutta väitöskirjatutkijalta",
  "ACCEPT": "Hyväksy",
  "DECLINE": "Hylkää",
  "DUE_TO_DATA_MIGRATION_THESSA": "Tietojen siirron vuoksi Thessa ei tiedä tämän tilan alkamispäivää. Valitse oikea päivämäärä alta ja tallenna julkaisu.",
  "NO_PUBLICATIONS_IN_PLAN_YET": "Suunnitelmissa ei ole vielä julkaisuja.",
  "TOPIC_OR_TITLE": "Aihe tai otsikko",
  "EDIT_REMINDER": "Muokkaa muistutusta",
  "LOADING_YOUR_REMINDERS": "Ladataan muistutuksiasi",
  "NEW_REMINDER": "Uusi muistutus",
  "UPCOMING_REMINDERS": "Tulevia muistutuksia",
  "PAST_REMINDERS": "Menneitä muistutuksia",
  "NO_REMINDERS": "Ei muistutuksia",
  "DUE": "Erääntynyt",
  "SEND_REMINDERS_TO": "Lähetä muistutuksia osoitteeseen",
  "REMINDER_TIMING": "Muistutuksen ajoitus",
  "DAYS_BEFORE_DUE_DATE": "päivää ennen eräpäivää",
  "CREATE_REPORT": "Luo raportti",
  "YOUR_ACCOUNT_IS_INACTIVE_THER": "Tilisi ei ole aktiivinen, joten et voi vielä luoda raporttia.",
  "YOUR_DOCTORAL_PROGRAMME_S_STAF": "Tohtoriohjelmasi henkilökunta aktivoi tilin puolestasi, kun he ovat tarkastaneet profiilisi.",
  "CANNOT_CREATE_REPORT_WHEN_A_PR": "Raporttia ei voi luoda, jos edellinen raportti on luonnos. Lähetä tai poista se ennen uuden raportin luomista.",
  "OPEN_REPORT": "Avaa raportti",
  "CANNOT_CREATE_REPORT_WHEN_A_PR1": "Raporttia ei voi luoda, jos edellisessä raportissa ei ole loppulausetta eli yhteenvetoa.",
  "SUMMARIZE_THE_REPORTING_PERIOD": "Tee yhteenveto raportointikaudesta",
  "AS_AN_OVERVIEW_OF_THE_REPORTIN": "Listaa raportointikauden yleiskatsauksena kolme positiivista ja kolme negatiivista asiaa.",
  "BELOW_ARE_ALL_COURSES_YOU_COMP": "Alla on kaikki suorittamasi kurssit välillä",
  "YOU_CAN_EDIT_THE_LIST_ON": "Voit muokata luetteloa osoitteessa",
  "PHD_PROJECT_PAGE": "Väitöskirjaprojektin sivu",
  "NO_COURSES_IN_REPORT_RIGHT_NOW": "Tällä hetkellä raportissa ei ole kursseja.",
  "BELOW_ARE_ALL_ACTIVE_SALARIES": "Alla on kaikki aktiiviset palkat ja apurahat, jotka sinulla on suunnitelmassasi välillä",
  "YOU_CAN_EDIT_THE_LISTS_ON_TH": "Voit muokata listoja",
  "NO_SALARIES_IN_REPORT_RIGHT_NO": "Ei palkkoja tällä hetkellä raportissa.",
  "NO_GRANTS_IN_REPORT_RIGHT_NOW": "Raportissa ei tällä hetkellä ole apurahoja.",
  "BELOW_ARE_ALL_CONFERENCES_AND": "Alla on kaikki suorittamasi konferenssit ja liikkuvuudet välillä",
  "NO_CONFERENCES_OR_MOBILITY_IN": "Tällä hetkellä raportissa ei ole konferensseja tai liikkuvuutta.",
  "BELOW_ARE_ALL_PUBLICATIONS_THA": "Alla on kaikki julkaisut, jotka on julkaistu tai keskeneräinen välillä",
  "NO_PUBLICATIONS_IN_REPORT_RIGH": "Tällä hetkellä raportissa ei ole julkaisuja.",
  "BELOW_ARE_ALL_ACTIVE_TEACHINGS": "Alla on kaikki aktiiviset opetukset, jotka sinulla on välillä",
  "NO_COURSES_OR_WORKSHOPS_IN_REP": "Raportissa ei ole tällä hetkellä kursseja tai työpajoja.",
  "NO_THESIS_INSTRUCTIONS_IN_REPO": "Raportissa ei ole tällä hetkellä opinnäytetyöohjeita.",
  "NOT_SUBMITTED": "Ei lähetetty",
  "CONCLUSION_DONE": "Loppulause tehty",
  "WRITE_CONCLUSION": "Kirjoita loppulause",
  "HAVE_A_THESIS_COMMITTEE_MEETIN1": "Pidä seurantaryhmän kokous ja kirjoita raportin loppulause eli yhteenveto.",
  "THE_DOCTORAL_CANDIDATE_NEEDS_T": "Väitöskirjatutkijan tulee järjestää seurantaryhmän kokous ja kirjoittaa loppulause raporttiin.",
  "DONE": "tehty",
  "REPORT_SUBMITTED_FOR_REVIEW_SU": "Raportti lähetetty tarkistettavaksi.",
  "REPORT_SUBMITTED": "Raportti lähetetty",
  "REPORT_UPDATED": "Raportti päivitetty",
  "EDIT_REPORT": "Muokkaa raporttia",
  "YOU_CAN_MAKE_CHANGES_TO_THE_RE": "Voit tehdä muutoksia raporttiin.",
  "NO_REPORTS_TO_REVIEW_FOUND": "Tarkistettavia raportteja ei löytynyt.",
  "EDIT_REVIEW": "Muokkaa arviointia",
  "REVIEW_REPORT": "Tarkista raportti",
  "CHANGES_SAVED": "Muutokset tallennettu",
  "LAST_SAVED": "Viimeksi tallennettu",
  "CANCEL_EDITING": "Peruuta muokkaus",
  "SAVE_REPORT": "Tallenna raportti",
  "SUBMIT_REPORT_FOR_REVIEW": "Lähetä raportti tarkistettavaksi",
  "CREATED_IN": "Luotu vuonna",
  "CONFERENCES_AND_MOBILITY1": "Konferenssit ja liikkuvuus",
  "FUNDING": "Rahoitus",
  "TEACHING": "Opetus",
  "COURSES_AND_WORKSHOPS": "Kurssit ja työpajat",
  "THESIS_INSTRUCTION": "Opinnäytetöiden ohjaus",
  "YOU_ARE_EDITING_THE_REPORT_S_C": "Muokkaat raportin päätelmiä ja suosituksia",
  "SHOW_EDITOR": "Näytä editori",
  "PLEASE_INDICATE_WHO_ATTENDED_T": "Ilmoittakaa, ketkä osallistuivat seurantaryhmän kokoukseen.",
  "THESIS_COMMITTEE_MEETING_FORM": "Seurantaryhmän kokouslomake",
  "CONCLUSION_AND_RECOMMENDATIONS": "Loppulause",
  "NO_REPORT_FOUND_CANNOT_EDIT_CONCLUSION": "Raporttia ei löytynyt. Loppulausetta ei voi muokata.",
  "NO_TEXT_TO_PREVIEW": "Ei tekstiä esikatseltavaksi",
  "CANNOT_SUBMIT_CONCLUSION_EMPTY": "Loppulause ei saa olla tyhjä.",
  "THE_DOCTORAL_CANDIDATE_IS_EXPE": "Väitöskirjatutkijan odotetaan täyttävän tämä lomake väitöskirjatoimikunnan kokouksen päätyttyä, kun kaikki osallistujat ovat paikalla.",
  "PREVIEW": "Esikatselu",
  "SUBMITTING_PLEASE_WAIT": "Lähetetään, odota...",
  "CONCLUSION_CONFIRM_CANCEL": "Oletko varma, että haluat lopettaa loppulauseen muokkaamisen? Kirjoittamasi teksti menetetään.",
  "CONCLUSION_CANNOT_BE_EDITED": "Loppulause on jo kirjoitettu tälle raportille, eikä sitä voi muuttaa jälkikäteen. Voit <0>palata takaisin raportille</0>.",
  "CUMULATIVE_REPORT_FOR": "Kumulatiivinen raportti:",
  "COMPLETED_IN_THE_MOST_RECENT": "valmis viimeisimmässä raportissa",
  "IN_PROGRESS_IN_THE_MOST_RECE": "edistynyt viimeisimmässä raportissa",
  "PUBLICATION_IS_IN_THESIS": "Julkaisu on osa väitöskirjaa.",
  "COMPLETE": "valmis",
  "NOTHING_REPORTED_YET": "Mitään ei ole raportoitu vielä.",
  "DELETE_REPORT": "Poista raportti",
  "YOU_CAN_DELETE_YOUR_REPORT_BEF": "Voit poistaa raportin ennen sen lähettämistä.",
  "COMPLETED1": "valmis",
  "ESTIMATE_OF_HOW_COMPLETE_YOUR": "Arvio siitä, kuinka valmis väitöskirjaprojektisi on prosentteina.",
  "FULL_TIME": "kokopäiväinen",
  "ESTIMATE_OF_FULL_PART_TIME_WOR": "Arvio koko/osa-aikaisesta työstä väitöskirjaprojektissa raportointijaksolla.",
  "YOUR_DOCTORAL_PROGRAMME_ASKS_Y": "Tohtoriohjelmasi pyytää sinua kirjoittamaan tämän raportin saadaksesi käsityksen siitä, miten väitöskirjaprojektisi etenee.",
  "PLEASE_FILL_IN_ALL_OF_THE_FIEL": "Täytä kaikki kentät.",
  "THE_REPORT_WILL_BE_VISIBLE_TO": "Raportti näkyy ohjaajillesi, seurantaryhmän jäsenille ja koordinaattoreille lähetyksen jälkeen.",
  "THIS_REPORT_COVERS_DATES_BETWE": "Tämä raportti kattaa päivämäärät välillä",
  "AND": "ja",
  "WHEN_YOU_ARE_DONE_PRESS_THE_S": "Kun olet valmis, paina Lähetä-painiketta. Painike tulee näkyviin, kun olet täyttänyt kaikki kentät. Lähettämisen jälkeen raportti on avoin kommentoitavaksi.",
  "REMEMBER_TO_SAVE_THE_REPORT_PE": "Muista tallentaa raportti säännöllisesti. Voit tallentaa raportin milloin tahansa ja jatkaa sen kirjoittamista myöhemmin.",
  "MESSAGE_REMOVED": "Viesti poistettu.",
  "DISCUSSION": "Keskustelu",
  "LOADING": "Ladataan...",
  "NO_DOCUMENT_FOUND_WITH_DATE": "Päivämäärällä varustettua asiakirjaa ei löytynyt",
  "APPROVED_BY_THE_BOARD_OF_DOCTO": "Helsingin yliopiston tohtorikoulutuksen johtoryhmän hyväksymä, <0>{{date}}</0>.",
  "THESSA_JOIN_A_THESIS_COMMITT": "Thessa - Liity seurantaryhmään",
  "WITH_YOUR_THESSA_CREDENTIALS_O": "Thessa-tunnuksillasi tai käytä Helsingin yliopiston käyttäjätunnustasi.",
  "OTHERWISE": "Muuten",
  "REGISTER_AN_EXTERNAL_ACCOUNT": "rekisteröi ulkoinen tili",
  "YOU_WILL_USE_THIS_FOR_LOGGING": "Käytät tätä kirjautumiseen.",
  "AT_LEAST_CHARACTERS": "Vähintään 8 merkkiä.",
  "YOUR_ORGANIZATION": "Organisaatiosi",
  "FOR_EXAMPLE_FINNISH_METEOROLO": "Esimerkiksi: Ilmatieteen laitos tai Acme Oy.",
  "THERE_WERE_PROBLEMS_WITH_SIGN": "Ilmoittautumisessa oli ongelmia:",
  "WELCOME_TO_THESSA_AS_A_NON_UN": "Tervetuloa Thessaan! Mikäli sinulla ei ole Helsingin yliopiston käyttäjätunnusta, voit luoda tilisi tästä.",
  "ERROR": "Virhe",
  "SORRY_AN_ERROR_HAS_OCCURRED_I": "Valitettavasti rekisteröitymisessä tapahtui virhe. Yritä uudelleen päivittämällä sivu tai",
  "DOCTORAL_STUDIES": "Tohtoriopinnot",
  "FILL_IN_DETAILS_OF_YOUR_STUDIE": "Täytä tiedot opinnoistasi.",
  "BEGINNING_OF_DOCTORAL_STUDIES": "Tohtoriopintojen alkaminen",
  "DOCTORAL_PROGRAMME": "Tohtoriohjelma",
  "BACK": "Takaisin",
  "CONTINUE": "Jatka",
  "OR": "tai",
  "LOG_OUT1": "kirjaudu ulos",
  "WELCOME_TO_THESSA1": "Tervetuloa Thessaan",
  "WE_NEED_SOME_INFORMATION_ABOUT": "Tarvitsemme tietoja sinusta, ennen kuin voit jatkaa.",
  "MY_ROLE_IN_THESSA": "Roolini Thessassa",
  "DOCTORAL_CANDIDATE": "Väitöskirjatutkija",
  "SUPERVISOR": "Ohjaaja",
  "SUPERVISOR_other": "Ohjaajat",
  "THESIS_COMMITTEE_MEMBER": "Seurantaryhmän jäsen",
  "VERIFY_YOUR_DETAILS": "Vahvista tietosi",
  "WE_FOUND_THE_FOLLOWING_DETAILS": "Löysimme seuraavat tiedot sinusta automaattisesti.",
  "PLEASE_CHECK_THAT_THEY_ARE_COR": "Tarkista, että ne ovat oikein ja korjaa tarvittaessa.",
  "LIST_DOCTORAL_CANDIDATES_BY_RE": "Listaa väitöskirjatutkijat raportin tilan mukaan",
  "LIST_REVIEWERS_OF_NOT_COMPLETE": "Luettele keskeneräisten raporttien arvioijat",
  "DOWNLOAD_THESSA_ADMIN_REPORT": "Lataa Thessan pääkäyttäjäraportti",
  "REPORT_CONCLUSIONS_OVERVIEW": "Loppulauseiden yleiskatsaus",
  "FILTER": "Suodata",
  "SHOW_ALL_REPORTS": "Näytä kaikki raportit",
  "OVER_MONTH_OLD_REPORTS": "Yli 2 kuukautta vanhoja raportteja",
  "COPY_REVIEWER_EMAIL_ADDRESSES": "Kopioi arvioijien sähköpostiosoitteet",
  "EMAIL_ADDRESSES_COPIED": "Sähköpostiosoitteet kopioitu",
  "CREATED_AT": "Luotu klo",
  "REVIEWERS": "Arvioijat",
  "FILTER_BY_DOCTORAL_PROGRAMME": "Suodata tohtoriohjelman mukaan",
  "NO_REPORT_CONCLUSIONS_AVAILABL": "Tohtoriohjelmasta ei ole saatavilla loppulauseita",
  "PRELIMINARY_TITLE_OF_DISSERTAT": "Väitöskirjan alustava nimi",
  "STUDENT_NUMBER1": "OPISKELIJANUMERO",
  "NAME1": "NIMI",
  "ACCOUNT_CLOSED": "Tili suljettu:",
  "STUDIES": "OPINNOT",
  "FACULTY": "TIEDEKUNTA",
  "SUPERVISORS1": "OHJAAJAT",
  "THESIS_COMMITTEE2": "SEURANTARYHMÄ",
  "REPORT_CREATED": "Raportti luotu",
  "PROGRESS1": "edistyminen",
  "LINK_TO_THE_DOCTORAL_CANDIDATE": "Linkki väitöskirjatutkijan Thessa-sivulle",
  "STUDENT_ID_NUMBER": "Opiskelijanumero",
  "ACCOUNT_STATUS_OPEN_CLOSED": "Tilin tila (avoin/suljettu)",
  "IF_ACCOUNT_IS_CLOSED_REASON_F": "Jos tili suljetaan, sulkemisen syy",
  "IS_ACCOUNT_ACTIVE_ACTIVE_INAC": "Onko tili aktiivinen (aktiivinen/ei-aktiivinen)",
  "EMAIL2": "sähköposti",
  "FACULTY1": "tiedekunta",
  "FACULTY_OF_DEGREE": "Tiedekunta",
  "STARTING_DATE_OF_DOCTORAL_STUD": "Tohtoriopintojen alkamispäivä",
  "DOES_THE_DOCTORAL_CANDIDATE_HA": "Onko väittelijällä ohjaussuunnitelma?",
  "IS_THE_SUPERVISION_PLAN_IN_EFF": "Onko ohjaussuunnitelma voimassa",
  "SUPERVISORS2": "ohjaajat",
  "SUPERVISORS_AND_THEIR_EMAIL_AD": "Ohjaajat ja heidän sähköpostiosoitteensa",
  "IS_THE_ANNUAL_REPORT_SUBMITTED": "Onko vuosiraportti toimitettu",
  "THE_ANNUAL_REPORTING_IS_CLOSED": "Vuosiraportointi on päättynyt ja loppulause kirjoitettu",
  "NEXT_REPORT_S_DEADLINE": "Seuraavan raportin määräaika",
  "THESIS_COMMITTEE_HAS_BEEN_ASSI": "Seurantaryhmä on perustettu",
  "MEMBERS_OF_THE_THESIS_COMMITTE": "Seurantaryhmän jäsenet ja heidän sähköpostiosoitteensa",
  "OVERALL_PERCENTAGE_OF_COMPLETI": "Väitöskirjasta valminna prosentteina",
  "ADVANCEMENT_OF_THESIS_WORK_COM": "Väitöskirjan eteneminen raportointikauden aikana",
  "FULL_TIME_PART_TIME": "Täysiaikainen osa-aikainen",
  "SALARIES2": "palkat",
  "GRANTS2": "apurahat",
  "GRANTS_OBTAINED_AND_APPLIED_FO": "Apurahoja on saatu ja haettu",
  "EXPORT": "Vie",
  "CREATE_SUPERVISION_PLAN": "Luo ohjaussuunnitelma",
  "DELETE_SUPERVISION_PLAN": "Poista ohjaussuunnitelma",
  "ACCEPT_PLAN": "Hyväksy suunnitelma",
  "VALID_BETWEEN": "Voimassa välillä",
  "VALID_FROM": "Voimassa toistaiseksi alkaen",
  "VALID_INDEFINITELY": "Ohjaussuunnitelma on voimassa toistaiseksi",
  "DECISIONS": "Päätökset",
  "SIGNATURES": "Allekirjoitukset",
  "SIGNED": "Allekirjoitettu",
  "NOT_YET_SIGNED": "Ei vielä allekirjoitettu",
  "WHAT_HAVE_YOU_DECIDED_ABOUT_TH": "Täytä vähintään yksi valinnainen kenttä siitä, mitä olette yhdessä päättäneet työskentelytavoista väitöskirjatyön etenemiseksi",
  "ACTIVE": "Aktiivinen",
  "PENDING": "Odottaa",
  "EXPIRED": "Vanhentunut",
  "GENERAL_TERMS_ON": "Yleiset ohjaussuunnitelman ehdot:",
  "RIGHTS_OBLIGATIONS_AND_RESPON2": "Oikeudet, velvollisuudet ja vastuut tohtorikoulutuksessa Helsingin yliopistossa",
  "THAT_APPLY_TO_THE_SUPERVISION": "",
  "NO_COURSES_OR_WORKSHOPS_IN_PLA": "Kursseja tai työpajoja ei ole vielä suunnitteilla.",
  "NO_THESIS_INSTRUCTIONS_IN_PLAN": "Suunnitelmissa ei ole vielä opinnäytetyöohjeita.",
  "ADD_TEACHING": "Lisää opetus",
  "TYPE_OF_TEACHING": "Opetuksen tyyppi",
  "DATES1": "Päivämäärät",
  "COURSE_OR_WORKSHOP_NAME": "Kurssin tai työpajan nimi",
  "MY_ROLE": "Minun roolini",
  "WORK_HOURS": "Työajat",
  "STUDENT_S_NAME": "Oppilaan nimi",
  "THESIS_LEVEL": "Opinnäytetyön taso",
  "THESIS_TOPIC_OR_TITLE": "Opinnäytetyön aihe tai otsikko",
  "SIGNED_UP_ON": "Tili luotu",
  "USERNAME": "Käyttäjätunnus:",
  "ACTIVATE_ACCOUNT_NOW": "Aktivoi tili nyt",
  "USER_ACCOUNT_IS_NOT_YET_ACTIVA": "Käyttäjätiliä ei ole vielä aktivoitu",
  "CHECK_THE_ACCOUNT_DETAILS_IF": "Tarkista tilin tiedot – jos käyttäjä todella on organisaatiossasi, aktivoi tili. Muuten",
  "CONTACT_THESSA_S_ADMINS": "ota yhteyttä Thessan ylläpitäjiin",
  "CLOSE_ACCOUNT": "Sulje tili",
  "REASON_FOR_CLOSING_THE_ACCOUNT": "Syy tilin sulkemiseen",
  "FACULTY2": "Tiedekunta:",
  "MAJOR": "Pääaine:",
  "DOCTORAL_PROGRAMME1": "Tohtoriohjelma:",
  "BEGINNING_OF_DOCTORAL_STUDIES1": "Tohtoriopintojen alkaminen:",
  "BACK_TO": "Takaisin",
  "ORGANIZATION": "Organisaatio:",
  "STUDENT_NUMBER2": "Opiskelijanumero:",
  "VERIFIED_FROM_LDAP": "Vahvistettu LDAP:sta)",
  "NOT_VERIFIED_REPORTED_BY_DOCT": "Ei vahvistettu, väittelijän ilmoittama)",
  "THESIS_COMMITTEE_MEMBER1": "Opinnäytetyötoimikunnan jäsen",
  "IN_THESIS_COMMITTEE_FOR": "Seurantaryhmässä {{count}} väitöskirjatutkijalle",
  "IN_THESIS_COMMITTEE_FOR_other": "Seurantaryhmässä {{count}} väitöskirjatutkijalle",
  "THE_INVITATION_HAS_EXPIRED": "<0>Kutsu on vanhentunut.</0> Pyydä uusi kutsu väitöskirjatutkijalta.",
  "THERE_WAS_AN_ERROR_IN_LOADING_THE_INVITATION": "<0>Kutsun lataamisessa tapahtui virhe.</0> <1>Yritä uudelleen</1>. <2>Ilmoita meille</2>, jos ongelma ei poistu.",
  "INVITATION_DECLINED": "<0>Kutsu hylätty.</0> Kiitos vastauksestasi!",
  "YOUR_ACCOUNT_IS_CLOSED": "Tilisi on suljettu",
  "SUPERVISION_PLAN_NOT_FOUND": "Ohjaussuunnitelmaa ei löydy",
  "IN_THESIS": "Väitöskirjassa",
  "PUBLISHED": "Julkaistu",
  "NOT_STARTED": "Ei alkanut",
  "THE_SUPERVISION_PLAN_AFFECTS_THE_DOCTORAL": "Ohjaussuunnitelma koskee väitöskirjatutkijaa ja kaikkia hänen ohjaajiaan.",
  "IT_IS_RECOMMENDED_THAT_SUPERVISION_PLANS": "Ohjaussuunnitelmat on suositeltava tehdä, silloin kun kaikki, joihin se vaikuttaa, ovat paikalla. Jos joku ei pääse paikalle, muista tehdä suunnitelma, jonka hänkin voi allekirjoittaa.",
  "CREATE_AND_SIGN_PLAN_AS": "Tallenna lopullisesti ja allekirjoita",
  "SAVE_DRAFT": "Tallenna luonnos",
  "SAVE_DRAFT_FEEDBACK": "Luonnos tallennettu",
  "LOADING_DOCTORAL_PROGRAMMES": "Ladataan tohtoriohjelmia...",
  "FORM_IS_INCOMPLETE": "Lomake ei ole valmis",
  "DESCRIPTION": "Kuvaus",
  "ROLE_IN_CONFERENCE": "Rooli konferenssissa",
  "FAILED_TO_SAVE": "<0>Tallennus epäonnistui.</0> Yritä uudelleen tai <1>ota meihin yhteyttä</1>, jos ongelma jatkuu.",
  "DOCTORAL_SCHOOL": "Tutkijakoulu",
  "ROLE": "Rooli",
  "PREVIOUS_PAGE": "Edellinen <0>sivu</0>",
  "NEXT_PAGE": "Seuraava <0>sivu</0>",
  "ROWS": "riviä",
  "PAGE_OF": "<0>Sivu {{currentPage}}</0> <1>/{{totalPages}}</1>",
  "PAGE": "Sivu",
  "MAX_SIZE_PER_ATTACHMENT": "Liitteen suurin mahdollinen koko:",
  "UPLOAD_ATTACHMENTS": "Lataa liitteet",
  "UPLOADING": "Ladataan...",
  "WHAT_PLANS_DO_YOU_HAVE": "Mitä suunnitelmia sinulla on? Tukevatko suoritetut opinnot tätä tavoitetta?",
  "WRITE_HERE_ABOUT_YOUR_RESEARCH_PROGRESS": "Kirjoita tähän tutkimukseksesi edistymisestä raportointijaksolla. Pysy pääkohdissa ja kirjoita lyhyesti, jotta seurantaryhmä voi arivoida edistymistäsi helposti. Huomaa, että voit ladata päivitetyn tutkimussuunnitelmasi tai muun asiaankuuluvan materiaalin tähän raportin liitteenä.",
  "FOR_EXAMPLE_WRITE_ABOUT_HOSTING_A_VISITOR": "Kirjoita esimerkiksi vierailijan isännöimisestä tai kansallisten opintomatkojen järjestämisestä.",
  "FOR_EXAMPLE_HAVE_YOU_WRITTEN_A_BLOG": "Oletko esimerkiksi kirjoittanut blogia tai populaaritieteellistä artikkelia, järjestänyt oman alasi ulkopuoliselle yleisölle suunnatun tapahtuman tai yrittänyt vaikuttaa jollain muulla tavalla?",
  "NO_REPORTS": "Ei raportteja",
  "ANY": "Minkä tahansa",
  "FILTER_BY": "Suodata {{target}}",
  "SORT_BY": "Lajitteluperuste",
  "THERE_WAS_AN_ERROR_IN_LOADING_DOCTORAL_CANDIDATES": "<0>Väitöskirjatutkijoiden lataamisessa tapahtui virhe.</0> <1>Yritä uudelleen</1>. <2>Ilmoita meille</2>, jos ongelma ei poistu.",
  "THERE_ARE_NO_DOCTORAL_CANDIDATES_IN_THE_SELECTED": "Yhtään väitöskirjatutkijaa ei löytynyt.",
  "DOCTORAL_PROGRAMMES": "Tohtoriohjelmat",
  "ERROR_LOADING_DOCTORAL_PROGRAMMES": "<0>Virhe ladattaessa tohtoriohjelmia.</0> <1>Yritä uudelleen</1>. Jos se ei auta, <2>ilmoita siitä meille</2>.",
  "SELECT_NONE": "Älä valitse mitään",
  "SELECT_ALL": "Valitse kaikki",
  "WHEN_WILL_EACH_STAGE_OF_THE_PUBLICATION_BEGIN": "Milloin jokainen julkaisun vaihe alkaa? Jos vaihe tulevaisuudessa, arvioi, milloin se alkaisi. Jokaisen tilan tulee alkaa edellisen jälkeen.",
  "THE_STATUSES_ARE_IN_INCORRECT_ORDER": "Tilat ovat väärässä järjestyksessä: jokaisen tilan tulee alkaa edellisen jälkeen.",
  "TO_BE_INCLUDED_IN_THE_PHD_THESIS": "Sisältyy väitöskirjaan",
  "SAVE_PUBLICATION": "Tallenna julkaisu",
  "CREATE_ANOTHER": "Luo toinen",
  "CREATING_ANOTHER_PUBLICATION": "Luodaan toista julkaisua",
  "I_HAVE_COMPLETED_THIS_COURSE": "Olen suorittanut tämän kurssin",
  "SAVE_COURSE": "Tallenna kurssi",
  "CREATING_ANOTHER_COURSE": "Luodaan toinen kurssi",
  "CREATING_ANOTHER_GRANT": "Luodaan toinen apuraha",
  "SOURCE_OF_GRANT": "Apurahan lähde",
  "FROM_WHICH_ORGANIZATION_IS_THE_GRANT_FROM": "Miltä organisaatiolta apuraha on peräisin?",
  "HOW_MUCH_IS_THIS_GRANT": "Kuinka paljon tämä apuraha on?",
  "SAVE_GRANT": "Tallenna apuraha",
  "ENDS_AT": "Päättyy",
  "BEGINS_AT": "Alkaa",
  "WHAT_IS_THE_INTENDED_USAGE_OF_THIS_GRANT": "Mikä on tämän apurahan käyttötarkoitus?",
  "DURATION_OF_GRANT": "Apurahan kesto",
  "WHEN_DOES_THIS_GRANT_BEGIN_AND_END": "Milloin tämä apuraha alkaa ja päättyy? Jos tarkkaa päivämäärää ei ole, tee arvio.",
  "CREATING_ANOTHER_TEACHING": "Toisen opetuksen luominen",
  "BEGINNING_DATE": "Aloituspäivämäärä",
  "END_DATE": "Päättymispäivä",
  "NAME_OF_THE_COURSE_OR_WORKSHOP": "Kurssin tai työpajan nimi",
  "MY_ROLE_IN_TEACHING": "Oma roolini opetuksessa",
  "FOR_EXAMPLE_LECTURER_OR_ASSISTANT": "Esimerkiksi <0>luennoitsija</0> tai <1>assistentti</1>.",
  "TOPIC_OR_TITLE_OF_THE_THESIS": "Opinnäytetyön aihe tai otsikko",
  "LEVEL_OF_THESIS": "Opinnäytetyön taso",
  "COURSE_OR_WORKSHOP": "Kurssi tai työpaja",
  "HOW_MANY_HOURS_OF_WORK_DID_YOU_SPEND_IN_TOTAL": "Kuinka monta tuntia työhön käytit yhteensä?",
  "NAME_OF_THE_STUDENT_WHO_YOUR_WERE_INSTRUCTING": "Sen opiskelijan nimi, jota ohjasit",
  "MY_ROLE_IN_INSTRUCTING_THE_STUDENT": "Oma roolini opiskelijan ohjaamisessa",
  "FOR_EXAMPLE_MAIN_SUPERVISOR_OR_CO_SUPERVISOR": "Esimerkiksi <0>pääohjaaja</0> tai <1>apuohjaaja</1>.",
  "FOR_EXAMPLE_MASTERS_THESIS_OR_BACHELORS_THESIS": "Esimerkiksi <0>pro gradu</0> tai <1>kandidaattityö</1>.",
  "CREATING_ANOTHER_SALARY": "Toisen palkan luominen",
  "SOURCE_OF_SALARY": "Palkan lähde",
  "FROM_WHICH_ORGANIZATION_AND_OR_PROJECT_DO_YOU_GET": "Mistä organisaatiosta ja/tai projektista saat palkkasi?",
  "DURATION_OF_SALARY": "Palkan kesto",
  "WHEN_DOES_THIS_SALARY_BEGIN_AND_END_IF_THERE_IS_NO": "Milloin tämä palkka alkaa ja päättyy? Jos tarkkaa päivämäärää ei ole, tee arvio.",
  "SAVE_SALARY": "Tallenna palkka",
  "DUE_DATE": "Eräpäivä",
  "SEND_REMINDER_EMAILS_AT": "Lähetä muistutussähköpostit osoitteeseen",
  "SAVE_REMINDER": "Tallenna muistutus",
  "LOG_IN_OR_SIGN_UP_TO_ACCEPT_INVITATION": "Kirjaudu sisään tai rekisteröidy hyväksyäksesi kutsu.",
  "LOG_IN_WITH_UNIVERSITY_OF_HELSINKI_ACCOUNT": "Kirjaudu sisään Helsingin yliopiston tilillä",
  "I_DON_T_HAVE_A_UNIVERSITY_OF_HELSINKI_ACCOUNT": "Minulla ei ole Helsingin yliopiston tiliä",
  "LOG_IN_WITH_AN_EXISTING_THESSA_ACCOUNT": "Kirjaudu sisään olemassa olevalla Thessa-tilillä.",
  "LOG_IN_OR_SIGN_UP_FOR_A_THESSA_ACCOUNT_TO": "Kirjaudu sisään tai rekisteröidy Thessa-tilillesi hyväksyäksesi kutsu.",
  "SIGN_UP_FOR_A_NEW_THESSA_ACCOUNT": "Luo uusi Thessa-tili",
  "ERROR_LOGIN_OR_SIGN_UP": "VIRHE - kirjaudu sisään tai rekisteröidy!",
  "INVITATION_HAS_EXPIRED_AT": "Kutsu on vanhentunut {{date}}. <0>Siirry Thessan etusivulle</0>",
  "YOU_HAVE_BEEN_INVITED_TO_BECOME": "Sinut on kutsuttu <0>{{roles}} henkilölle {{inviter}}</0> Thessassa.",
  "ACCEPT_INVITATION": "Hyväksy kutsu",
  "THERE_WAS_AN_ERROR_IN_DECLINING_THE_INVITATION": "<0>Kutsun hylkäämisessä tapahtui virhe.</0> Yritä uudelleen. <1>Ilmoita meille</1>, jos ongelma ei poistu.",
  "SIGN_UP_FOR_A_NEW_THESSA_ACCOUNT_TO": "Luo uusi Thessa-tili hyväksyäksesi kutsun.",
  "SIGN_UP": "Kirjaudu",
  "ERROR_IN_SIGN_UP": "Virhe rekisteröitymisessä.",
  "LOG_IN_FAILED_TRY_AGAIN": "Kirjautuminen epäonnistui. Yritä uudelleen.",
  "ROLES": "Roolit",
  "DOES_NOT_HAVE_ANY_ROLES_ASSIGNED": "Käyttäjälle <0>{{name}} ei ole määritettyjä rooleja.</0> Jokaisella on oltava vähintään yksi rooli.",
  "THERE_WAS_AN_ERROR_IN_UPDATING_ROLES": "<0>Roolien päivityksessä tapahtui virhe.</0> Yritä uudelleen. <1>Ilmoita meille</1>, jos ongelma ei poistu.",
  "ONLY_ONE_PERSON_CAN_BE_THE_COORDINATING": "Vain yksi henkilö voi olla vastuuhenkilö.",
  "ONLY_ONE_PERSON_CAN_BE_THE_MAIN_SUPERVISOR": "Pääohjaajana voi toimia vain yksi henkilö.",
  "ONE_PERSON_SHOULD_BE_SELECTED_AS_THE_COORDINATING": "Yksi henkilö tulee valita vastuuhenkilöksi.",
  "ONE_PERSON_SHOULD_BE_SELECTED_AS_THE_MAIN": "Pääohjaajaksi tulee valita yksi henkilö.",
  "MYSELF": "Itse",
  "FILTERS": "Suodattimet",
  "CHOOSE_A_DOCTORAL_PROGRAMME": "Valitse tohtoriohjelma",
  "LOADING_INACTIVE_USER": "Ladataan passiivista käyttäjää...",
  "NO_STUDENT_NUMBER": "Ei opiskelijanumeroa",
  "SIGNED_UP": "Rekisteröidy",
  "NO_SUPERVISORS": "Ei ohjaajia",
  "SUPERVISOR_INVITATIONS": "Ohjaajakutsut",
  "ACTIVATE": "Aktivoida",
  "SEND_EMAIL": "Lähetä sähköpostia",
  "NO_EMAIL_ADDRESS": "Ei sähköpostiosoitetta",
  "THERE_WAS_AN_ERROR_IN_ACTIVATING_THE_USER_ACCOUNT": "<0>Käyttäjätilin aktivoinnissa tapahtui virhe.</0> <1>Yritä uudelleen</1>. <2>Ilmoita meille</2>, jos ongelma ei poistu.",
  "SELECT_DOCTORAL_PROGRAMME": "Valitse tohtoriohjelma",
  "NO_DOCTORAL_PROGRAMME_SELECTED": "Tohtoriohjelmaa ei ole valittu",
  "SELECT_DOCTORAL_PROGRAMMES": "Valitse tohtoriohjelmat",
  "ALL_DOCTORAL_PROGRAMMES": "Kaikki tohtoriohjelmat",
  "NOT_VERIFIED": "Ei vahvistettu",
  "VERIFY_NOW": "Vahvista nyt",
  "DOCTORAL_CANDIDATE_DOES_NOT_HAVE_ANY_SUPERVISORS": "Väitöskirjatutkijalla ei ole ohjaajia.",
  "COUNT_THESIS_COMMITTEE_MEMBER": "{{count}} seurantaryhmän jäsen",
  "COUNT_THESIS_COMMITTEE_MEMBER_other": "{{count}} seurantaryhmän jäsentä",
  "COUNT_SUPERVISOR_RELATIONSHIP": "{{count}} ohjaussuhdetta",
  "COUNT_SUPERVISOR_RELATIONSHIP_other": "{{count}} ohjausssuhdetta",
  "COUNT_PUBLICATION_IN_PROGRESS": "{{count}} julkaisu käynnissä",
  "COUNT_PUBLICATION_IN_PROGRESS_other": "{{count}} julkaisua käynnissä",
  "COUNT_PUBLICATION_PUBLISHED": "{{count}} julkaisu julkaistu",
  "COUNT_PUBLICATION_PUBLISHED_other": "{{count}} julkaisua julkaistu",
  "MAIN_SUPERVISOR": "Pääohjaaja",
  "MAIN_SUPERVISOR_other": "Pääohjaajat",
  "COORDINATING_ACADEMIC": "Vastuuhenkilö",
  "COORDINATING_ACADEMIC_other": "Vastuuhenkilöt",
  "COUNT_DOCTORAL_CANDIDATE": "{{count}} väitöskirjatutkija löydetty",
  "COUNT_DOCTORAL_CANDIDATE_other": "{{count}} väitöskirjatutkijaa löydetty",
  "ONLY_SHOW_DOCTORAL_CANDIDATES_WITHOUT_ACCOUNT": "Näytä vain väitöskirjatutkijat ilman tiliä",
  "ONLY_SHOW_DOCTORAL_CANDIDATES_WITHOUT_SUPERVISOR": "Näytä vain väitöskirjatutkijat ilman ohjaajaa",
  "COUNT_DOCTORAL_CANDIDATE_S_REPORT": "{{count}} väitöskirjatutkijan raportti on myöhässä ({{percentage}}%)",
  "COUNT_DOCTORAL_CANDIDATE_S_REPORT_other": "{{count}} väitöskirjatutkijan raportti on myöhässä ({{percentage}}%)",
  "COUNT_DOCTORAL_CANDIDATE_S_LATEST": "{{count}} väitöskirjatutkijan viimeisin raportti ei ole vielä valmis ({{percentage}}%)",
  "COUNT_DOCTORAL_CANDIDATE_S_LATEST_other": "{{count}} väitöskirjatutkijan viimeisin raportti ei ole vielä valmis ({{percentage}}%)",
  "COUNT_DOCTORAL_CANDIDATE_IS_ON_TIME": "{{count}} väitöskirjatutkija on ajoissa ({{percentage}}%)",
  "COUNT_DOCTORAL_CANDIDATE_IS_ON_TIME_other": "{{count}} väitöskirjatutkijaa on ajoissa ({{percentage}}%)",
  "COUNT_ITEM_IN_QUEUE": "{{count}} kohde jonossa",
  "COUNT_ITEM_IN_QUEUE_other": "{{count}} kohdetta jonossa",
  "OLDEST_ITEM_FIRST": "vanhin ensin",
  "ISPROCEEDING": "edistyminen",
  "IS_THE_ANNUAL_REPORT_PROCEEDING_AS_PLANNED": "Edistyykö vuosiraportti suunnitellusti",
  "CONCLUSIONCOMMENT": "johtopäätösMiten",
  "COMMENT_ABOUT_THE_PROGRESS_OF_THE": "Pääohjaajan kommentti väitöskirjan etenemistä",
  "PROGRESS_FEEDBACK_BY": "Edistymispalautteen lähettäjä",
  "IS_THE_DISSERTATION_WORK_PROCEEDING_AS_PLANNED": "Sujuuko väitöskirjatyö suunnitellusti?",
  "PROGRESS_FEEDBACK_IS_MISSING": "Palaute edistymisestä puuttuu",
  "PROGRESS_FEEDBACK_SUBMITTED": "Palaute edistymisestä lähetetty",
  "GIVE_PROGRESS_FEEDBACK": "Anna palautetta edistymisestä",
  "THERE_WAS_A_PROBLEM_SUBMITTING_PROGRESS_FEEDBACK": "Edistymispalautteen lähettämisessä oli ongelma",
  "PROGRESS_FEEDBACK_SUBMITTED_REDIRECTING_YOU_BACK": "Palaute edistymisestä lähetetty. Ohjataan takaisin <0>esittelysivulle</0>",
  "PROGRESS_FEEDBACK": "Palaute edistymisestä",
  "COMMENT1": "Kommentti",
  "OPTIONAL_COMMENT_ABOUT_THE_PROGRESS_OF_THE": "Vapaaehtoinen kommentti väitöskirjan etenemisestä",
  "MOBILE": "mobiili",
  "DESKTOP": "Työpöytä",
  "VIEW_ACCOUNT": "Näytä tili",
  "EDIT_ROLES": "Muokkaa rooleja",
  "ENDING_ON_DATE": "Päättyy {{date}}",
  "INVITE_SUPERVISOR": "Kutsu ohjaaja",
  "ADD_SUPERVISOR": "Lisää ohjaaja",
  "FAILED_TO_SEND_INVITE_PLEASE_TRY_AGAIN": "<0>Kutsun lähettäminen epäonnistui.</0> Yritä uudelleen tai <1>ota yhteyttä Thessa-tukeen</1> saadaksesi apua.",
  "SAVE_ROLES": "Tallenna roolit",
  "ERROR_IN_SAVING_ROLES_TRY_AGAIN_OR": "Virhe roolien tallentamisessa. Yritä uudelleen tai <0>ota yhteyttä tukeen</0>.",
  "INVITATION": "Kutsu",
  "REMOVE_INVITATION": "Poista kutsu",
  "YOU_HAVE_NO_ACTIVE_DOCTORAL_CANDIDATES_IN_THESSA": "Sinulla ei ole tällä hetkellä aktiivisia väitöskirjatutkijoita Thessassa.",
  "NOT_IN_THESSA": "Ei Thessassa",
  "UPLOAD_AN_EXCEL_FILE_WITH_SUPERVISOR_RELATIONSHIPS": "Lataa Excel-tiedosto, jossa on ohjaussuhteet",
  "CHANGE_FILE": "Vaihda tiedosto",
  "GO_BACK": "Mene takaisin",
  "SAVE_SUPERVISOR_RELATIONSHIPS": "Tallenna ohjaussuhteet",
  "DOCTORAL_CANDIDATE_ROW": "Väitöskirjatutkijarivi",
  "IGNORE": "Jätä huomiotta",
  "COORDINATING_ACADEMIC_EMAIL": "Vastuuhenkilön sähköpostiosoite",
  "MAIN_SUPERVISOR_EMAIL": "Pääohjaajan sähköposti",
  "SUPERVISOR_EMAIL": "Ohjaajan sähköposti",
  "SUPERVISOR_RELATIONSHIPS": "Ohjaussuhteet",
  "UPLOAD_A_FILE_WITH_SUPERVISOR_RELATIONSHIPS": "Lataa tiedosto, jossa on ohjaajatiedot",
  "USE_THE_BUTTON_ABOVE_TO_UPLOAD_AN_EXCEL_FILE": "Lataa Excel-tiedosto yllä olevalla painikkeella. Tiedostossa tulee olla <0>sarakkeet opiskelijanumerolle ja ohjaajan/ohjaajien/vastuuhenkilön sähköpostiosoitteelle</0>. Rivien tulee edustaa yksittäisiä väitöskirjatutkijoita.",
  "PICK_COLUMNS_AND_ROWS_TO_IMPORT": "Valitse tuotavat sarakkeet ja rivit",
  "FOR_EACH_COLUMN_WITH_RELEVANT_DATA": "Valitse oikea sarake avattavasta valikosta jokaiselle sarakkeelle, jossa on oleellisia tietoja (opiskelijanumero ja ohjaajien sähköpostiosoitteet). Jätä loput sarakkeet huomiotta jätetyiksi.",
  "THEN_SELECT_EACH_ROW_WITH_A_SUPERVISOR": "Valitse sitten jokainen rivi, jonka on ohjaajatiedot haluat tallentaa, ja paina Esikatselu-painiketta.",
  "CHECK_THAT_THE_FOLLOWING_PREVIEW_IS_CORRECT": "Tarkista, että seuraava esikatselu näyttää oikealta. Jos esikatselu on oikein, aloita tuonti painamalla Tallenna valvojan suhteet -painiketta. Jos esikatselu on virheellinen, voit ladata uuden Excel-tiedoston tai paina paluupainiketta ja muuta sarake- ja rivivalintoja.",
  "IMPORT_SUCCESSFUL": "Tuonti onnistui",
  "IMPORT_FAILED_PLEASE_CONTACT_US_FOR_HELP": "<0>{{message}}</0> <1>Ota meihin yhteyttä</1> saadaksesi apua.",
  "FILE_UPLOAD_WAS_NOT_SUCCESSFUL_PLEASE_TRY_AGAIN": "<0>Tiedoston lataus ei onnistunut.</0> Yritä uudelleen.",
  "NOTES": "Muistiinpanot",
  "DONE1": "Valmis",
  "COURSE_TITLE": "Kurssin nimi",
  "SALARY_SOURCE": "Palkkalähde",
  "GRANT_SOURCE": "Apurahan lähde",
  "STUDENT": "Opiskelija",
  "STATUS_OF_THE_PHD_PROJECT": "Väitöskirjaprojektin tila",
  "TIME_SPENT_ON_PHD": "Väitöskirjaprojektiin käytetty aika",
  "SOCIAL_IMPACT_AND_PUBLIC_OUTREACH": "Yhteiskunnallinen vaikutus ja tieteen popularisointi",
  "ATTACHMENTS": "Liitteet",
  "THREE_POSITIVE_THINGS": "Kolme positiivista asiaa",
  "THREE_NEGATIVE_THINGS": "Kolme negatiivista asiaa",
  "CAREER_PLANNING": "Urasuunnittelu",
  "CONCLUSIONS": "Loppulause",
  "OTHER_WORK": "Muu työ",
  "WAIT_FOR_ONE_MINUTE_BEFORE_RESENDING": "Odota minuutti ennen kuin lähetät uudelleen",
  "ACCOUNT_IS_CLOSED": "Tili on suljettu",
  "ACCOUNT_IS_ACTIVE": "Tili on aktiivinen",
  "ACCOUNT_IS_INACTIVE": "Tili ei ole aktiivinen",
  "ESTIMATED_STATUS_OF_THE_PHD_PROJECT": "Väitöskirjaprojektin arvioitu tila",
  "ESTIMATED_TIME_OF_PHD_DEFENCE": "Arvio väittelyajasta",
  "CAREER_PLANS_AFTER_YOUR_PHD": "Urasuunnitelmat tohtorintutkinnon jälkeen",
  "BACK_TO_OVERVIEW": "Takaisin yleiskatsaukseen",
  "BACK_TO_REPORT": "Takaisin raporttiin",
  "CHANGE_SUPERVISOR": "Vaihda ohjaaja",
  "ADD_SUPERVISOR_FOR_NAME": "Lisää ohjaaja käyttäjälle {{name}}",
  "FAILED_TO_ADD_SUPERVISOR_PLEASE_TRY_AGAIN_OR": "<0>Ohjaajan lisääminen epäonnistui.</0> Yritä uudelleen tai <1>ota yhteyttä Thessa-tukeen</1> saadaksesi apua.",
  "FAILED_TO_INVITE_SUPERVISOR_PLEASE_TRY_AGAIN_OR": "<0>Ohjaajan kutsuminen epäonnistui.</0> Yritä uudelleen tai <1>ota yhteyttä Thessa-tukeen</1> saadaksesi apua.",
  "INVITE_SUPERVISOR_FOR_NAME": "Kutsu ohjaaja käyttäjälle {{name}}",
  "CHOOSE_LANGUAGE": "Valitse kieli",
  "PREPARATION_AND_ANALYSIS": "Valmistelu ja analysointi",
  "WRITING": "Kirjoitus",
  "NAME_IS_NOT_YET_ON_THESSA_WHEN_THEY_SIGN_UP": "<0>{{name}} ei ole vielä Thessassa.</0> Kun he kirjautuvat Thessaan, näet heidän tiedot.",
  "INVITE_THESIS_COMMITTEE": "Kutsu seurantaryhmä",
  "EDIT_THESIS_COMMITTEE": "Muokkaa seurantaryhmää",
  "YOUR_USER_ACCOUNT_NEEDS_TO_BE_ACTIVATED": "Käyttäjätilisi on aktivoitava ennen kuin voit luoda ensimmäisen raportin. Ennen kuin tilisi voidaan aktivoida, sinun on <0>kutsuttava kaikki opinnäytetyötoimikunnan jäsenet</0> Thessassa.",
  "YOU_WILL_RECEIVE_AN_EMAIL_WHEN_YOUR_ACCOUNT": "Saat sähköpostin, kun tilisi on aktivoitu.",
  "ESTIMATE_MADE_ON": "Arvio tehty",
  "PHD_PROJECT_STATUS": "Edistyminen",
  "TIME_SPENT_PERIOD": "Aikaväli",
  "EDIT_PRELIMINARY_TITLE_OF_DISSERTATION": "Muokkaa väitöskirjan alustavaa nimeä",
  "CREATE_PRELIMINARY_TITLE_OF_DISSERTATION": "Lisää väitöskirjan alustava nimi",
  "SUBJECT_OR_STUDENT": "Aihe tai opiskelija",
  "APPLICATION_SENT": "Hakemus lähetetty",
  "PLANNING_TO_APPLY": "Suunnitellaan hakemista",
  "CREDITS_one": "{{count}} opintopiste",
  "CREDITS_other": "{{count}} opintopistettä",
  "CR": "{{count}} op",
  "WHAT_WAS_DECIDED": "Mitä päätettiin?",
  "ROLE_NAME": {
    "key": "value",
    "doctoral-candidate": "Väitöskirjatutkija",
    "coordinating-academic": "Vastuuhenkilö",
    "main-supervisor": "Pääohjaaja",
    "supervisor": "Ohjaaja",
    "thesis-committee-member": "Seurantaryhmän jäsen",
    "salary-system-foreman": "Esimies",
    "coordinator": "Koordinaattori"
  },
  "CHECK_REPORT_STATUS": "Tarkista raportin tila.",
  "CHECK_SUPERVISION_PLAN_STATUS": "Tarkista ohjaussuunnitelman tila.",
  "SEARCH_BY": "Etsi tunnisteella {{keyword}}",
  "COMPLETE_THE_REPORT_FORM": "Täytä raporttilomake ja lähetä raportti.",
  "CONCLUSION_CAN_BE_WRITTER_AFTER": "Loppulause voidaan kirjoittaa raportin jättämisen jälkeen.",
  "YES": "Kyllä",
  "NO": "Ei",
  "PLEASE_INVITE_TCMS_TO_THESSA": "Kutsu kaikki seurantaryhmäsi jäsenet Thessaan.",
  "COORDINATOR_TOOLS": {
    "key": "value",
    "MAIN_HEADING": "Työkalut",
    "HEADINGS": {
      "key": "value",
      "MANAGE_USERS": "Käyttäjät",
      "STATISTICS": "Tilastot",
      "OTHER": "Muut"
    },
    "TOOLS": {
      "key": "value",
      "LATEST_REPORTS": "Viimeisimmät raportit",
      "INCOMPLETE_REPORTS": "Keskeneräiset raportit",
      "ADMIN_REPORT": "Thessan admin-raportti",
      "IMPORT_SUPERVISOR_RELATIONSHIPS": "Tuo ohjaussuhteita"
    },
    "DESCRIPTIONS": {
      "key": "value",
      "DOCTORAL_CANDIDATES": "Hallitse väitöskirjatutkijoita",
      "USERS": "Hallitse kaikkia käyttäjiä",
      "REPORT_CONCLUSIONS_OVERVIEW": "Kaikkien väitöskirjatutkijoiden loppulauseet",
      "LATEST_REPORTS": "Väitöskirjatutkijoiden viimeisimpien raporttien tila",
      "INCOMPLETE_REPORTS": "Väitöskirjatutkijat ja ohjaajat, joilla on keskeneräisiä raportteja",
      "ADMIN_REPORT": "Lataa väitöskirjatutkijoiden tiedot CSV-tiedostona",
      "IMPORT_SUPERVISOR_RELATIONSHIPS": "Excelistä Thessaan"
    }
  },
  "SHOW_ALL_DOCTORAL_CANDIDATES": "Näytä kaikki väitöskirjatutkijat",
  "HIDE_INACTIVE_DOCTORAL_RESEARCHERS": "Piilota ei-aktiiviset väitöskirjatutkijat",
  "DOCTORAL_RESEARCHER_OVERVIEW": "Väitöskirjatutkijan yleiskatsaus",
  "LAST_COMPLETED_REPORT": "Viimeksi valmistunut raportti",
  "FILTER_DOCTORAL_RESEARCHERS": "Suodata väitöskirjatutkijoita",
  "SUPERVISOR_RELATIONSHIP_OVERVIEW": {
    "key": "value",
    "LINK": "https://studies.helsinki.fi/ohjeet/artikkeli/tohtorikoulutuksen-palvelut",
    "IF_MISSING": "Jos vastuuhenkilö tai ohjaajia puuttuu listasta,",
    "IF_MISSING_THEN_CONTACT": "ota yhteyttä tiedekuntasi jatko-opintopalveluihin",
    "IF_CHANGED": "Jos henkilöt ovat vaihtuneet,",
    "IF_CHANGED_THEN_CONTACT": "ota yhteyttä tohtoriohjelmaasi"
  },
  "THESIS_COMMITTEE_MEMBERS": {
    "key": "value",
    "SUPERVISORS_ARE_HANDLED_BY_OTHERS": "Ohjaajatietoja ylläpitää tiedekuntasi jatko-opintopalvelu ja tohtoriohjelmasi suunnittelija. Yhteystiedot löytyvät ",
    "LINK": "https://studies.helsinki.fi/ohjeet/artikkeli/tohtorikoulutuksen-palvelut",
    "LINK_TITLE": "opiskelijan ohjeista",
    "INVITATION_HEADING": "Kutsu seurantaryhmän jäsenet",
    "INVITATION_HEADING_FOR_SUPERVISORS": "Kutsu ohjaajat",
    "INVITATION_LABEL": "Seurantaryhmän jäsenen sähköpostiosoite",
    "INVITATION_LABEL_FOR_SUPERVISORS": "Ohjaajan sähköpostiosoite"
  },
  "CONTACT": {
    "HEADING": "Yhteystiedot",
    "PLANNING_OFFICER": "Tohtoriohjelman suunnittelija",
    "DOCTORAL_PROGRAMME_CONTACT_LINK_PREFIX": "Tohtoriohjelmasi yhteystiedot:",
    "DOCTORAL_PROGRAMME_CONTACT_LINK_URL": "https://studies.helsinki.fi/ohjeet/artikkeli/tohtorikoulutuksen-palvelut",
    "PLANNING_OFFICER_HELPS_WITH": "Suunnittelijat auttavat seuraavan kaltaisissa asioissa:",
    "PLANNING_OFFICER_HELPS_WITH_ITEMS": [
      "Vuosiraporttiin liittyvät asiat",
      "Ohjaajat ja seurantaryhmät",
      "Ohjaussopimukset",
      "Pitääkö minun käyttää Thessaa?"
    ],
    "TECHNICAL_PROBLEMS": "Tekniset ongelmat",
    "TECHNICAL_EMAIL_PREFIX": "Teknisissä ongelmissa ja vikatilanteissa voit lähettää sähköpostia",
    "TECHNICAL_EMAIL_LINK_TEXT": "kehittäjien sähköpostilistalle"
  },
  "DATE_RANGE_PICKER": {
    "INVALID_START_DATE": "Alkamispäivä ei ole oikea päivämäärä.",
    "INVALID_END_DATE": "Loppumispäivä ei ole oikea päivämäärä.",
    "INVALID_END_IS_BEFORE_START": "Loppumispäivän tulee olla alkamispäivän jälkeen."
  },
  "COORDINATOR_DOCTORAL_CANDIDATES": {
    "PLACEHOLDER": "Tohtoriohjelma tai opiskelijanumero",
    "LABEL": "Hae tohtoriohjelmalla tai opiskelijanumerolla",
    "ACTION": "Hae"
  },
  "END_OF_SENTENCE_PERIOD": ".",
  "RESOURCES": {
    "INTRO_VIDEO": "PajRucu9XfM"
  },
  "MENU": "Valikko",
  "FRONTPAGE": "Etusivu",
  "OPEN_PROFILE_MENU": "Avaa profiilivalikko",
  "CLOSE_PROFILE_MENU": "Sulje profiilivalikko",
  "OPEN_MAIN_MENU": "Avaa päävalikko",
  "CLOSE_MAIN_MENU": "Sulje päävalikko",
  "CHANGE_LANGUAGE": "Vaihda kieli",
  "PROFILE_MENU": "Profiilivalikko",
  "MAIN_MENU": "Päävalikko",
  "FOOTER_MENU": "Alatunniste-valikko",
  "DATE_NOT_SPECIFIED": "Päivämäärää ei ole määritetty",
  "EMAIL_OR_USER_INPUT": {
    "HINT": "Kirjoita oikea sähköpostiosoite tai valitse jokin ehdotuksista"
  },
  "FORM": {
    "REQUIRED_FIELD": "Pakollinen",
    "OPTIONAL_FIELD": "Valinnainen",
    "VALID_EMAIL_ADDRESS_REQUIRED": "Syötä kelvollinen sähköpostiosoite",
    "PASSWORD_REQUIREMENTS": "Vähintään 10 merkkiä",
    "VALID": "Kelvollinen",
    "INVALID": "Kelvoton",
    "OPTIONAL": "Valinnainen",
    "COMPLETE_FIELDS_TO_SAVE_FORM": "Huomaa, että kaikki kentät on täytettävä, jotta lomake voidaan tallentaa.",
    "COMPLETE_FIELDS_TO_SUBMIT_FORM": "Huomaa, että kaikki kentät on täytettävä, jotta lomake voidaan lähettää."
  },
  "INVITATION_ROLE": {
    "COORDINATING_ACADEMIC": "vastuuhenkilöksi",
    "MAIN_SUPERVISOR": "pääohjaajaksi",
    "SUPERVISOR": "ohjaajaksi",
    "THESIS_COMMITTEE_MEMBER": "seurantaryhmän jäseneksi"
  },
  "EDIT_PUBLICATION": "Muokkaa julkaisua",
  "EDIT_SALARY": "Muokkaa palkkaa",
  "EDIT_GRANT": "Muokkaa apurahaa",
  "EDIT_COURSE": "Muokkaa kurssia",
  "EDIT_COURSE_WORKSHOP": "Muokkaa kurssia/työpajaa",
  "EDIT_THESIS_INSTRUCTION": "Muokkaa opinnäytetyön ohjausta",
  "EDIT_TEACHING": "Muokkaa opetusta",
  "FOR_STUDENT": "opiskelijalle",
  "CREATE_NEW_REMINDER": "Luo uusi muistutus",
  "LOGO_OF_UNIVERSITY_OF_HELSINKI": "Helsingin Yliopiston logo",
  "NEW": "Uusi",
  "CHANGED": "Muuttunut",
  "INDICATOR": "osoitin",
  "STATUS_IN_THE_MOST_RECENT": "Viimeisimmän raportin tila",
  "SHOW_DETAILS": "Näytä tiedot",
  "HIDE_DETAILS": "Piilota tiedot",
  "ADD_PUBLICATION": "Lisää julkaisu",
  "ADD_COURSE": "Lisää kurssi",
  "EDIT_NOTES": "Muokkaa <0>muistiinpanoja</0>",
  "CANCEL_EDITING_NOTES": "Peruuta <0>muistiinpanojen muokkaaminen</0>",
  "SAVE_NOTES": "Tallenna <0>muistiinpanot</0>",
  "COURSE_IS_COMPLETED": "Kurssi on suoritettu",
  "SEND_MESSAGE": "Lähetä viesti",
  "REMOVE_COMMENT": "Poista kommentti",
  "REPORT_VIEW": {
    "ERROR": {
      "MISSING_REPORT": "Raporttia ei löytynyt.",
      "FAILED_TO_SAVE_REPORT": "<0>Raportin tallentaminen ei onnistunut.</0> Tarkista, että raporttilomakkeella ei ole virheitä ja yritä uudelleen tai <1>ota meihin yhteyttä</1>.",
      "FAILED_TO_SUBMIT_REPORT": "<0>Raportin lähettäminen tarkastettavaksi ei onnistunut.</0> Tarkista, että raporttilomakkeella ei ole virheitä ja yritä uudelleen tai <1>ota meihin yhteyttä</1>.",
      "FAILED_TO_DELETE_REPORT": "<0>Raportin poistaminen ei onnistunut.</0> Yritä uudelleen tai <1>ota meihin yhteyttä</1>."
    }
  },
  "PHD_PROJECT": {
    "ERROR": {
      "GENERAL_ERROR_HEADING": "Thessassa on käynyt virhe.",
      "YOU_CAN_RETRY_OR": "Voit yrittää päivittää sivun, mutta jos se ei auta",
      "INCLUDE_THIS_TEXT": "Auta vian selvittämisessä liittämällä tämä teksti viestiisi",
      "CANNOT_INITIALIZE": "Väitöskirjaprojektin tietojen lataaminen palvelimelta epäonnistui."
    }
  },
  "CANCEL_INVITING_THESIS_COMMITTEE_MEMBER": "Peruuta seurantaryhmän jäsenen kutsuminen",
  "INFORMATION": "Tiedot",
  "COMMENT": "Kommentti",
  "WRITE_YOUR_COMMENT_HERE": "Kirjoita kommenttisi tähän",
  "HIDE_DISCUSSION": "Piilota keskustelu",
  "SHOW_DISCUSSION": "Näytä keskustelu",
  "VIEW_SUPERVISION_PLAN": "Näytä ohjaussuunnitelma",
  "ADD_COURSE_WORKSHOP": "Lisää kurssi/työpaja",
  "ADD_THESIS_INSTRUCTION": "Lisää opinnäytetyön ohjaus",
  "REPORT_STATUS": "Raportin tila",
  "UNTITLED": "Nimeämätön",
  "UNTITLED_PUBLICATION": "Nimeämätön julkaisu",
  "UNTITLED_SALARY": "Nimeämätön palkka",
  "UNTITLED_GRANT": "Nimeämätön apuraha",
  "UNTITLED_COURSE": "Nimeämätön kurssi",
  "UNTITLED_TEACHING": "Nimeämätön opetus",
  "REMOVAL_CONFIRMATION": "Vahvista poisto",
  "THESSA_INTRODUCTION_VIDEO": "Thessan esittelyvideo",
  "ACCESSIBILITY_STATEMENT": "Saavutettavuusseloste",
  "INFORMATION_ON_THE_ACCESSIBILITY_OF_THE_WEBSITE": "Tietoa sivuston saavutettavuudesta",
  "LAST_UPDATED": "Päivitetty viimeksi",
  "LOGIN_FOR_EXTERNAL_USERS": "Kirjaudu sisään yliopiston ulkopuolisilla tunnuksilla",
  "YOU_WILL_NOT_BE_ABLE_TO_ACCESS": "Et voi käyttää Thessaa, kun tilisi on suljettu.",
  "ASK_YOUR_DOCTORAL_PROGRAMME": "Kysy lisätietoja tohtoriohjelmastasi.",
  "REMINDER": "Muistutus",
  "BACK_TO_STATISTICS": "Takaisin tilastoihin",
  "BACK_TO_USER_DETAILS": "Takaisin käyttäjätietoihin",
  "TOTAL_AMOUNT_OF_CREDITS": "Opintopisteiden kokonaismäärä",
  "FOR_EXAMPLE": "Esimerkiksi",
  "DOWNLOAD": "Lataa",
  "PLEASE_SELECT_ONE_OF_THE_FOLLOWING_TYPES": "Valitse yksi seuraavista tyypeistä",
  "PLEASE_SELECT_YOUR_ROLE_IN_THE_CONFERENCE": "Valitse roolisi konferenssissa",
  "PLEASE_NOTE_YOU_HAVE_THE_OPTION_TO_SAVE": "Voit tallentaa edistymisesi milloin tahansa, vaikka kaikki pakolliset kentät eivät olisikaan vielä täytetty. Muista palata ja täyttää kaikki puuttuvat tiedot ennen raportin lopullista lähettämistä.",
  "DURATION_OF_TEACHING": "Opetuksen kesto",
  "CURRENT": "Meneillään",
  "UPCOMING": "Tulossa",
  "SITEMAP": "Sivukartta",
  "UPON_LOGGING_IN": "Kirjautumisen jälkeen tämä sivukartta päivittyy automaattisesti näyttämään sinulle Thessassa saatavilla olevia sivuja ja toimintoja.",
  "OVERVIEW_OF_ALL_SITE_PAGES": "Yleiskatsaus kaikista sivuista helpottamaan navigointia ja nopeaa pääsyä sisältöihin.",
  "UPDATED": "Päivitetty",
  "PLEASE_FILL_OUT_ALL_REQUIRED_FIELDS": "Täytä kaikki pakolliset kentät.",
  "PLEASE_FILL_OUT_ALL_REQUIRED_FIELDS_AND_DATES": "Täytä kaikki pakolliset kentät ja tarkista päivämäärät.",
  "PLEASE_ENTER_VALID_DATES": "Syötä kelvolliset päivämäärät.",
  "IS_MISSING": "puuttuu",
  "THE_VALUE_ENTERED_IS_INVALID": "Syötetty arvo on virheellinen",
  "CLOSE": "Sulje",
  "CHOOSE": "Valitse",
  "SUBMITTING_FORM_FAILED": "Lomakkeen lähettäminen epäonnistui.",
  "TRANSCRIPT_OF_THESSA_INTRODUCTION_VIDEO": "Thessan esittelyvideon tekstitys",
  "INTRODUCTION_TO_USING_THESSA_FOR_DOCTORAL": "Johdanto Thessan käyttöön väitöskirjatutkijoille",
  "CHOOSE_LANGUAGE_FROM_THE_LANGUAGE_NAVIGATION": "Valitse kieli kielivalikosta",
  "AFTER_LOGGING_IN": "Kirjautumisen jälkeen päädyt \"Väitöskirjaprojektini\" -sivulle, joka koostuu seuraavista osioista",
  "SUPERVISION_PLANS_ARE_CREATED_BY_YOUR_SUPERVISORS": "Ohjaajasi luovat ohjaussuunitelmasi. Saat sähköpostia, kun ohjaajasi ovat luoneet sinulle uuden ohjaussuunnitelman. Hyväksyäksesi suunnitelman, klikkaa sähköpostissa olevaa linkkiä ja sen jälkeen klikkaa \"Hyväksy suunnitelma\" -painiketta ohjaussuunnitelman sivulla.",
  "PLANS_FOR_YOUR_PHD_PROJECT": "Väitöskirjaprojektisi suunnitelmat",
  "PLANS_FOR_YOUR_PHD_PROJECT_ARE_CREATED_BY_YOU": "Väitöskirjaprojektisi suunnitelmat luot itse. Nämä suunnitelmat sisältävät tietoja julkaisuistasi, konferensseista, kursseista ja muista väitöskirjaprojektiisi liittyvistä asioista.",
  "REPORTS_ARE_CREATED_BY_YOU_AND_INCLUDE_INFORMATION": "Luot itse raportit ja ne sisältävät tietoja edistymisestäsi väitöskirjaprojektissasi. Ne voidaan tallentaa luonnoksina ja lähettää, kun olet valmis raportin kanssa.",
  "YOUR_SUPERVISORS_ARE_ADDED_AUTOMATICALLY": "Ohjaajasi lisätään automaattisesti. Voit nähdä ohjaajasi ja heidän yhteystietonsa.",
  "YOU_CAN_SEE_INFORMATION_ABOUT_YOUR_THESIS_COMMITTEE": "Voit nähdä tietoja serurantaryhmän jäsenistä. Seurantaryhmän jäsenet on kutsuttava manuaalisesti \"Seurantaryhmä\" -sivulla.",
  "DIGITAL_SERVICE_ACCESSIBILITY_STATUS": "Digipalvelun saavutettavuuden tila",
  "GENERAL_SECTIONS_AND_FEATURES_THAT_ARE_NOT_ACCESSIBLE": "Yleiset osat ja toiminnallisuudet, jotka eivät ole saavutettavia",
  "SERVICES_FOR_DOCTORAL_RESEARCHERS_THAT_ARE_NOT_ACCESSIBLE": "Väitöskirjatutkijoiden palvelut, jotka eivät ole saavutettavia",
  "SERVICES_FOR_SUPERVISORS_THAT_ARE_NOT_ACCESSIBLE": "Ohjaajien palvelut, jotka eivät ole saavutettavia",
  "SERVICES_FOR_COORDINATORS_THAT_ARE_NOT_ACCESSIBLE": "Koordinaattorien palvelut, jotka eivät ole saavutettavia",
  "NOT_WITHIN_THE_SCOPE_OF_LEGISLATION": "Lainsäädännön piiriin kuulumattomat",
  "ACCESSIBILITY_FEEDBACK": "Puutteista raportoiminen",
  "SUPERVISORY_AUTHORITY": "Valvontaviranomainen",
  "CONTACT_INFORMATION_OF_THE_REGULATOR": "Valvontaviranomaisen yhteystiedot",
  "REGIONAL_STATE_ADMINISTRATIVE_AGENCY_FOR_SOUTHERN_FINLAND": "Etelä-Suomen aluehallintovirasto",
  "ACCESSIBILITY_CONTROL_UNIT": "Saavutettavuuden valvonnan yksikkö",
  "TEL_SWITCHBOARD": "Puhelinnumero vaihde",
  "PERCEIVABLE": "Havaittava",
  "OPERABLE": "Hallittava",
  "UNDERSTANDABLE": "Ymmärrettävä",
  "ROBUST": "Toimintavarma",
  "ACCESSIBILITY_OF_THESSA": "Thessan saavutettavuus",
  "THIS_ACCESSIBILITY_STATEMENT_APPLIES": "Tämä saavutettavuusseloste koskee <0>Helsingin yliopiston Thessa-palvelua</0>",
  "THE_SERVICE_IS_SUBJECT_TO_THE_LAW": "Palvelua koskee laki digitaalisten palvelujen tarjoamisesta, jossa edellytetään, että julkisten verkkopalvelujen on oltava saavutettavia. Olemme arvioineet palvelun saavutettavuuden itse.",
  "THE_ACCESSIBILITY_STATEMENT_HAS_BEEN_CREATED_ON": "Saavutettavuusseloste on laadittu {{ createdDate }} ja päivitetty viimeksi {{ lastUpdatedDate }}.",
  "INFO_AND_RELATIONSHIPS": "WCAG 1.3.1: Informaatio ja suhteet",
  "ERROR_IDENTIFICATION": "WCAG 3.3.1: Virheen tunnistaminen",
  "ON_INPUT": "WCAG 3.2.2: Syöte",
  "USE_OF_COLOR": "WCAG 1.4.1: Värien käyttö",
  "CONTRAST_MINIMUM": "WCAG 1.4.3: Kontrasti (minimi)",
  "PARSING": "WCAG 4.1.1: Jäsentäminen",
  "NAME_ROLE_VALUE": "WCAG 4.1.2: Nimi, rooli, arvo",
  "STATUS_MESSAGES": "WCAG 4.1.3: Tilasta kertovat viestit",
  "THE_THESSA_SERVICE_OF_THE_UNIVERSITY_OF_HELSINKI_MEETS": "Helsingin yliopiston Thessa-palvelu täyttää lain vaatimat A ja AA-tason saavutettavuusvaatimukset (WCAG-2.1) suurimmalta osin. Havaitut saavutettavuuspuutteet kuvataan tarkemmin alempana. Nämä mainitut puutteet pyritään korjaamaan digipalvelun ylläpidon yhteydessä. Lue lisää <0>saavutettavuudesta Helsingin yliopistolla.</0>",
  "DATA_TABLES_ARE_NOT_PROGRAMMATICALLY_DISPLAYED": "Taulukkomaista tietoa ei ole esitetty ohjelmallisesti taulukkomuodossa",
  "THE_INFORMATION_ON_THE_CONFERENCES_AND_MOBILITY": "Väitöskirjaprojektini-sivulla oleva Konferenssit ja liikkuvuus -osion tieto ei ole ohjelmallisesti esitetty taulukkomuodossa",
  "FORMS_DO_NOT_SUFFICIENTLY_GUIDE_USERS_ON_INCORRECT": "Lomakkeet eivät opasta käyttäjää riittävästi virheellisistä tai vajaista syötteistä",
  "THE_SUBMISSION_BUTTONS_IN_THE_FORMS_FOR_SUPERVISION": "Ohjaussuunnitelma- ja raporttisivujen lomakkeiden lähetyspainikkeet ovat poistettu käytöstä lomakkeen ollessaan vajaasti tai virheellisesti täytetty",
  "THE_INFORMATION_ON_THE_MY_DOCTORAL_THESIS_PAGE": "Väitöskirjatutkijani-sivulla oleva tieto ei ole ohjelmallisesti esitetty taulukkomuodossa",
  "A_FORM_DOES_NOT_DISPLAY_THE_SUBMISSION_BUTTON": "Lomake ei näytä lähetyspainiketta",
  "THE_SUBMISSION_BUTTON_FOR_THE_PROGRESS_FEEDBACK": "Raportin yhteydessä jätettävän edistymispalautteen lomakkeen lähetyspainike näytetään vasta esikatselu tilassa ja on muutoin piilotettu käyttäjältä",
  "GRAPHIC_ELEMENTS_LACK_TEXT_ALTERNATIVES_AND_ARE_NOT": "Graafisilta elementeiltä puuttuu tekstivastine ja niitä ei olla ohjelmallisesti merkattu kuviksi",
  "ON_THE_LIST_DOCTORAL_RESEARCHERS_ACCORDING_TO_REPORT": "Listaa väitöskirjatutkijat raportin tilan mukaan -sivulla jotkin graafiset elementit ovat puutteellisesti ohjelmallisesti merkattu kuviksi ja osasta puuttuu tekstivastine",
  "TITLES_HAVE_NOT_BEEN_USED_TO_PARSE_CONTENT": "Otsikkoja ei olla käytetty sisällön jäsentelyyn",
  "THE_TITLES_ON_THE_OVERVIEW_OF_CONCLUDING_STATEMENTS": "Loppulauseiden yleiskatsaus -sivulla otsikoita ei ole käytetty sisällön jäsentelyyn",
  "THE_DISTINCTIVENESS_OF_LINKS_FROM_OTHER_TEXT_AND_FOCUS": "Linkkien erotettavuus muusta tekstistä sekä kohdistustyylit tukeutuvat ainoastaan väreihin",
  "IN_THE_COORDINATOR_SERVICES_THE_DISTINCTIVENESS": "Koordinaattoripalveluissa usealla sivulla linkkien erotettavuus muusta tekstistä sekä interaktiivisten elementtien kohdistustyylit voivat tukeutua ainoastaan väri muutoksiin",
  "THE_CONTRAST_RATIO_OF_TEXT_ELEMENTS_COMPARED": "Tekstielementtien kontrastisuhde taustaan verrattuna ei ole riittävä",
  "THE_CONTRAST_RATIO_OF_TEXT_ELEMENTS_ON_THE": "Kaikkien loppulauseiden yleiskatsaus -sivulla olevien tekstielementtien kontrastisuhde taustaan verrattuna ei ole riittävän suuri",
  "THE_SUBMISSION_BUTTON_IN_THE_FORM_FOR_ADDING_SUPERVISORS": "Väitöskirjatutkijat-sivun ohjaajan lisäämislomakkeen lähetyspainike on poistettu käytöstä lomakkeen ollessaan vajaasti tai virheellisesti täytetty",
  "THERE_ARE_DEFICIENCIES_IN_THE_PROGRAMMATIC": "Sivuston ohjelmallisessa rakenteessa on puutteita",
  "THE_USER_INFORMATION_PAGE_CONTAINS_BUTTONS_THAT": "Käyttäjätieto-sivulla on painikkeita, jotka ovat spesifikaation vastaisesti asetettu sisäkkäin ja näin ollen tekee niiden käytöstä haastavaa näppäimistö- ja ruudunlukija käyttäjille",
  "BUTTONS_HAVE_NO_VISIBLE_OR_PROGRAMMATIC_TEXT": "Painikkeilla ei ole näkyvää eikä ohjelmallista tekstiä",
  "THE_SUPERVISOR_FORM_ON_THE_DOCTORAL_RESEARCHERS_PAGE": "Väitöskirjatutkijat-sivun Ohjaaja-lomakkeella on painikkeita, joilla ei ole näkyvää tai ohjelmallista tekstiä",
  "SCREEN_READERS_DO_NOT_AUTOMATICALLY": "Ruudunlukijaohjelmat eivät automaattisesti ilmoita tilasta kertovia viestejä",
  "ON_THE_DOCTORAL_RESEARCHERS_AND_LIST_THE_REVIEWERS": "Väitöskirjatutkijat- sekä Luettele keskeneräisten raporttien arvioijat -sivuilla ruudunlukijaohjelmat eivät automaattisesti ilmoita tilasta kertovia viestejä",
  "NOT_COVERED_BY_LEGISLATION": "Ei kuulu lainsäädännön piiriin",
  "CONTENT_PUBLISHED_BY_USERS_OR_OTHER_THIRD_PARTIES": "Käyttäjien tai muiden ulkopuolisten tahojen palvelussa julkaisemia sisältöjä, jotka eivät ole palveluntarjoajan itsensä tuottamia, rahoittamia tai valvomia.",
  "CONTENT_PUBLISHED_BY_USERS_THAT_CANNOT": "Sivustolla saattaa esiintyä käyttäjien julkaisemaa sisältöä, joita ei voida muokata saavutettavuusvaatimusten mukaisiksi. Käyttäjien julkaisemaa sisältöä ei myöskään olla ohjelmallisesti merkattu (WCAG 3.1.2) käyttämään julkaisun sisällön kieltä.",
  "DID_YOU_NOTICE_AN_ACCESSIBILITY_ISSUE_IN": "Huomasitko saavutettavuuspuutteen digipalvelussamme? Kerro se meille sähköpostilla ja teemme parhaamme puutteen korjaamiseksi.",
  "IF_YOU_NOTICE_ACCESSIBILITY_ISSUES_ON": "Jos huomaat sivustolla saavutettavuusongelmia, anna ensin palautetta meille eli sivuston ylläpitäjälle. Vastauksessa voi mennä 14 päivää. Jos et ole tyytyväinen saamaasi vastaukseen tai et saa vastausta lainkaan kahden viikon aikana, <0>voit tehdä ilmoituksen Etelä-Suomen aluehallintovirastoon</0>. Etelä-Suomen aluehallintoviraston sivulla kerrotaan tarkasti, miten ilmoituksen voi tehdä ja miten asia käsitellään.",
  "PAGINATION": "Sivutus",
  "GO_TO_PAGE": "Mene sivulle",
  "AMOUNT_OF_ITEMS_PER_PAGE": "Tulosten määrä sivulla",
  "THERE_WAS_AN_ERROR_IN_LOADING_SUPERVISION_PLANS": "<0>Ohjaussuunnitelmien lataamisessa tapahtui virhe.</0> <1>Ilmoita meille</1>, jos ongelma ei poistu.",
  "PHD_PROJECT_LIST": {
    "ERROR_DELETE_FAILED": "Poistaminen ei onnistunut."
  },
  "THESIS_COMMITTEE": {
    "TITLE": "Seurantaryhmä",
    "MEETINGS": "Kokoukset",
    "CHOOSE_FIRST_MEETING_DATE": "Valitse ensimmäisen seurantaryhmän kokouksen päivämäärä",
    "NO_FIRST_MEETING_DATE_YET": "Ensimmäisen kokouksen päivämäärää ei ole vielä valittu",
    "CHOOSE_DATE": "Valitse päivä",
    "CHANGE_DATE": "Vaihda päivä",
    "MEETING": "Seurantaryhmän kokous",
    "FIRST_MEETING": "Ensimmäinen seurantaryhmän kokous",
    "MEETING_DATE": "Kokouksen päivämäärä",
    "MEETING_DATE_IN_PAST": "Kokouksen päivämäärä on menneisyydessä",
    "ERROR_CHOOSING_DATE": "Virhe seurantaryhmän kokouksen tallentamisessa. Yritä uudelleen.",
    "ERROR_MISSING_DATE": "Valitse kokouksen päivämäärä ennen tallentamista.",
    "REPORT_FOR_MEETING": "Kokouksessa käsiteltävä raportti",
    "REPORT_FOR_MEETING_READY": "<0>Raportti</0> on valmis käsiteltäväksi kokouksessa.",
    "REPORT_FOR_MEETING_MISSING": "<0>Raportti puuttuu.</0> Lähetä raportti <0>{{ startDate }}–{{ endDate }}</0> välisenä aikana, jotta se lasketaan tässä kokouksessa käsiteltäväksi raportiksi."
  },
  "SUPERVISION_PLAN_TITLE_NO_END_DATE": "{{startDate}} alkaen",
  "SUPERVISION_PLAN_HEAD_TITLE": "Ohjaussuunnitelma {{startDate}} - {{endDate}}",
  "SUPERVISION_PLAN_HEAD_TITLE_NO_END_DATE": "Ohjaussuunnitelma {{startDate}} alkaen",
  "VALIDITY": "Voimassaolo",
  "COPY_DECISIONS_TO_NEW_PLAN": "Kopioi päätökset uuteen suunnitelmaan",
  "COPY_DECISIONS_FROM_A_PREVIOUS_PLAN": "Kopioi päätökset aiemmasta suunnitelmasta",
  "PREVIEW_OF_THE_SUPEVISION_PLAN_FOR": "Henkilön {{name}} ohjaussuunnitelman esikatselu",
  "SELECT_A_PREVIOUS_SUPERVISION_PLAN_TO_COPY_FROM": "Valitse aiempi ohjaussuunnitelma, josta haluat kopioida päätökset",
  "PLEASE_NOTE_THAT_SUPERVISION_PLANS_MAY_CONTAIN": "Huomaathan, että ohjaussuunnitelmat voivat sisältää arkaluontoista tietoa, kuten tutkimustietoja ja henkilötietoja.",
  "CHAPTER": "Jakso",
  "CHAPTERS": "Jaksot",
  "CHAPTERS_AND_MILESTONES": "Jaksot ja tavoitteet",
  "CURRENT_CHAPTER": "Nykyinen jakso",
  "MILESTONE": "Tavoite",
  "MILESTONES": "Tavoitteet",
  "PERSONAL_MILESTONE": "Henkilökohtainen tavoite",
  "PERSONAL_MILESTONES": "Henkilökohtaiset tavoitteet",
  "COMMON_MILESTONE": "Yhteinen tavoite",
  "COMMON_MILESTONES": "Yhteiset tavoitteet",
  "ADD_PERSONAL_MILESTONE": "Lisää henkilökohtainen tavoite",
  "ADD_COMMON_MILESTONE": "Lisää yhteinen tavoite",
  "STARTING": "Alkaa",
  "STARTED": "Aloitettu",
  "PLANNED": "Suunniteltu",
  "ESTIMATED": "Arvioitu",
  "STARTED_FROM_GRANTING_STUDY_RIGHT": "Alkoi <0>{{ date }}</0> opinto-oikeuden myöntämisestä",
  "STARTED_AT_THE_THESIS_COMMITTEE_MEETING_HELD_ON": "Alkoi <0>{{ date }}</0> pidettävässä seurantaryhmän kokouksessa",
  "BEGIN_AT_THE_THESIS_COMMITTEE_MEETING_HELD_ON": "Alkaa <0>{{ date }}</0> pidettävässä seurantaryhmän kokouksessa",
  "BEGIN_AT_THE_MEETING_ESTIMATED_TO_BE_HELD_ON": "Alkaa arviolta <0>{{ date }}</0> pidettävässä kokouksessa",
  "EACH_CHAPTER_REPRESENTS_THE_TIME_PERIOD": "Jokainen jakso tarkoittaa ajanjaksoa kahden seurantaryhmän kokouksen välillä.",
  "OVERVIEW": "Yleiskatsaus",
  "NO_MILESTONES_IN_THE_PLAN_FOR_THE_CHAPTER_YET": "Tälle jaksolle ei ole vielä lisätty tavoitteita.",
  "VIEW_CHAPTERS": "Näytä jaksot",
  "EDIT_MILESTONES": "Muokkaa tavoitteita",
  "CUSTOM_MILESTONES_CREATED_BY_THE_USER": "Käyttäjän luomia tavoitteita. Voit lisätä niin monta henkilökohtaista tavoitetta kuin haluat ja liittää ne mihin tahansa jaksoon.",
  "A_PREDEFINED_SET_OF_MILESTONES_THAT_MUST_BE_ASSIGNED": "Ennalta määrättyjä tavoitteita, jotka tulee liittää johonkin jaksoon. Yleiset tavoitteet on ryhmitelty seuraaviin kategorioihin",
  "STUDY_RIGHT_GRANTED": "Opiskeluoikeus myönnetty",
  "ADD_ITEM": "Lisää kohde",
  "MILESTONE_DESCRIPTION": "Tavoitteen kuvaus",
  "MOVE": "Siirrä",
  "OPEN_CONTEXT_MENU": "Avaa kontekstivalikko"
}
