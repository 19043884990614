import Publication from './Publication'
import Course from './Course'
import Conference from './Conference'
import InternationalActivity from './InternationalActivity'
import Salary from './Salary'
import Grant from './Grant'
import Teaching from './Teaching'
import { SupervisorRole } from '../model'

export interface DoctoralCandidateResponse {
  id: string
  name: string
  email: string
  beginningOfStudies: string
  expectedGraduationDate: string
  doctoralProgramme: string
  publications: DoctoralCandidatePublicationResponse[]
  courses: DoctoralCandidateCourseResponse[]
  salaries: DoctoralCandidateSalaryResponse[]
  grants: DoctoralCandidateGrantResponse[]
  teachings: DoctoralCandidateTeachingResponse[]
  conferences: DoctoralCandidateConferenceResponse[]
  internationalActivities: DoctoralCandidateInternationalActivityResponse[]
  isFastDoctoralCandidate: boolean
  notes: string
  supervisorRelationships: DoctoralCandidateSupervisorRelationshipResponse[]
  thesisCommitteeMembers: DoctoralCandidateThesisCommitteeMemberResponse[]
  major: string
  faculty: string
  studentNumber: string
  verifiedStudentNumber: string
  preliminaryTitleOfDissertation: string
  nextReportDeadline: string
  daysUntilNextReportDeadline: number
  thesisCommitteeMemberInvitationCount: number
  roles: DoctoralCandidateRolesResponse
}

export interface DoctoralCandidatePublicationResponse {
  id: number
  doctoralCandidateId: number
  title: string
  inThesis: boolean
  createdAt: string
  updatedAt: string
  deletedAt: string
  statuses: DoctoralCandidatePublicationStatusResponse[]
}

export interface DoctoralCandidatePublicationStatusResponse {
  id: number
  order: number
  status: 'data-collection' | 'manuscript' | 'peer-review' | 'published'
  created_at: string
  updated_at: string
  pivot: {
    publication_id: number
    publication_status_id: number
    status_started_at: string
  }
  statusStartedAt: string
}

interface DoctoralCandidateCourseResponse {
  id: number
  title: string
  credits: number
  isCompleted: boolean
  completedAt: string
}

interface DoctoralCandidateSalaryResponse {
  id: number
  source: string
  beginsAt: string
  endsAt: string
}

interface DoctoralCandidateGrantResponse {
  id: number
  source: string
  amount: string
  beginsAtMonth: string
  endsAtMonth: string
  usedFor: string
  status: 'planning-to-apply' | 'application-sent' | 'response-positive' | 'response-negative'
}

interface DoctoralCandidateTeachingResponse {
  id: number
  type: 'course_or_workshop' | 'thesis_instructor'
  beginsAt: string
  endsAt: string
  myRole: string
  courseName: string
  workHours: number
  studentName: string
  thesisLevel: string
  thesisTopicOrTitle: string
}

interface DoctoralCandidateConferenceResponse {
  id: number
  name: string
  beginsAt: string
  endsAt: string
  type: 'participant' | 'organizer'
  participationType: 'poster' | 'talk' | 'participation_without_presentation'
  description: string
}

interface DoctoralCandidateInternationalActivityResponse {
  id: number
  type: 'conference' | 'other' | 'research-visit'
  name: string
  location: string
  beginsAt: string
  endsAt: string
  conferenceRole: 'participant' | 'organizer'
  participationType: 'poster' | 'talk' | 'participation_without_presentation'
  description: string
}

interface DoctoralCandidateSupervisorRelationshipResponse {
  id: number
  roles: SupervisorRole[]
  name: string
  email: string
  userId: number
  isVerified: boolean
  verifiedAt: null | string
  verifiedBy: null | string
}

interface DoctoralCandidateThesisCommitteeMemberResponse {
  id: number
  name: string
  email: string
  userId: number
}

export interface DoctoralCandidateRolesResponse {
  isRequesterDoctoralCandidate: boolean
  isRequesterCoordinator: boolean
  isRequesterSupervisor: boolean
  isRequesterMemberOfThesisCommittee: boolean
  isRequesterSalarySystemForeman: boolean
}

export default class DoctoralCandidate {
  id: string
  beginningOfStudies: string
  publications: Publication[]
  courses: Course[]
  conferences: Conference[]
  doctoralProgramme: string
  internationalActivities: InternationalActivity[]
  isFastDoctoralCandidate: boolean
  salaries: Salary[]
  grants: Grant[]
  teachings: Teaching[]
  notes: string
  email: string
  name: string
  supervisorRelationships: DoctoralCandidateSupervisorRelationshipResponse[]
  major: string
  faculty: string
  studentNumber: string
  verifiedStudentNumber: string
  roles: DoctoralCandidateRolesResponse
  thesisCommitteeMembers: DoctoralCandidateThesisCommitteeMemberResponse[]
  thesisCommitteeMemberInvitationCount: number
  preliminaryTitleOfDissertation: string

  constructor({
    id,
    beginningOfStudies,
    studentNumber,
    doctoralProgramme,
    publications,
    courses,
    conferences,
    internationalActivities,
    isFastDoctoralCandidate,
    notes,
    email,
    name,
    salaries,
    grants,
    supervisorRelationships,
    teachings,
    faculty,
    major,
    verifiedStudentNumber,
    roles,
    thesisCommitteeMembers,
    thesisCommitteeMemberInvitationCount,
    preliminaryTitleOfDissertation
  }: DoctoralCandidateResponse) {
    this.id = id
    this.beginningOfStudies = beginningOfStudies
    this.publications = publications.map((publication) => new Publication(publication))
    this.courses = courses.map((course) => new Course(course))
    this.conferences = conferences.map((conference) => new Conference(conference))
    this.doctoralProgramme = doctoralProgramme
    this.internationalActivities = internationalActivities.map(
      (internationalActivity) => new InternationalActivity(internationalActivity)
    )
    this.isFastDoctoralCandidate = isFastDoctoralCandidate
    this.salaries = salaries.map((salary) => new Salary(salary))
    this.grants = grants.map((grant) => new Grant(grant))
    this.teachings = teachings.map((teaching) => new Teaching(teaching))
    this.notes = notes
    this.email = email
    this.name = name
    this.supervisorRelationships = supervisorRelationships
    this.major = major
    this.faculty = faculty
    this.studentNumber = studentNumber
    this.verifiedStudentNumber = verifiedStudentNumber
    this.roles = roles
    this.thesisCommitteeMembers = thesisCommitteeMembers
    this.thesisCommitteeMemberInvitationCount = thesisCommitteeMemberInvitationCount
    this.preliminaryTitleOfDissertation = preliminaryTitleOfDissertation
  }

  addPublication(publication: Publication) {
    this.publications.push(publication)
  }

  addCourse(course: Course) {
    this.courses.push(course)
  }

  addConference(conference: Conference) {
    this.conferences.push(conference)
  }

  addInternationalActivity(internationalActivity: InternationalActivity) {
    this.internationalActivities.push(internationalActivity)
  }

  addSalary(salary: Salary) {
    this.salaries.push(salary)
  }

  addGrant(grant: Grant) {
    this.grants.push(grant)
  }

  addTeaching(teaching: Teaching) {
    this.teachings.push(teaching)
  }
}
