import styled, { css } from 'styled-components'
import { fromTabletUp } from '../../styledUtils'
import { UniParagraphAnchorStyles, UniParagraphStyles } from './UniParagraph'

export const UniList = styled.ul<{
  textAlignEnd?: boolean
  noMargin?: boolean
  noMarginTop?: boolean
  noMarginBottom?: boolean
}>`
  ${UniParagraphStyles}
  margin: 16px 0;
  padding: 0;

  ${fromTabletUp(css`
    margin: 24px 0;
  `)}

  ${({ textAlignEnd = false }) =>
    textAlignEnd &&
    css`
      text-align: end;
    `}

  ${(props) =>
    props.noMargin &&
    css`
      margin: 0;

      ${fromTabletUp(css`
        margin: 0;
      `)}
    `}


  ${(props) =>
    props.noMarginTop &&
    css`
      margin-top: 0;

      ${fromTabletUp(css`
        margin-top: 0;
      `)}
    `}

  ${(props) =>
    props.noMarginBottom &&
    css`
      margin-bottom: 0;

      ${fromTabletUp(css`
        margin-bottom: 0;
      `)}
    `}
`

export const UniListItem = styled.li<{ noBullet?: boolean; noMargin?: boolean }>`
  ${UniParagraphStyles}
  margin: 8px 0;
  margin-left: 18px;

  ${({ noBullet }) =>
    noBullet &&
    css`
      list-style-type: none;
      margin-left: 0;
    `}

  ${(props) =>
    props.noMargin &&
    css`
      margin: 0;

      ${fromTabletUp(css`
        margin: 0;
      `)}
    `}

  a {
    ${UniParagraphAnchorStyles}
  }
`
