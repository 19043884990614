import React from 'react'
import PropTypes from 'prop-types'
import { UniButton } from '../../common/uni/UniButton'

const CardActionButton = ({
  secondary = true,
  minimal = false,
  icon = 'plus',
  onClick,
  disabled = false,
  children
}) => {
  return (
    <UniButton
      small
      minimal={minimal}
      secondary={secondary}
      icon={icon}
      onClick={onClick}
      disabled={disabled}
      className="card-action"
    >
      {children}
    </UniButton>
  )
}

CardActionButton.propTypes = {
  secondary: PropTypes.bool,
  disabled: PropTypes.bool
}

export default CardActionButton
