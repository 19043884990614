import React from 'react'
import { Link } from 'react-router'
import styled, { css } from 'styled-components'
import { BRAND_MAIN_LIGHT, GRAYSCALE_DARK, GRAYSCALE_MEDIUM_DARK, LINK_BLUE, GRAYSCALE_BLACK } from '../uni/colors'
import { DEFAULT_FONT } from '../uni/typography'
import { UniParagraphAnchorStyles } from '../uni/UniParagraph'

interface PageNavigationProps {
  id?: string
  items: Array<{
    id?: string
    path: string
    title: string
    subTitle?: string
    iconBefore?: string
    iconAfter?: string
    isActive?: boolean
    className?: string
  }>
  paddingBlock?: boolean
  row?: boolean
  column?: boolean
}

const PageNavigation = ({ id, items = [], row = true, column, paddingBlock }: PageNavigationProps) => {
  return (
    <Container className="page-navigation" paddingBlock={paddingBlock}>
      <nav id={id}>
        <List row={row} column={column}>
          {items.map(({ id, path, title, subTitle, iconBefore, iconAfter, isActive, className }, key) => (
            <Item key={key}>
              <NavigationLink
                id={id}
                to={path}
                aria-current={isActive ? 'page' : undefined}
                $isActive={isActive}
                $hasIconBefore={!!iconBefore}
                $hasIconAfter={!!iconAfter}
                $hasSubTitle={!!subTitle}
                className={`page-navigation__item ${className}`}
              >
                {iconBefore && (
                  <Icon
                    aria-hidden="true"
                    role="img"
                    $isIconBefore={true}
                    className={`glyphicon ${iconBefore} page-navigation__item-icon`}
                  ></Icon>
                )}

                {(title || subTitle) && (
                  <Text $hasIconBefore={!!iconBefore} $hasIconAfter={!!iconAfter}>
                    {title && <Title isColumn={column}>{title}</Title>}
                    {subTitle && <SubTitle>{subTitle}</SubTitle>}
                  </Text>
                )}

                {iconAfter && (
                  <Icon
                    aria-hidden="true"
                    role="img"
                    $isIconAfter={true}
                    className={`glyphicon ${iconAfter} page-navigation__item-icon`}
                  ></Icon>
                )}
              </NavigationLink>
            </Item>
          ))}
        </List>
      </nav>
    </Container>
  )
}

const Container = styled.div<{ paddingBlock?: boolean }>`
  border-top: ${GRAYSCALE_MEDIUM_DARK};
  font-size: 16px;
  ${DEFAULT_FONT}

  ${({ paddingBlock }) =>
    paddingBlock &&
    css`
      padding-block: 12px;
    `}
`

const List = styled.ul<{ row?: boolean; column?: boolean }>`
  display: flex;
  gap: 16px;
  margin: 0;
  padding: 0;

  ${(props) =>
    props.column
      ? css`
          display: flex;
          flex-flow: column wrap;
          gap: 0px;
        `
      : props.row
      ? css`
          display: flex;
          flex-flow: row wrap;
          row-gap: 16px;
          column-gap: 24px;
        `
      : ''}
`

const Item = styled.li`
  position: relative;
  list-style: none;
`

const NavigationLink = styled(Link)<{
  $hasIconBefore?: boolean
  $hasIconAfter?: boolean
  $hasSubTitle?: boolean
  $isActive?: boolean
}>`
  ${UniParagraphAnchorStyles}
  display: block;
  position: relative;
  color: ${LINK_BLUE};
  padding: 8px 0px;

  ${({ $hasSubTitle }) =>
    $hasSubTitle
      ? css`
          justify-content: space-between;
        `
      : ''}

  ${({ $hasIconBefore }) => ($hasIconBefore ? css`` : '')}

  ${({ $hasIconAfter }) => ($hasIconAfter ? css`` : '')}

  &[aria-current] {
    border-left: 4px solid ${GRAYSCALE_BLACK};
    padding-left: 20px;
  }
`

const Icon = styled.span<{
  $isIconBefore?: boolean
  $isIconAfter?: boolean
}>`
  position: absolute;

  top: 50%;
  transform: translateY(-50%);
  color: ${BRAND_MAIN_LIGHT};
  line-height: 1;
  width: 1em;
  height: 1em;

  ${({ $isIconBefore }) =>
    $isIconBefore
      ? css`
          left: 0;
        `
      : ''}

  ${({ $isIconAfter }) =>
    $isIconAfter
      ? css`
          right: 0;
        `
      : ''}
`

const Text = styled.div<{
  $hasIconBefore?: boolean
  $hasIconAfter?: boolean
}>`
  ${({ $hasIconBefore }) =>
    $hasIconBefore
      ? css`
          padding-left: 32px;
        `
      : ''}
  ${({ $hasIconAfter }) =>
    $hasIconAfter
      ? css`
          padding-right: 32px;
        `
      : ''}
`

const Title = styled.div<{ isColumn?: boolean }>`
  ${NavigationLink}:hover & {
    text-decoration: underline;
  }

  ${({ isColumn }) =>
    isColumn &&
    css`
      color: ${BRAND_MAIN_LIGHT};
      font-weight: 800;
    `}
`

const SubTitle = styled.div`
  font-size: 14px;
  margin-top: 2px;
  color: ${GRAYSCALE_DARK};
`

export default PageNavigation
