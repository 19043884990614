import { ChapterItemType, ChapterItem, ChapterAssignableItem, ChapterItemBase } from './types'
import { useTranslation } from 'react-i18next'
import { usePhdProject } from '../doctoral-candidate-overview/phd-project-context/PhdProjectContextProvider'
import moment from 'moment'
import { useLayoutEffect, useRef, useState } from 'react'
import { Chapter } from '../../models/Chapter'
import { Chapter as ChapterType } from './types'

const CHAPTER_MONTHLY_INTERVAL = [3, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6]

export function getChapterMontlyInterval(index: number, plannedAt: moment.Moment | undefined) {
  if (index === 0 && plannedAt && moment().isAfter(plannedAt)) {
    return 0
  } else {
    return plannedAt && moment().isAfter(plannedAt) ? CHAPTER_MONTHLY_INTERVAL[index] : 0
  }
}

export function calculateCompletedAt(plannedAt: moment.Moment | undefined, interval: number) {
  return plannedAt && moment().isAfter(plannedAt) ? plannedAt.clone().add(interval, 'months') : undefined
}

export const useChapters = (): {
  chapters: ChapterType[]
  expectedGraduationDate: string | undefined
} => {
  const { t } = useTranslation()

  const phdProject = usePhdProject()
  const { thesisCommitteeMeetings, beginningOfStudies, expectedGraduationDate } = phdProject

  const chapterGenerator = new Chapter(t)

  const chapters = chapterGenerator.generateChapters(
    thesisCommitteeMeetings,
    beginningOfStudies,
    expectedGraduationDate
  )

  return {
    chapters,
    expectedGraduationDate
  }
}

export function useChapterProgress(chapter: ChapterType, chapters: ChapterType[]) {
  const { items } = chapter || {}
  const containerRef = useRef<HTMLDivElement>(null)
  const itemReferences = useRef<(HTMLLIElement | null)[]>([])
  const [barHeight, setBarHeight] = useState(0)
  const [completedTop, setCompletedTop] = useState(0)
  const [completedHeight, setCompletedHeight] = useState(0)
  const [completedItemPositions, setCompletedItemPositions] = useState<number[]>([])

  const isNextStartEventCompleted = getIsNextStartEventCompleted(chapters, chapter)

  const currentChapterStartEvent =
    items?.find((item) => item.type === ChapterItemType.Meeting) ??
    items?.find((item) => item.type === ChapterItemType.StudyRight)
  const isCurrentStartEventCompleted = !!currentChapterStartEvent?.completedAt

  const areAllChapterItemsCompleted = items?.length > 0 && items.every((item) => item.completedAt)

  useLayoutEffect(() => {
    if (containerRef.current) {
      const container = containerRef.current
      const containerHeight = container.offsetHeight
      setBarHeight(containerHeight)

      const hasOnlyStarEventItem =
        items?.length === 1 &&
        (items[0].type === ChapterItemType.Meeting || items[0].type === ChapterItemType.StudyRight)
      const isStartEventCompleted = !!items?.[0]?.completedAt

      if (hasOnlyStarEventItem && isStartEventCompleted && !isNextStartEventCompleted) {
        const meetingItem = itemReferences.current[0]
        if (meetingItem) {
          const meetingItemBottom = meetingItem.offsetTop + meetingItem.offsetHeight
          const halfwayPointAfterMeeting = meetingItemBottom + (containerHeight - meetingItemBottom) / 2
          const completedHeight = halfwayPointAfterMeeting

          setCompletedTop(0)
          setCompletedHeight(completedHeight)
        }
        setCompletedItemPositions([])
      } else if (!isCurrentStartEventCompleted) {
        const positions: number[] = []

        items?.forEach((item, index) => {
          if (item.completedAt) {
            const itemRef = itemReferences.current[index]
            if (itemRef) {
              const position = itemRef.offsetTop + itemRef.offsetHeight / 2
              positions.push(position)
            }
          }
        })

        setCompletedItemPositions(positions)
        setCompletedTop(0)
        setCompletedHeight(0)
      } else {
        setCompletedItemPositions([])

        if (areAllChapterItemsCompleted) {
          if (isNextStartEventCompleted) {
            setCompletedTop(0)
            setCompletedHeight(containerHeight)
          } else {
            const lastItemIndex = items.length - 1
            const lastItem = itemReferences.current[lastItemIndex]

            if (lastItem) {
              const top = 0
              const bottom = lastItem.offsetTop + lastItem.offsetHeight / 2
              const height = bottom - top

              setCompletedTop(top)
              setCompletedHeight(height)
            }
          }
        } else {
          if (isNextStartEventCompleted) {
            setCompletedHeight(containerHeight)
          } else {
            let firstCompletedIndex = -1
            let lastCompletedIndex = -1

            items?.forEach((item, index) => {
              if (item.completedAt) {
                if (firstCompletedIndex === -1) {
                  firstCompletedIndex = index
                }
                lastCompletedIndex = index
              }
            })

            if (firstCompletedIndex !== -1 && lastCompletedIndex !== -1) {
              const firstItem = itemReferences.current[firstCompletedIndex]
              const lastItem = itemReferences.current[lastCompletedIndex]

              if (firstItem && lastItem) {
                const top = firstItem.offsetTop + firstItem.offsetHeight / 2
                const bottom = lastItem.offsetTop + lastItem.offsetHeight / 2
                const height = bottom - top

                setCompletedTop(top)
                setCompletedHeight(height)
              }
            } else {
              setCompletedTop(0)
              setCompletedHeight(0)
            }
          }
        }
      }
    }
  }, [items, areAllChapterItemsCompleted, isNextStartEventCompleted, isCurrentStartEventCompleted])

  return {
    containerRef,
    itemReferences,
    barHeight,
    completedTop,
    completedHeight,
    completedItemPositions,
    isNextStartEventCompleted,
    isCurrentStartEventCompleted,
    areAllChapterItemsCompleted
  }
}

export function getCurrentChapter(chapters: ChapterType[]) {
  if (!Array.isArray(chapters)) {
    return null
  }
  return [...chapters]
    .reverse()
    .find((chapter) =>
      chapter.items.some(
        (item) =>
          (item.type === ChapterItemType.Meeting && item.completedAt) ||
          (item.type === ChapterItemType.StudyRight && item.completedAt)
      )
    )
}

export function getIsNextStartEventCompleted(chapters: ChapterType[], chapter: ChapterType) {
  return Boolean(chapters[chapters.indexOf(chapter) + 1]?.items?.[0]?.completedAt)
}

export function getNumberOfChaptersCompleted(chapters: ChapterType[]) {
  return chapters.filter((chapter) => {
    const isNextStartEventCompleted = getIsNextStartEventCompleted(chapters, chapter)
    return getAreAllChapterItemsCompleted(chapter) && isNextStartEventCompleted
  }).length
}

export function getAreAllChapterItemsCompleted(chapter: ChapterType) {
  return chapter?.items.every((item) => item.completedAt)
}

export function getChapterStartEvent(chapter: ChapterType) {
  return chapter.items.find((item) => item.type === 'Meeting' || item.type === 'StudyRight')
}

export function getChapterStatusText(chapter: ChapterType, chapters: ChapterType[], item: ChapterItem): string | null {
  const isChapterCompleted = getAreAllChapterItemsCompleted(chapter) && getIsNextStartEventCompleted(chapters, chapter)
  const currentChapter = getCurrentChapter(chapters)
  const isCurrentChapter = currentChapter?.id === chapter?.id

  if (!isChapterCompleted && isCurrentChapter) {
    return 'active'
  } else if ('completedAt' in item && item.completedAt) {
    return 'completed'
  } else if ('plannedAt' in item && item.plannedAt) {
    return 'planned'
  } else if ('suggestedAt' in item && item.suggestedAt) {
    return 'suggested'
  }
  return null
}

export function convertToChapterItem(
  item: ChapterAssignableItem,
  type: ChapterItemType,
  chapterNumber: number
): ChapterItem {
  switch (type) {
    default:
      throw new Error(`Unsupported ChapterItemType: ${type}`)
  }
}
