import React, { useContext } from 'react'
import { UniHeading3, UniHeading4 } from './uni/UniHeading'
import { UniBanner } from './uni/UniBanner'
import { Actions } from './uni/UniForm'
import { WindowWidthContext } from '../../WindowWidthContext'
import { UniParagraph } from './uni/UniParagraph'

interface CardProperties {
  modifier: string
  cardAction: React.ReactNode
  heading: string
  children: React.ReactNode
  notification?: string
}

const Card = ({ modifier, cardAction, heading, children, notification }: CardProperties) => {
  const { isPhoneOrSmaller } = useContext(WindowWidthContext)
  return (
    <UniBanner
      gray={false}
      white={!isPhoneOrSmaller}
      paddingInline={!isPhoneOrSmaller}
      border={false}
      className={`doctoral-candidate-overview__card doctoral-candidate-overview__card--${modifier}`}
    >
      <CardHeading cardAction={cardAction}>{heading}</CardHeading>
      {notification ? <div className="doctoral-candidate-overview__card-notification">{notification}</div> : null}
      {children}
    </UniBanner>
  )
}

interface CardHeadingProperties {
  cardAction: React.ReactNode
  children: React.ReactNode
}

export const CardHeading = ({ cardAction, children }: CardHeadingProperties) => {
  return (
    <Actions className="doctoral-candidate-overview__card-heading" noPadTop noWrap marginBottom>
      <UniHeading3>{children}</UniHeading3>
      {cardAction ? (
        <Actions firstToStart noPadTop>
          {cardAction}
        </Actions>
      ) : null}
    </Actions>
  )
}

export const CardSubHeading = ({ cardAction, children }: CardHeadingProperties) => {
  return (
    <Actions className="doctoral-candidate-overview__card-sub-heading" noWrap marginBottom>
      <UniHeading4>{children}</UniHeading4>
      {cardAction ? (
        <Actions firstToStart noPadTop>
          {cardAction}
        </Actions>
      ) : null}
    </Actions>
  )
}

export const CardNoContentMessage = ({
  white = true,
  text,
  paddingInline,
  paddingBlock
}: {
  white?: boolean
  text: string
  paddingInline?: boolean
  paddingBlock?: boolean
}) => {
  const { isPhoneOrSmaller } = useContext(WindowWidthContext)
  return (
    <UniBanner
      white={white}
      gray={false}
      paddingInline={paddingInline ?? isPhoneOrSmaller}
      paddingBlock={paddingBlock ?? isPhoneOrSmaller}
      border={false}
      small
      marginTop
    >
      <UniParagraph muted small noMargin className="text-muted">
        {text}
      </UniParagraph>
    </UniBanner>
  )
}

export default Card
